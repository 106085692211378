import React from 'react'

import './aside.scss'

export default function Aside({ children }) {

    return <aside>{ children }</aside>

}

export function AsideDetail({ title, children }) {
    return (
        <div className='asideDetail'>
            <div className='asideDetail__title'>{ title }</div>
            <div className='asideDetail__value'>{ children }</div>
        </div>
    )
}
import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_CUSTOMERS } from 'operations/Customer'

import Card, { CardTitle } from 'components/Card'
import { FormatPhone } from 'components/Format'
import Loader from 'components/Loader'

import { PrimaryButton } from 'components/Button'
import { ModalButton } from 'components/Modal'

import { useTranslation } from 'react-i18next'

export default function Customers() {

    const { t } = useTranslation()

    const { error, loading, data } = useQuery(GET_CUSTOMERS, { pollInterval: 10000 })

    if (error || loading) return <Loader />

    const { customers } = data

    return (
        <div className='bodyContainer'>
            <h1>{ t('myCustomers', 'My customers') }</h1>

            {
                (!customers || customers.length < 1) && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <div>
                            <h1 style={{ marginBottom: '6rem' }}>{ t('noCustomersMessage', "Looks like you don't have any customers yet. Book an appointment to get started.") }</h1>
                            <center><PrimaryButton to='/appointment'>{ t('bookAnAppointment', 'Book an appointment') }</PrimaryButton></center>
                        </div>
                    </div>
                )
            }

            {
                customers.map(customer => {
                 
                    const address = customer.addresses  && customer.addresses[0] ? customer.addresses[0] : {}

                    return (
                        <Card 
                            key={ customer.id }
                            style={{
                                display: 'grid',
                                gridTemplateColumns: `
                                    minMax(200px, 300px)
                                    minMax(200px, 300px)
                                    minMax(200px, 300px)
                                `
                            }}
                            to={`/customers/${ customer.id }`}
                        >
                            <CardTitle title={ t('name', 'Name') }>
                                { customer.firstName } { customer.lastName }
                            </CardTitle>
                            <CardTitle title={ t('phone', 'Phone') }>
                                <FormatPhone>{ customer.user.mobileNumber }</FormatPhone>
                            </CardTitle>
                            <CardTitle title={ t('address', 'Address') }>
                                {
                                    address.address ? <>{ address.address } { address.addressLine2 }<br/>{ address.city }, { address.province } { address.postal }</> : t('notApplicable', 'N/A')
                                }                                
                            </CardTitle>
                        </Card>
                    )
                })
            }

            <ModalButton to='/customers/new-customer' hoverText={ t('addACustomer', 'Add a customer') } />

        </div>
    )

}
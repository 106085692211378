import React, { useState, useRef } from 'react'

import { useQuery, useMutation, useSubscription, useApolloClient } from '@apollo/react-hooks'

import Card, { CardTitle } from 'components/Card'

import { GET_CHAT_MESSAGES, GET_MESSAGES, SEND_MESSAGE, ON_NEW_MESSAGE } from 'operations/Message'
import { PrimaryButton } from 'components/Button'

import './chat.scss'
import { GET_AVAILABLE_PHONE_NUMBERS, PROVISION_PHONE_NUMBER, GET_MESSAGING_NUMBER } from 'operations/Installer'
import { useEffect } from 'react'

import Loader from 'components/Loader'

import shortid from 'shortid'
import NoData from 'components/NoData'
import { FormatPhone } from 'components/Format'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useAuthentication } from 'hooks/authentication'
import Blur from 'components/Blur'

import { useTranslation } from 'react-i18next'
import Form, { Field, Submit } from 'components/Form'

// function errorParse(error, code) {
//     console.log('ERROR----',error)
//     if (error) {
//         const { message } = error.graphQLErrors[0]
//         return message
//     }
//     return false
// }

export default function Chat() {

    const { error, loading, data, refetch } = useQuery(GET_MESSAGING_NUMBER)

    if (error || loading) return 'Loading...'

    if (!data || (data && data.installer && !data.installer.messagingNumber)) return <SelectMessagingNumber refetch={ refetch } />

    return <ChatWindow />
}

function ChatWindow() {

    const { t } = useTranslation()

    const { customerId } = useParams()

    const { error, loading, data } = useQuery(GET_CHAT_MESSAGES, { pollInterval: 10000, fetchPolicy: 'cache-and-network' })
    
    if (error || (loading && !data)) return t('loading', 'Loading...')

    const customer = customerId && data.chat.find(chat => chat.customer.id === customerId)

    return (
        <div className='bodyContainer bodyContainer--chat'>

            <div className='chatContainer'>

            <div className='chatContactContainer'>
            <h1>{ t('chat', 'Chat') }</h1>
            {
                data.chat.map(chat => {
                    return (
                        <Link key={ chat.customer.id } className={`chatContact ${ customerId === chat.customer.id ? 'chatContact--selected' : '' } ${ !chat.message.isRead && chat.message.isInbound ? 'chatContact--unread' : 'chatContact--read' }`} to={`/chat/${ chat.customer.id }`}>
                            <div className='chatHead'>{ chat.customer.fullName.substring(0,1) }</div>
                            <div>
                                <div className='chatContact__name'>{ chat.customer.fullName }</div>
                                <div className='chatContact__messagePreview'>{ !chat.message.isInbound && ( chat.message.isAutomated ? t('messageOriginAutomated', 'Automated:') : t('messageOriginSelf', 'You:')) } { chat.message.body }</div>
                            </div>                                    
                        </Link>
                    )
                })
            }
        
        </div>

                <div className='chatWindowContainer'>

                    { customerId ? <Messages customer={ customer.customer } /> : (
                        <>
                            <NoData>{ t('noChatSelected', "Let's start a chat") }</NoData>
                        </>
                    ) }

                </div>

            </div>

        </div>
    )
}

function Messages({ customer }) {

    const { t } = useTranslation()

    const messageWindowRef = useRef(null)

    const { user } = useAuthentication()

    const client = useApolloClient()

    const { error, loading, data } = useQuery(GET_MESSAGES, { variables: { filter: { customerId: customer.id } }, fetchPolicy: 'cache-and-network' })

    const { data: subscriptionData } = useSubscription(ON_NEW_MESSAGE, { variables: { filter: { customerId: customer.id } } })

    const [ newMessage, setNewMessage ] = useState('')

    const [ sendMessage ] = useMutation(SEND_MESSAGE)

    useEffect(() => {
        if (messageWindowRef && messageWindowRef.current) {
            messageWindowRef.current.scrollTo(0, messageWindowRef.current.scrollHeight - messageWindowRef.current.clientHeight)
        }
    }, [messageWindowRef, data, customer.id])

    useEffect(() => {

        if (subscriptionData && subscriptionData.newMessage) {
            try {

                const prev = client.readQuery({
                    query: GET_MESSAGES,
                    variables: {
                        filter: {
                            customerId: customer.id
                        }
                    }
                })

                if (prev && prev.messages) {

                    client.writeQuery({
                        query: GET_MESSAGES,
                        variables: {
                            filter: {
                                customerId: customer.id
                            }
                        },
                        data: {
                            messages: [...prev.messages, subscriptionData.newMessage]
                        }
                    })
                }

            } catch(err) {
                console.log(err)
            }

        }

    }, [subscriptionData, client, customer.id])

    if (error) return 'Error :('
    if (loading) return <Loader />

    const { messages } = data

    function sendTheMessage() {

        if (!newMessage) return false

        const id = shortid.generate()
        sendMessage({
            variables: {
                payload: {
                    customerId: customer.id,
                    body: newMessage,
                    id
                }
            },
            optimisticResponse: {
                __typename: 'Mutation',
                sendMessage: {
                    __typename: 'Message',
                    body: newMessage,
                    id,
                    smsStatus: 'pending',
                    to: 'UNKNOWN',
                    from: 'UNKNOWN',
                    media: [],
                    messageStatus: 'pending',
                    senderId: user.sub,
                    customerId: customer.id,
                    isRead: true
                }
            },
            update: (cache, { data: { sendMessage } }) => {
                setNewMessage('')
                const data = cache.readQuery({ query: GET_MESSAGES, variables: { filter: { customerId: customer.id } } })
                cache.writeQuery({ query: GET_MESSAGES, variables: { filter: { customerId: customer.id } }, data: {
                    ...data,
                    messages: [...data.messages, sendMessage]
                } })
            }
        })

    }

    return (
        <>
            <div className='contactContainer'>
                <Link to={`/customers/${ customer.id }`}>{ customer.fullName }: <Blur><FormatPhone>{ customer.user.mobileNumber }</FormatPhone></Blur></Link>
            </div>
            <div ref={ messageWindowRef } className='chatHistoryContainer'>
                <div className='chatMessageContainer'>
                    { 
                        messages && messages.map(message => {

                            // console.log(message)
                            // if (message.media && message.media.length > 0) console.log(message)

                            return (
                                <div key={ message.id } className={`chatMessage ${ message.messageStatus === 'received' ? 'chatMessage--received' : 'chatMessage--sent' }`}>
                                    <div className='chatMessageBubble'>
                                        { message.body }
                                        { 
                                            message.media && message.media.map((media, key) => <React.Fragment key={ message.id + '-' + key }><DisplayMedia media={ media } /></React.Fragment>)
                                        }
                                    </div>
                                    {
                                        message.isAutomated && <div className='chatMessage--isAutomated' title={ t('messageTypeAutomated', 'Automated message') }><i className='material-icons'>offline_bolt</i></div>
                                    }
                                    <div className='chatMessageBubble--status'>{ message.messageStatus === 'pending' && t('sendingMessage', 'Sending...') }</div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>

            <div className='newMessageContainer'>
                <label htmlFor='newMessage' style={{ display: newMessage ? 'none' : 'block' }}>{ t('smsMessage', 'Text message') }</label>
                    <textarea id='newMessage' rows={1} cols={1} onChange={(e) => setNewMessage(e.target.value) } value={ newMessage } onKeyPress={ async (e) => {

                        if (e.ctrlKey && e.which === 13) {
                            setNewMessage(message => message + '\r\n')
                        }

                        if (!e.ctrlKey && e.which === 13 && newMessage) {

                            e.preventDefault()
                            sendTheMessage()

                        }
                    }}></textarea>
                    <button type='button' className='sendMessageButton' onClick={() => sendTheMessage()}><i className='material-icons'>send</i>sms</button>
            </div>
        </>
    )
}

function SelectMessagingNumber({ refetch }) {

    const { t } = useTranslation()

    const history = useHistory()

    // const { user } = useAuthentication()

    // const [ selectNumber, setSelectNumber ] = useState()

    const [ inputs, setInputs ] = useState({})

    const { error, loading, data, refetch: refetchPhoneNumbers } = useQuery(GET_AVAILABLE_PHONE_NUMBERS, { 
        // skip: !selectNumber
    })

    const [ provisionPhoneNumber, { error: provisionError, loading: provisionLoading, data: provisionData } ] = useMutation(PROVISION_PHONE_NUMBER)

    // console.log(user)

    // if (!selectNumber) return (
    //     <div style={{ display: 'grid', height: '100%' }}>
    //         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0 }}>
    //             <div>
    //                 <h1 style={{ marginBottom: '6rem' }}>{ t('noMessagingNumberChosen', "Looks like you don't have a local messaging number yet.") }</h1>
    //                 {
    //                     user.typ === 'INSTALLER' ? (
    //                         <center><PrimaryButton onClick={() => setSelectNumber(true)}>{ t('chooseYourNumber', 'Choose your messaging phone number') }</PrimaryButton></center>
    //                     ) : (
    //                         <center>{ t('loginAsShopOwnerPhoneNumber', "Please log in as the shop owner to setup a messaging number.") }</center>
    //                     )
    //                 }
                    
    //             </div>
    //         </div>
    //     </div>
    // )

    if (error || loading) return <Loader />

    if (provisionError) return t('unableToGetNumber', 'Unable to provision this phone number.')

    if (provisionData && provisionData.provisionPhoneNumber) return (
        <div style={{ display: 'grid', height: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0 }}>
                <div>
                    <h1 style={{ marginBottom: '6rem' }}>{ t('yourNewNumber', "Great! Your new messaging number is:") } <FormatPhone>{ provisionData.provisionPhoneNumber.phoneNumber }</FormatPhone></h1>
                    <center><PrimaryButton onClick={() => {
                        refetch().then(() => history.push('/'))
                    }}>{ t('Continue') }</PrimaryButton></center>
                </div>
            </div>
        </div>
    )

    const sortedNumbers = data.availablePhoneNumbers.sort((a, b) => a.locality < b.locality ? -1 : a.locality > b.locality ? 1 : 0)

    return (
        <div className='bodyContainer'>
            <h1>{ t('chooseYourNumber', 'Choose your messaging phone number') }</h1>

            <Form
                onSubmit={({ rawInputs: { city, postalCode, areaCode, distance }, enableSubmit }) => {
                    enableSubmit()
                    refetchPhoneNumbers({
                        payload: {
                            city: city || null,
                            postalCode: postalCode || null,
                            areaCode: areaCode ? Number(areaCode) : null,
                            distance: Number(distance || 25)
                        }
                    })
                }}
            >
                {() => (
                    <div>
                        <Field label={ t('city', 'City') } name="city" defaultValue={ inputs.city } onChange={(e) => setInputs(inputs => ({ ...inputs, city: e.target.value }))} />
                        <Field label={ t('postalCode', 'Postal code') } name="postalCode" defaultValue={ inputs.postalCode } onChange={(e) => setInputs(inputs => ({ ...inputs, postalCode: e.target.value }))} />
                        <Field label={ t('areaCode', 'Area code') } name="areaCode" type='numeric' helper={t('areaCodeHelper', 'ie. 519')} defaultValue={ inputs.areaCode } onChange={(e) => setInputs(inputs => ({ ...inputs, areaCode: e.target.value }))} />
                        <Field label={ t('distance', 'Distance') } name="distance" type='select' options={[[25, '25 km'], [50, '50 km'],[100, '100 km'], [250, '250 km'],[500, '500 km']]} defaultValue={ inputs.distance } onChange={(e) => setInputs(inputs => ({ ...inputs, distance: e.target.value }))} />
                        <Submit>{ t('search', 'Search') }</Submit>
                    </div>
                )}
            </Form>

            <h2>{ t('Available phone numbers') }</h2>

            {
                sortedNumbers.length < 1 && <NoData>{ t("Sorry, we weren't able to find any phone numbers. Please try expanding your search.") }</NoData>
            }

            {
                sortedNumbers.map(number => {
                    return (
                        <Card 
                            key={number.phoneNumber}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr 180px'
                            }}
                        >
                            <CardTitle title='Number'>
                                { number.friendlyName }
                            </CardTitle>    
                            <CardTitle title='Location'>
                                { number.locality }, { number.region }
                            </CardTitle>
                            <CardTitle title='Capabilities'>
                                Voice, SMS, MMS
                            </CardTitle>
                            <PrimaryButton disabled={ provisionLoading }
                                onClick={ async () => {

                                    try {

                                        await provisionPhoneNumber({
                                            variables: {
                                                payload: {
                                                    phoneNumber: number.phoneNumber
                                                }
                                            }
                                        })

                                        // console.log(provisioned)

                                    } catch (err) {
                                        console.log(err)
                                    }


                                }}
                            >
                                { t('selectNumberButton', 'Use this number') }
                            </PrimaryButton>           
                        </Card>
                    )
                })
            }
        </div>
    )
}

function DisplayMedia({ media }) {

    const { t } = useTranslation()

    switch (media.contentType) {
        case 'image/jpeg':
        case 'image/png':
        case 'image/gif':
            return <img className='mediaMessage' src={ media.url } alt={ t('mediaMessage', 'Media message') } />
        default:
            return <div className='mediaMessage--error'>{ t('mediaMessageError', 'Unable to display media message.') }</div>
    }

}
import gql from 'graphql-tag'

export const GET_INSTALLER = gql`
    query GetInstaller {
        installer {
            id
            businessName
            legalName
            latitude
            longitude
            geocoderData
            address
            addressLine2
            city
            province
            provinceCode
            postal
            country
            countryCode
            phone
            phoneCountryCode
            email
            website
            language
            timezone
            ownerName
            businessHours
            specialHours
            currency
            hourlyRate
            messagingNumber
            salesTaxes {
                id
                taxName
                taxAbbr
                taxNumber
                taxRate
                isCompoundTax
                isRecoverable
                displayTaxNumber
            }
            stripeAccountId
            isActive
        }
    }
`

export const UPDATE_INSTALLER = gql`
    mutation UpdateInstaller($payload: UpdateInstallerPayload!) {
        updateInstaller(payload: $payload) {
            id
            businessName
            legalName
            latitude
            longitude
            geocoderData
            address
            addressLine2
            city
            province
            provinceCode
            postal
            country
            countryCode
            phone
            phoneCountryCode
            email
            website
            language
            ownerName
            businessHours
            specialHours
            currency
            hourlyRate
        }
    }
`

export const UPDATE_SALES_TAX = gql`
    mutation UpdateSalesTax($payload: UpdateSalesTaxPayload!) {
        updateSalesTax(payload: $payload) {
            id
            taxName
            taxAbbr
            taxNumber
            description
            taxRate
            isCompoundTax
            isRecoverable
            displayTaxNumber
        }
    }
`

export const ACTIVATE_INSTALLER = gql`
    mutation ActivateInstaller {
        activateInstaller {
            id
            isActive
        }
    }
`

export const GET_STRIPE_ACCOUNT = gql`
    query StripeAccount {
        stripeAccount
    }
`

export const GET_STRIPE_CUSTOMER_ACCOUNT = gql`
    query StripeCustomerAccount {
        stripeProducts
    }
`

export const CREATE_STRIPE_SUBSCRIPTION = gql`
    mutation CreateStripeSubscription($payload: CreateStripeSubscriptionPayload!) {
        createStripeSubscription(payload: $payload)
    }
`

export const GET_SUBSCRIPTION_STATUS = gql`
    query GetSubscriptionStatus {
        subscriptionStatus
        trialDaysRemaining
    }
`

export const GET_AVAILABLE_PHONE_NUMBERS = gql`
    query GetAvailablePhoneNumbers($payload: AvailablePhoneNumbersPayload) {
        availablePhoneNumbers(payload: $payload) {
            friendlyName
            phoneNumber
            lata
            locality
            rateCenter
            latitude
            longitude
            region
            postalCode
            isoCountry
            addressRequirements
            beta
            capabilities {
                voice
                SMS
                MMS
                fax
            }
        }
    }
`

export const PROVISION_PHONE_NUMBER = gql`
    mutation ProvisionPhoneNumber($payload: ProvisionPhoneNumberPayload) {
        provisionPhoneNumber(payload: $payload) {
            phoneNumber
        }
    }
`

export const GET_MESSAGING_NUMBER = gql`
    query GetMessagingNumber {
        installer {
            id
            messagingNumber
        }
    }
`

export const STRIPE_CREATE_PORTAL_SESSION = gql`
    mutation StripeCreatePortalSession {
        stripeCreatePortalSession
    }
`
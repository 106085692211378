import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageBackend from "i18next-http-backend"
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
.use(LanguageDetector)
.use(LanguageBackend)
.use(initReactI18next)
.init({
    // resources,
    fallbackLng: 'en',
    saveMissing: true,
    keySeparator: false,
    interpolation: {
        escapeValue: false
    },
    defaultNS: 'translation',
    backend: {
        // loadPath: testFR,
        loadPath: (process.env.NODE_ENV === 'development' ? 'http://localhost:4200' : 'https://autoserviceapi.dsmedia.ca') + '/locales/{{lng}}/{{ns}}.js',
        addPath: (process.env.NODE_ENV === 'development' ? 'http://localhost:4200' : 'https://autoserviceapi.dsmedia.ca') + '/locales/add/{{lng}}/{{ns}}'
    }
})

export default i18n
import React, { useState } from 'react'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_CUSTOMER_BY_ID, UPDATE_CUSTOMER } from 'operations/Customer'

import Form, { Fieldset, Field, Submit } from 'components/Form'

import Error from 'components/Error'
import ErrorMessage from 'components/ErrorMessage'

import {CancelButton} from 'components/Button'

import { Link, useParams } from 'react-router-dom'
import validator from 'validator'
import Loader from 'components/Loader'

import { useTranslation } from 'react-i18next'

export default function Profile() {

    const { t } = useTranslation()

    const { customerId } = useParams()

    const { error, loading, data } = useQuery(GET_CUSTOMER_BY_ID, { variables: { id: customerId } })
    const [ updateCustomer ] = useMutation(UPDATE_CUSTOMER)
    const [errorMessage, setErrorMessage] = useState()
    const [ errors, setErrors ] = useState({})

    if (error) return <Error>{ t(`Oops. We are having trouble loading this customer profile.`) }</Error>

    if (loading) return <Loader />

    const ERROR_MESSAGES = {
        'MOBILE_NUMBER_EXISTS': t('existingMobileNumber', 'A user with that mobile number already exists.'),
    }

    const { customerById: customer } = data

    return (
        <div className='bodyContainer'>
            <h1><Link to='/customers'>{ t('customers', 'Customers') }</Link><i className='material-icons'>chevron_right</i><span className='current'>{ customer.fullName }</span></h1>
            {
                errorMessage && (
                    <ErrorMessage>{ errorMessage }</ErrorMessage>
                )
            }
            <Form
                onSubmit={ async ({ rawInputs, enableSubmit }) => {

                    const formErrors = {}
                    if (validator.isEmpty(rawInputs.mobileNumber)) formErrors.mobileNumber = true

                    if (Object.keys(formErrors).length > 0) {
                        enableSubmit()
                        return setErrors(formErrors)
                    }

                    try {
                        await updateCustomer({
                            variables: {
                                payload: {
                                    id: customer.id,
                                    user: {
                                        id: customer.user.id,
                                        mobileNumber: rawInputs.mobileNumber
                                    }
                                }
                            }
                        })
                        enableSubmit()
                    }
                    catch(err) {
                        const { graphQLErrors } = err
                        graphQLErrors.forEach(({ message }) => {
                            setErrorMessage(ERROR_MESSAGES[message])
                        })
                        enableSubmit()
                    }
                }}
            >
                {() => (
                    <>
                        <Fieldset legend={ t('contactInformation', 'Contact information') } style={{ maxWidth: '480px' }}>
                            <Field name='mobileNumber' type='tel' label={ t('mobilePhoneNumber', 'Mobile Phone Number') } autoComplete='off' maxLength={10} helper={ t('mobilePhoneHelperText', '(321) 456-7890') } defaultValue={ customer.user.mobileNumber } required error={ errors.mobileNumber } errorMessage={ t('customerMobilePhoneError', `Please enter your customer's mobile phone number.`) } />
                        </Fieldset>
                        <Submit>{ t('save', 'Save') }</Submit><CancelButton or to={`/customers/${ customerId }`}>{ t('cancel', 'Cancel') }</CancelButton>
                    </>
                )}
            </Form>
        </div>
    )

}
import React, { useState } from 'react'

import { useMutation } from '@apollo/react-hooks'
import { CREATE_CUSTOMER, GET_CUSTOMERS } from 'operations/Customer'

import Form, { Fieldset, Field, Submit } from 'components/Form'
import validator from 'validator'
// import { FormatPhone } from 'components/Format'

import { useTranslation } from 'react-i18next'


export default function NewCustomer({ onChange, mobileNumber }) {

    const { t } = useTranslation()

    const [ createCustomer ] = useMutation(CREATE_CUSTOMER, { refetchQueries: [{ query: GET_CUSTOMERS }] })
    const [ errors, setErrors ] = useState({})

    return (
        <Form
            onSubmit={async ({rawInputs, enableSubmit}) => {

                const formErrors = {}
                if (validator.isEmpty(rawInputs.firstName)) formErrors.firstName = true
                if (validator.isEmpty(rawInputs.lastName)) formErrors.lastName = true

                if (Object.keys(formErrors).length > 0) {
                    enableSubmit()
                    return setErrors(formErrors)
                }

                try {

                    const { data } = await createCustomer({
                        variables: {
                            payload: {
                                mobileNumber,
                                firstName: rawInputs.firstName,
                                lastName: rawInputs.lastName
                            }
                        }
                    })

                    onChange && onChange(data.createCustomer)

                }

                catch(err) {
                    console.log(err)
                }

                // enableSubmit()
            }}
        >
            {() => (
                <div className='modalForm'>
                    <Fieldset legend={ t('addACustomer', 'Add a customer') }>
                        <Field name='firstName' label={ t('firstName', 'First name') } autoComplete='off' required error={ errors.firstName } errorMessage={ t('customerFirstNameError', `What is your customer's first name?`) } />
                        <Field name='lastName' label={ t('lastName', 'Last name') } autoComplete='off' required error={ errors.lastName } errorMessage={ t('customerLastNameError', `What is your customer's last name?`) } />
                        <Submit>{ t('addCustomerButton', 'Add Customer') }</Submit>
                    </Fieldset>
                </div>
            )}
        </Form>
    )

}
import React, { useState } from 'react'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_INSTALLER, UPDATE_SALES_TAX, ACTIVATE_INSTALLER } from 'operations/Installer'

import { Link } from 'react-router-dom'

import Card, { CardTitle } from 'components/Card'

import Form, { Fieldset, Field, Submit } from 'components/Form'

import { CancelButton, AddRecordButton } from 'components/Button'

import validator from 'validator'
import Loader from 'components/Loader'

import { useTranslation } from 'react-i18next'

export default function SalesTaxes({ onboarding, onSave }) {

    const { t } = useTranslation()

    const [ salesTax, setSalesTax ] = useState()
    const { error, loading, data } = useQuery(GET_INSTALLER)
    const [ activateInstaller ] = useMutation(ACTIVATE_INSTALLER)

    if (error || loading) return <Loader />

    const { salesTaxes } = data.installer

    const Element = onboarding ? React.Fragment : 'div'

    return (
        <Element { ...Element === 'div' ? { className: 'bodyContainer' } : null }>
            {/* { !salesTax && <h2>Sales taxes</h2> } */}
    { !onboarding && <h1><Link to='/settings'>{ t('settings', 'Settings') }</Link><i className='material-icons'>chevron_right</i><span className='current'>{ t('salesTaxesTitle', 'Sales taxes') }</span></h1> }

            
            <div style={{ maxWidth: '480px' }}>
                { 
                    salesTax ? (
                        <SalesTax salesTax={ salesTax } onComplete={() => setSalesTax()} />
                    ) : (
                        <>
                            {
                                salesTaxes && salesTaxes.map(salesTax => {
                                    return (
                                        <Card
                                            onClick={() => setSalesTax(salesTax)}
                                            key={ salesTax.id }
                                            style={{
                                                display: 'grid',
                                                gridTemplateColumns: '60px 70px 1fr 1fr',
                                                gridGap: '2rem',
                                            }}
                                        >
                                            <CardTitle title={ t('taxName', 'Name') }>{ salesTax.taxAbbr }</CardTitle>
                                            <CardTitle title={ t('taxRate', 'Rate') }>{ salesTax.taxRate.toFixed(3) }%</CardTitle>
                                            <CardTitle title={ t('recoverableTax', 'Recoverable Tax') }>{ salesTax.isRecoverable ? t('yes', 'Yes') : t('no', 'No') }</CardTitle>
                                            <CardTitle title={ t('compoundingTax', 'Compounding Tax') }>{ salesTax.isCompoundTax ? t('yes', 'Yes') : t('no', 'No') }</CardTitle>
                                            {/* <CardEditButton onClick={() => setEditing(true)} /> */}
                                        </Card>
                                    )
                                })
                            }
                            <AddRecordButton onClick={ () => setSalesTax({}) }>{ t('addNewSalesTax', 'Add a sales tax') }</AddRecordButton>
                            { salesTaxes && salesTaxes.length > 0 && <button className='submitButton' onClick={async () => {
                                try {
                                    await activateInstaller()
                                    onSave && onSave()
                                }
                                catch (err) {
                                    console.log(err)
                                }
                            }}>{ onboarding ? t('onboardingComplete', 'Complete') : t('save', 'Save') }</button> }
                        </>
                    )
                }
            </div>
        </Element>
    )

}

function SalesTax({ salesTax, onComplete }) {

    const { t } = useTranslation()

    const [ updateSalesTax ] = useMutation(UPDATE_SALES_TAX, { refetchQueries: [{ query: GET_INSTALLER }] })
    const [ errors, setErrors ] = useState({})

    return (
        <Form
            onSubmit={ async ({ rawInputs: { taxName, taxAbbr, taxNumber, taxRate, isRecoverable, isCompoundTax, displayTaxNumber }, enableSubmit }) => {

                const formErrors = {}
                if (validator.isEmpty(taxName)) formErrors.taxName = true
                if (validator.isEmpty(taxAbbr)) formErrors.taxAbbr = true
                if (validator.isEmpty(taxRate) || taxRate > 100 || taxRate < 1) formErrors.taxRate = true

                if (Object.keys(formErrors).length > 0) {
                    enableSubmit()
                    return setErrors(formErrors)
                }

                try {
                    await updateSalesTax({
                        variables: {
                            payload: {
                                ...(salesTax && { id: salesTax.id }),
                                taxName,
                                taxAbbr,
                                taxNumber,
                                taxRate: Number(taxRate),
                                isRecoverable: isRecoverable ? true : false,
                                isCompoundTax: isCompoundTax ? true : false,
                                displayTaxNumber: displayTaxNumber ? true : false,
                            }
                        }
                    })
                    enableSubmit()
                    onComplete && onComplete()
                }
                catch (err) {
                    console.log(err)
                    enableSubmit()
                }


            }}
        >
            {() => {
                return (
                <Fieldset legend={ t('salesTaxInfo', 'Sales tax information') }>
                    <Field name='taxName' label={ t('taxName', 'Tax name') } helper={ t('taxNameHelper', 'Example: Harmonized Sales Tax') } required error={ errors.taxName } defaultValue={ salesTax && salesTax.taxName } errorMessage={ t('taxNameError', 'Please enter the tax name.') } />
                    <Field name='taxAbbr' label={ t('taxNameAbbreviation', 'Abbreviation') } maxLength={5} format={['uppercase']} helper={ t('taxAbbreviationHelper', 'Example: HST') } required error={ errors.taxAbbr } defaultValue={ salesTax && salesTax.taxAbbr } errorMessage={ t('taxAbbreviationError', 'Please enter the tax abbreviation.') } />
                    {/* <Field name='description' label='Description' type='textarea' /> */}
                    <Field name='taxNumber' label={ t('taxNumber', 'Your tax number') } helper={ t('taxNumberHelper', 'Your registered tax ID.') } defaultValue={ salesTax && salesTax.taxNumber } error={ errors.taxNumber } errorMessage={ t('taxNumberError', 'Please enter your tax number.') } />
                    <Field name='taxRate' label={ t('taxRatePercentage', 'Tax rate (%)') } helper={ t('taxRatePercentageHelper', 'A number only, without a percent sign.') } type='decimal' maxLength={6} required error={ errors.taxRate } defaultValue={ salesTax && salesTax.taxRate } errorMessage={ t('taxRateError', 'Please enter your tax rate. Example: 13') } />
                    <Field name='isRecoverable' label='Is this tax recoverable?' type='checkbox' defaultValue={ salesTax && salesTax.isRecoverable } error={ errors.isRecoverable } errorMessage={ t('taxIsRecoverableError', 'Is this a recoverable tax?') } />
                    <Field name='isCompoundTax' label={ t('compoundTax', 'Is this a compound tax?') } type='checkbox' defaultValue={ salesTax && salesTax.isCompoundTax } error={ errors.isCompoundTax } errorMessage={ t('isCompoundTaxError', 'Is this a compound tax?') } />
                    <Field name='displayTaxNumber' label={ t('displayTaxOnInvoices', 'Show tax number on invoices?') } type='checkbox' defaultValue={ salesTax && salesTax.displayTaxNumber } error={ errors.displayTaxNumber } errorMessage={ t('showTaxNumberError', 'Should we show your tax number on customer invoices?') } />
                    <Submit>{ t('save', 'Save') }</Submit><CancelButton or onClick={onComplete}>{ t('cancel', 'Cancel') }</CancelButton>
                </Fieldset>                
            )}}
        </Form>
    )

}
import React from 'react'

import './error.scss'

export default function Error({ error }) {

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%'
            }}
        >
            { error.message }
        </div>
    )

}
import React, { useState } from 'react'

import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks'
import { GET_CUSTOMER_VEHICLES, GET_VEHICLE_YEARS, GET_VEHICLE_MAKES, GET_VEHICLE_MODELS, CREATE_VEHICLE } from 'operations/Vehicle'

import Form, { Fieldset, Field, Submit } from 'components/Form'

import { VehicleCard } from 'components/Card'

import validator from 'validator'
import { CancelButton } from 'components/Button'
import Loader from 'components/Loader'

import { useTranslation } from 'react-i18next'

export default function VehicleLookup({ customerId, onChange }) {

    const { t } = useTranslation()

    const [ newVehicle, setNewVehicle ] = useState()

    const { error, loading, data } = useQuery(GET_CUSTOMER_VEHICLES, { variables: { customerId } })

    if (error || loading) return <Loader />

    if (newVehicle || data.customerVehicles.length < 1) return <VehicleForm customerId={ customerId } onChange={ (vehicle) => onChange && onChange(vehicle) } onCancel={() => setNewVehicle()} />

    return (
        <Form>
            {() => (
                <div className='modalForm'>
                    <Fieldset legend={ t('selectAVehicle', 'Select a vehicle') }>

                        { data.customerVehicles.map(vehicle => <VehicleCard key={ vehicle.id } vehicle={ vehicle } onClick={ () => onChange && onChange(vehicle) } />) }
                        <button type='button' className='addVehicleButton' onClick={ () => setNewVehicle(true) }><i className='material-icons'>add_circle_outline</i>{ t('addNewVehicleButton', 'Add a new vehicle') }</button>

                    </Fieldset>
                </div>
            )}
        </Form>
    )

}

function VehicleForm({ customerId, onChange, onCancel }) {

    const { t } = useTranslation()

    const { error: yearsError, loading: yearsLoading, data: yearsData } = useQuery(GET_VEHICLE_YEARS)
    const [ getVehicleMakes, { data: makeData } ] = useLazyQuery(GET_VEHICLE_MAKES)
    const [ getVehicleModels, { data: modelData } ] = useLazyQuery(GET_VEHICLE_MODELS)

    const [ errors, setErrors ] = useState({})

    const [ createVehicle ] = useMutation(CREATE_VEHICLE)

    const [ customMake, setCustomMake ] = useState()
    const [ customModel, setCustomModel ] = useState()

    if (yearsError || yearsLoading) return <Loader />

    const vehicleYears = []
    for(let i = yearsData.vehicleYears.maxYear; i >= yearsData.vehicleYears.minYear; i--) {
        vehicleYears.push(i)
    }

    return (
        <Form
            onSubmit={ async ({ rawInputs, enableSubmit }) => {

                const formErrors = {}
                if (validator.isEmpty(rawInputs.year)) formErrors.year = true
                if (validator.isEmpty(rawInputs.make)) formErrors.make = true
                if (validator.isEmpty(rawInputs.model)) formErrors.model = true

                if (Object.keys(formErrors).length > 0) {
                    enableSubmit()
                    return setErrors(formErrors)
                }

                try {
                    const { data } = await createVehicle({
                        variables: {
                            payload: {
                                customerId,
                                year: Number(rawInputs.year),
                                make: rawInputs.make,
                                model: rawInputs.model
                            }
                        }
                    })

                    onChange && onChange(data.createVehicle)
                }
                catch (err) {
                    console.log(err)
                }
            }}
        >
            {({ rawInputs: inputs, setInput }) => {

                function handleCustomMake() {
                    setInput({'make': { value: '', rawValue: '' }})
                    setCustomMake(true)
                    setCustomModel(true)
                }
                
                function handleCustomModel() {
                    setInput({ model: { value: '', rawValue: '' }})
                    setCustomModel(true)
                }

                return (
                    <div className='modalForm'>
                        <Fieldset legend={ t('addAVehicle', 'Add a vehicle') }>
                            
                            <Field name='year' type='select' label={ t('vehicleYear', 'Year') } options={ vehicleYears } onChange={ (e) => getVehicleMakes({ variables: { year: Number(e.target.value) } }) } required error={ errors.year } errorMessage={ t('vehicleYearError', 'What is the vehicle year?') } />
                            <Field name='make' type={ customMake ? 'text' : 'select' } label={ t('vehicleMake', 'Make') } options={ inputs.year && makeData ? [...makeData.vehicleMakes, ['CUSTOM_MAKE', '[ENTER A CUSTOM MAKE]']] : ['', t('loadingText', 'Loading...') ] } onChange={ (e) => e.target.value === 'CUSTOM_MAKE' ? handleCustomMake() : getVehicleModels({ variables: { year: Number(inputs.year), make: e.target.value } }) } required error={ errors.make } errorMessage={ t('vehicleMakeError', 'What is the vehicle make?') } />
                            <Field name='model' type={ customModel ? 'text' : 'select' } label={ t('vehicleModel', 'Model') } options={ inputs.year && inputs.make && modelData ? [...modelData.vehicleModels, ['CUSTOM_MODEL', '[ENTER A CUSTOM MODEL]']] : ['', t('loadingText', 'Loading...') ] } required error={ errors.model } errorMessage={ t('vehicleModelError', 'What is the vehicle model?') } onChange={(e) => e.target.value === 'CUSTOM_MODEL' && handleCustomModel() } />
                            <Submit>{ t('addVehicle', 'Add vehicle') }</Submit><CancelButton or onClick={onCancel}>{ t('cancel', 'Cancel') }</CancelButton>

                        </Fieldset>
                    </div>
                )
            }}
        </Form>
    )

}
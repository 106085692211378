import React from 'react'

import { useQuery } from '@apollo/react-hooks'
import { GET_DEVICES } from 'operations/Device'

import { Link } from 'react-router-dom'

import Card, { CardTitle, CardIcon } from 'components/Card'
import { PrimaryButton, CancelButton } from 'components/Button'
import Loader from 'components/Loader'
import { useDevice } from 'hooks/device'

import { useTranslation } from 'react-i18next'

const ICONS = {
    'desktop': 'desktop_windows',
    'desktop.windows': 'desktop_windows',
    'desktop.mac': 'desktop_mac',
    'laptop': 'laptop',
    'laptop.windows': 'laptop_windows',
    'laptop.mac': 'laptop_mac',
    'tablet': 'tablet_mac',
    'tablet.mac': 'tablet_mac',
    'tablet.android': 'tablet_android'
}

export default function Devices({ onSave }) {

    const { t } = useTranslation()

    const { error, loading, data } = useQuery(GET_DEVICES)

    const { device } = useDevice()

    if (error || loading) return <Loader />

    const { devices } = data

    const installerDevices = devices.filter(device => device.deviceType === 'INSTALLER')
    const serviceAdvisorDevices = devices.filter(device => device.deviceType === 'SERVICE_ADVISOR')
    const technicianDevices = devices.filter(device => device.deviceType === 'TECHNICIAN')

    return (
        <div className='bodyContainer' >
            <h1><Link to='/settings'>{ t('settings', 'Settings') }</Link><i className='material-icons'>chevron_right</i><span className='current'>{ t('devices', 'Devices') }</span></h1>
 
            {
                (!devices || devices.length < 1) && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <div>
                            <h1 style={{ marginBottom: '6rem' }}>{ t('noDevicesLinked', "Looks like you haven't linked a device to your account yet.") }</h1>
                            <center><PrimaryButton to='/settings/devices/link-device'>{ t('linkANewDevice', 'Link a new device') }</PrimaryButton>{ !device && <CancelButton to='/settings/devices/link-this-device' or>{ t('linkThisDevice', 'Link this device') }</CancelButton> }</center>
                        </div>
                    </div>
                )
            }

            { installerDevices && installerDevices.length > 0 && <h2>{ t('administratorDevices', 'Administrator devices') }</h2> }
            {
                installerDevices && installerDevices.map((device) => <Device key={ device.id } data={ device } />)
            }

            { serviceAdvisorDevices && serviceAdvisorDevices.length > 0 && <h2>{ t('serviceAdvisorDevices', 'Service advisor devices') }</h2> }
            {
                serviceAdvisorDevices && serviceAdvisorDevices.map((device) => <Device key={ device.id } data={ device } />)
            }

            { technicianDevices && technicianDevices.length > 0 && <h2>{ t('technicianDevices', 'Technician devices') }</h2> }
            {
                technicianDevices && technicianDevices.map((device) => <Device key={ device.id } data={ device } />)
            }

            { devices && devices.length > 0 && <><PrimaryButton to='/settings/devices/link-device'>{ t('linkANewDevice', 'Link a new device') }</PrimaryButton>{ !device && <CancelButton to='/settings/devices/link-this-device' or>{ t('linkThisDevice', 'Link this device') }</CancelButton> }</> }

        </div>
    )

}

function Device({ data }) {

    const { t } = useTranslation()

    const { os, client, device } = data.deviceInfo

    return (
        <Card
            style={{
                display: 'grid',
                gridTemplateColumns: `
                    minMax(40px, 70px)
                    minMax(40px, 200px)
                    minMax(40px, 200px)
                    minMax(40px, 200px)
                    auto
                `,
                gridGap: '1rem'
            }}
        >
            <CardIcon>{ ICONS[`${ device.type }.${ os.name }`.toLowerCase()] || ICONS[`${ device.type }`.toLowerCase()] }</CardIcon>
            <CardTitle title={ t('device', 'Device') }>
                { device.brand || `${ t('unbrandedDevice', 'Unbranded') } ${ device.type }` } { device.model }
            </CardTitle>
            <CardTitle title={ t('operatingSystemAbbr', 'OS') }>
                { os.name } { os.version }
            </CardTitle>
            <CardTitle title={ t('webBrowser', 'Browser') }>
                { client.name } { client.version }
            </CardTitle>
            <CardTitle title={ t('deviceName', 'Name') }>
                { data.name }
            </CardTitle>
        </Card>
    )
}
import React from "react";
import { useForm } from './FormProvider'

// NOTE: The type = "text" declaration below serves as a default value for that component property
export default function Submit({ children = 'Submit', fullWidth, disabled = false, onClick }) {
    const { isSubmitted } = useForm()

    return (
    <button className={`submitButton ${ fullWidth ? 'submitButton--fullWidth' : '' } ${ isSubmitted ? 'submitButton--loading' : '' }`} disabled={ isSubmitted || disabled } onClick={ (e) => { if (onClick) { onClick(e) } } }>{ children }{ isSubmitted && <div className='formLoader'/> }</button>
    );
}

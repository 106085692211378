import React from 'react'

import './nodata.scss'

export default function NoData({ children, sm }) {

    return (
        <div className={`noData__container ${ sm ? 'noData__container--small' : '' }`}>
            { children }
        </div>
    )

}
import React, { useState } from 'react'
import { DateTime, Duration } from 'luxon'
import { Line } from 'react-chartjs-2'
import {FormatCurrency} from 'components/Format'
import { useTranslation } from 'react-i18next'

export default function ({ title, dateTicks, data, average, currency, hour, time }) {

    const { t } = useTranslation()

    const [ current, setCurrent ] = useState(-1)

    const labels = []
    const chartData = []
    dateTicks.map(increment => {
        const res = data.find(i => DateTime.fromObject({ year: Number(i.year), month: Number(i.month || 0), day: Number(i.day || 0), hour: Number(i.hour || 0) }).toMillis() === increment.toMillis())
        labels.push(increment.toLocaleString(hour && DateTime.TIME_SIMPLE))
        return chartData.push(res ? res.value : 0)
    })

    const total = data.reduce((total, i) => total += i.value, 0)

    const previousPeriod = t('previousPeriod', 'Previous period')
    console.log(previousPeriod)

    const renderChartData= {
        labels,
        datasets: [{
            label: t('currentPeriod', 'Current period'),
            fill: false,
            lineTension: 0,
            backgroundColor: '#fff',
            borderColor: '#0d2a8a',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#0d2a8a',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#0d2a8a',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: chartData,
            
            borderWidth: 2,
        }]
    }

    const max = Math.max(...chartData)

    let maxCeiling = 1
    if (max > 1000) maxCeiling = 1000
    if (max > 100 && max < 1000) maxCeiling = 100
    if (max > 10 && max < 100) maxCeiling = 10

    return (
        <div className='statContainer'>
            <div className='statContainerBorder'>
                <h1>{ title }</h1>
                {
                    currency ? 
                    (
                        <h2>$<FormatCurrency>{ ( current !== -1 ? chartData[current] : total ) / (average ? data.length : 1) }</FormatCurrency></h2>
                    ) :
                    time ? (
                        <h2>{ Duration.fromObject({ seconds: ( current !== -1 ? chartData[current] : total ) / (average ? data.length : 1) }).toFormat("h'h' m'm' s's'") }</h2>
                    ) : (
                        <h2>{ ( current !== -1 ? chartData[current] : total ) / (average ? data.length : 1) }</h2>
                    )
                }
                {
                    current !== -1 && <h3>{ labels[current] }</h3>
                }
                <Line
                    data={ renderChartData }
                    height={60}
                    options={{
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    display: false,
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    display: false,
                                    max: (Math.ceil(max / maxCeiling) * maxCeiling) + maxCeiling
                                }
                            }]
                        },
                        tooltips: {
                            enabled: false,
                            custom: ({ opacity, dataPoints }) => {
                                if (!opacity) return setCurrent(-1)
                                return setCurrent(dataPoints[0].index)
                            }
                        }
                    }}
                />
            </div>
        </div>
    )    

}
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable, split } from "apollo-link";

const request = async (operation) => {
  const headers = {};
  const token = localStorage.getItem("accessToken") || "";
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }
  operation.setContext({ headers });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const wsLink = new WebSocketLink({
  uri:
    process.env.NODE_ENV === "development"
      ? `ws://localhost:4200/graphql`
      : "wss://autoserviceapi.dsmedia.ca/graphql",
  options: {
    reconnect: true,
    connectionParams: {
      token: localStorage.getItem("accessToken") || null,
    },
  },
});

const httpLink = new HttpLink({
  uri:
    process.env.NODE_ENV === "development"
      ? "http://localhost:4200/graphql"
      : "https://autoserviceapi.dsmedia.ca/graphql",
  name: `INSTALLER (${process.env.NODE_ENV})`,
  version: "1.0.0",
  credentials: "same-origin",
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    requestLink,
    link,
  ]),
  cache: new InMemoryCache(),
});

export default client;

import React, { createContext, useContext, useState, useEffect } from 'react'

import { useQuery } from '@apollo/react-hooks'

import { GET_SUBSCRIPTION_STATUS } from 'operations/Installer'

import Loader from 'components/Loader'
import { useAuthentication } from 'hooks/authentication'

import { useTranslation } from 'react-i18next'

const SubscriptionContext = createContext()

const POLL_INTERVAL = {
    SLOW: 600000,
    FAST: 60000
}

export default function Subscription({ children }) {

    
    const { isAuthenticated } = useAuthentication()
    
    if (!isAuthenticated) return children
    return <SubscriptionProvider>{ children }</SubscriptionProvider>
    
}

function SubscriptionProvider({ children }) {
    
    const { t } = useTranslation()
    const [ pollInterval, setPollInterval ] = useState('SLOW')

    const { error, loading, data, refetch } = useQuery(GET_SUBSCRIPTION_STATUS, { pollInterval: POLL_INTERVAL[pollInterval], fetchPolicy: 'network-only' })

    useEffect(() => {
        if (data && ['TRIAL_EXPIRED', 'PAST_DUE'].includes(data.subscriptionStatus)) {
            setPollInterval('FAST')
        }
        if (data && !['TRIAL_EXPIRED', 'PAST_DUE'].includes(data.subscriptionStatus)) {
            setPollInterval('SLOW')
        }
    }, [data])

    if (loading) return <Loader />

    if (error) return t('subscriptionCheckError', 'Unable to get subscription status')
    
    return (
        <SubscriptionContext.Provider
            value={{
                subscriptionStatus: data.subscriptionStatus,
                trialDaysRemaining: data.trialDaysRemaining,
                refetchSubscriptionStatus: () => refetch()
            }}
        >
            { children }
        </SubscriptionContext.Provider>
    )

}

export const useSubscription = () => useContext(SubscriptionContext)
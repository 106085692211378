import React from 'react'

import './technicianFilter.scss'
import Initials from 'components/Initials'
import { useAssignments } from 'hooks/assignments'

export default function() {

    const { teamMembers, assignmentFilter, setAssignmentFilter } = useAssignments()

    return (
        <div className='technicianFilter'>
            {
                teamMembers.map(teamMember => {
                    return (
                        <button key={ teamMember.id } type='button' 
                            className={`technician ${ assignmentFilter.includes(teamMember.id) ? 'technician--active' : '' }`} 
                            title={ teamMember.firstName + ' ' + teamMember.lastName }
                            onClick={() => setAssignmentFilter(filter => {

                                if (filter.includes(teamMember.id)) {
                                    const newFilter = filter.filter(item => item !== teamMember.id)
                                    return newFilter
                                }

                                return [
                                    ...filter,
                                    teamMember.id
                                ]
                                

                            })}
                        >
                            <Initials name={ teamMember.firstName + ' ' + teamMember.lastName } />
                        </button>
                    )
                })
            }
        </div>
    )

}
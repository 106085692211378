import React, { useState } from 'react'

import Card, { CardHeader } from 'components/Card'
import Accordion, { AccordionItem, AccordionButton } from 'components/Accordion'
import buttonImage from './blue-on-light@3x.png'

import { useQuery, useMutation } from '@apollo/react-hooks'

import { GENERATE_CONNECT_STATE, ME, 
    // GET_TEAM_MEMBERS, 
    RESEND_VERIFICATION_EMAIL, GET_MY_DAY } from 'operations/Auth'
import { GET_INSTALLER } from 'operations/Installer'
// import { GET_DEVICES } from 'operations/Device'
import { useAuthentication } from 'hooks/authentication'
import Loader from 'components/Loader'
import { useSubscription } from 'hooks/subscription'
import { GET_STATS } from 'operations/Transaction'
// import NPSRating from 'components/NPSRating'
import Info from 'components/Info'
// import NoData from 'components/NoData'

import { DateTime } from 'luxon'

import { useTranslation } from 'react-i18next'

import './dashboard.scss'
import Form, { Field } from 'components/Form'
// import { Sparklines, SparklinesLine, SparklinesSpots, SparklinesText } from 'react-sparklines'
// import { Chart } from 'react-charts'
// import { ReactChart } from 'chartjs-react'
import { Line } from 'react-chartjs-2'
// import { FormatCurrency } from 'components/Format'

import Stat from './Stat'

export default function Dashboard() {

    const { t } = useTranslation()

    const { user } = useAuthentication()

    const { subscriptionStatus, trialDaysRemaining } = useSubscription()

    return (
        <div className='bodyContainer'>
            <h1>{ t('dashboard', 'Dashboard') }</h1>

            {
                subscriptionStatus === 'TRIAL' && (
                    <h2
                        style={{ 
                            ...(trialDaysRemaining < 7 && { color: 'orange' } ),
                            ...(trialDaysRemaining < 2 && { color: 'red' } )
                        }}
                    >
                        { trialDaysRemaining < 1 ? t('lastDayOfTrial', 'Last day of your free trial.') : t('trialDaysRemaining', '{{ trialDaysRemaining }} day(s) left in your free trial', { trialDaysRemaining }) }
                    </h2>
                )
            }

            <Card>
                <CardHeader>
                    <Welcome />
                </CardHeader>
                <Accordion>

                    {
                        user && user.typ === 'INSTALLER' && <InstallerOnboarding />
                    }

                </Accordion>
            </Card>

            <h2>{ t(`My day`) }</h2>
            <MyDay />

            <h2>{ t(`Stats`) }</h2>
            <DashboardStats />

        </div>
    )

}

function Welcome() {

    const { t } = useTranslation()

    // const { user } = useAuthentication()

    const { error, loading, data } = useQuery(ME)

    if (error || loading) return null

    const { me } = data

    return (
        <>
            { t('welcome', "Welcome, {{ firstName }}", { firstName: me.firstName }) }
        </>
    )

}

function InstallerOnboarding() {

    const { t } = useTranslation()

    const { subscriptionStatus, trialDaysRemaining } = useSubscription()

    const { error, loading, data } = useQuery(GENERATE_CONNECT_STATE)
    const { error: installerError, loading: installerLoading, data: installerData } = useQuery(GET_INSTALLER)

    // const { error: teamError, loading: teamLoading, data: teamData } = useQuery(GET_TEAM_MEMBERS)
    // const { error: deviceError, loading: deviceLoading, data: deviceData } = useQuery(GET_DEVICES)

    const { error: meError, loading: meLoading, data: meData } = useQuery(ME)

    const [ resendVerificationEmail, { data: resendData, loading: resendLoading } ] = useMutation(RESEND_VERIFICATION_EMAIL)

    if (
        error || 
        loading || 
        installerError || 
        installerLoading || 
        // teamError || 
        // teamLoading || 
        // deviceError || 
        // deviceLoading || 
        meError || 
        meLoading
    ) return <Loader />

    return (
        <>
            <h2 style={{ margin: '0 0 1rem 0', paddingLeft: '2rem' }}>{ t('gettingStartedSteps', 'Follow these steps to get started.') }</h2>
            <AccordionItem title={ '1. ' + t('gettingStartedActivate', 'Activate your NAPA Connect account') } complete={ installerData.installer.isActive }>
                { t('gettingsStartedActivateMessage', 'Before you start, tell us a few details about your business.') }
                <AccordionButton to='/onboarding'>{ t('startNow', 'Start now') }</AccordionButton>
            </AccordionItem>

            <AccordionItem title={ '2. ' + t('gettingStartedVerify', 'Verify your email address') } complete={ meData.me.isVerified }>
                <p>{ t('gettingsStartedVerifyMessageOne', "Let's make sure you are able to receive important emails related to your account like password resets.") }</p>
                <p>{ t('gettingStartedVerifyMessageTwo', 'A verification email was sent to') } <strong>{ meData.me.email }</strong>.</p>
                <p>{ t('gettingStartedVerifyThree', "Don't see an email from us? Please check your spam folder.") }</p>
                <AccordionButton onClick={ () => resendVerificationEmail() } loading={ resendLoading } disabled={ resendData && resendData.resendVerificationEmail === 'SUCCESS' }>{ t('resendVerificationEmail', 'Resend verification email') }</AccordionButton>
            </AccordionItem>

            <AccordionItem title={ '3. ' + t('gettingStartedPhone', 'Choose your local messaging number') } complete={ installerData.installer.messagingNumber }>
                { t('gettingStartedPhoneMessage', 'Send and receive text messages with your customers from a local phone number.') }
                <AccordionButton to='/chat'>{ t('startNow', 'Start now') }</AccordionButton>
            </AccordionItem>

            {/* <AccordionItem title={ t('gettingStartedTeam', '4. Add a team member') } complete={ teamData.teamMembers && teamData.teamMembers.length > 0 }>
                { t('gettingStartedTeamMessage', "Let's get your team on board") }
                <AccordionButton to='/settings/team-members/add-team-member'>{ t('startNow', 'Start now') }</AccordionButton>
            </AccordionItem>

            <AccordionItem title={ t('gettingStartedDevices', '5. Link a device') } complete={ deviceData.devices && deviceData.devices.length > 0 }>
                { t('gettingStartedDevicesMessage', 'Link devices to your account for easy team member logins') }
                <AccordionButton to='/settings/devices'>{ t('startNow', 'Start now') }</AccordionButton>
            </AccordionItem> */}

            <AccordionItem title={ '4. ' + t('gettingStartedStripe', 'Enable online payments (optional)') } complete={ installerData.installer.stripeAccountId }>
                { t('gettingStartedStripeMessage', 'Connect a Stripe account and start accepting credit card payments.') }
                <p><a href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${ process.env.NODE_ENV === 'development' ? 'ca_H8QDx2COZ9Mgp8GYMaoPm24ktlsJRPnv' : 'ca_H8QD7TiCYfzOIBSwhVZE4cgVYxmMGN1O' }&scope=read_write&state=${ data.generateConnectState }`}><img style={{ width: '180px' }} src={buttonImage} alt='Connect with Stripe' /></a></p>
            </AccordionItem>

            <AccordionItem title={ <>5. { t('gettingStartedUpgrade', 'Add your billing information') }{ subscriptionStatus === 'TRIAL' && (<>:&nbsp;
            <span
                    style={{ 
                        ...(trialDaysRemaining < 7 && { color: 'orange' } ),
                        ...(trialDaysRemaining < 2 && { color: 'red' } )
                    }}
                >
                    { trialDaysRemaining < 1 ? t('lastDayOfTrial', 'Last day of your free trial.') : t('trialDaysRemaining', '{{ trialDaysRemaining }} day(s) left in your free trial', { trialDaysRemaining }) }
                </span>
        </>)  }</>} complete={ ['PAID', 'PAST_DUE', 'DEMO'].includes(subscriptionStatus) }>
                { t('gettingStartedUpgradeMessage', 'Upgrade to a paid NAPA Connect account and get appointment scheduling, customer surveys, two-way messaging and more for just $59.95/month.') }
                <AccordionButton to='/settings/billing'>{ t('upgradeMyAccount', 'Upgrade my account') }</AccordionButton>
            </AccordionItem>

        </>
    )
}

function DashboardStats() {

    const { t } = useTranslation()

    return (
        <Form>
            {({ rawInputs }) => {

                const today = DateTime.local()

                const START_DATES = {
                    'today': today.startOf('day'),
                    'last-7-days': today.minus({ days: 7 }).startOf('day'),
                    'last-4-weeks': today.minus({ weeks: 4 }).startOf('day'),
                    'last-3-months': today.minus({ months: 3 }).startOf('day'),
                    'last-12-months': today.minus({ months: 12 }).startOf('day'),
                    'month-to-date': today.startOf('month').startOf('day'),
                    'quarter-to-date': today.startOf('quarter').startOf('day'),
                    'year-to-date': today.startOf('year').startOf('day'),
                    'all-time': DateTime.fromISO('2020-01-01').startOf('day')
                }

                const END_DATES = {
                    'today': today.plus({ days: 1 }).startOf('day'),
                    'last-7-days': today.startOf('day'),
                    'last-4-weeks': today.startOf('day'),
                    'last-3-months': today.startOf('day'),
                    'last-12-months': today.startOf('day'),
                    'month-to-date': today.endOf('day'),
                    'quarter-to-date': today.endOf('day'),
                    'year-to-date': today.endOf('day'),
                    'all-time': today.endOf('day')
                }
            
                const startDate = rawInputs.range ? START_DATES[rawInputs.range] : null
                const endDate = rawInputs.range ? END_DATES[rawInputs.range] : null

                let groupBy = 'DATE'

                if (startDate) {
                    const diff = endDate.diff(startDate, 'months').toObject()
                    if (diff.months > 3) groupBy = 'MONTH'
                    if (diff.months > 36) groupBy = 'YEAR'
                    if (rawInputs.range === 'today') groupBy = 'HOUR'
                }

                return (
                    <>
                        <Field 
                            type='select'
                            name='range'
                            label={ t('statsRange', 'Stats range') }
                            options={[
                                ['today', t('todayRange', 'Today')],
                                ['last-7-days', t('last7DaysRange', 'Last 7 days')],
                                ['last-4-weeks', t('last4WeeksRange', 'Last 4 weeks')],
                                ['last-3-months', t('last3MonthsRange', 'Last 3 months')],
                                ['last-12-months', t('last12MonthsRange', 'Last 12 months')],
                                ['month-to-date', t('monthToDateRange', 'Month to date')],
                                ['quarter-to-date', t('quarterToDateRange', 'Quarter to date')],
                                ['year-to-date', t('yearToDateRange', 'Year to date')],
                                ['all-time', t('allTimeRange', 'All time')],
                            ]}
                            defaultValue='last-7-days'
                            noBlankOption
                        />

                        {
                            startDate && <StatsRange startDate={ startDate } endDate={ endDate } groupBy={ groupBy } />
                        }

                    </>
                )
            }}
        </Form>
    )
}

function StatsRange({ startDate, endDate, groupBy }) {

    const { t } = useTranslation()

    const { error, loading, data } = useQuery(GET_STATS, {
        variables: {
            statsPayload: {
                startDate,
                endDate,
                groupBy
            }
        },
        pollInterval: 60000
    })

    if (error || loading) return null

    const INCREMENT = {
        'DATE': 'days',
        'MONTH': 'months',
        'YEAR': 'years',
        'HOUR': 'hours'
    }

    const dateTicks = []
    for (let i = 0; startDate.plus({ [INCREMENT[groupBy]]: i }) < endDate; i++) {
        dateTicks.push(startDate.plus({ [INCREMENT[groupBy]]: i }))
    }

    return (
        <>
        <div className='dashboardReportContainer'>
                                    
            <NPSScore2 title={ t('netPromoterScore', 'Net promoter score') } dateTicks={ dateTicks } data={ data.stats.npsSurveyResults } hour={ groupBy === 'HOUR' } />
            <Stat title={ t('grossRevenue', 'Gross revenue') } currency dateTicks={ dateTicks } data={ data.stats.grossVolume } hour={ groupBy === 'HOUR' } />
            <Stat title={ t('averageInvoiceAmount', 'Average invoice amount') } currency average dateTicks={ dateTicks } data={ data.stats.avgRepairOrderAmount } hour={ groupBy === 'HOUR' } />
            <Stat title={ t('numberOfVehiclesServiced', 'Vehicles serviced') } dateTicks={ dateTicks } data={ data.stats.vehiclesServiced } hour={ groupBy === 'HOUR' } />
            <Stat title={ t('averageTimeOnHoist', 'Average time on hoist') } dateTicks={ dateTicks } data={ data.stats.avgAppointmentDuration } hour={ groupBy === 'HOUR' } time averag />

        </div>
        </>
    )
    
}

function NPSScore2({ dateTicks, data, title, hour }) {

    const { t } = useTranslation()

    const [ current, setCurrent ] = useState(-1)

    const labels = []
    const total = []
    const promoters = []
    const passives = []
    const detractors = []
    dateTicks.map(increment => {
        const res = data.find(i => DateTime.fromObject({ year: Number(i.year), month: Number(i.month || 0), day: Number(i.day || 0), hour: Number(i.hour || 0) }).toMillis() === increment.toMillis())
        labels.push(increment.toLocaleString(hour && DateTime.TIME_SIMPLE))
        total.push(res ? res.total : 0)
        promoters.push(res ? res.promoters : 0)
        passives.push(res ? res.passives : 0)
        return detractors.push(res ? res.detractors : 0)
    })

    const totals = data.reduce((totals, i) => {
        return {
            total: totals.total + i.total,
            promoters: totals.promoters + i.promoters,
            passives: totals.passives + i.passives,
            detractors: totals.detractors + i.detractors
        }
    }, {
        total: 0,
        promoters: 0,
        passives: 0,
        detractors: 0
    })

    const max = Math.max(...[totals.promoters, totals.passives, totals.detractors])

    let maxCeiling = 1
    if (max > 1000) maxCeiling = 1000
    if (max > 100 && max < 1000) maxCeiling = 100
    if (max > 10 && max < 100) maxCeiling = 10

    const renderChartData= {
        labels,
        datasets: [
            {
                label: t('promoters', 'Promoters'),
                fill: false,
                lineTension: 0,
                backgroundColor: '#fff',
                borderColor: '#33A532',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#33A532',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#33A532',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: promoters,
                
                borderWidth: 2,
            },
            {
                label: t('passives', 'Passives'),
                fill: false,
                lineTension: 0,
                backgroundColor: '#fff',
                borderColor: '#0d2a8a',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#0d2a8a',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#0d2a8a',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: passives,
                
                borderWidth: 2,
            },
            {
                label: t('detractors', 'Detractors'),
                fill: false,
                lineTension: 0,
                backgroundColor: '#fff',
                borderColor: '#ff0000',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#ff0000',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#ff0000',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: detractors,
                
                borderWidth: 2,
            },
        ]
    }

    return (
        <div className='statContainer'>
            <div className='statContainerBorder'>
                <h1>{ title }<Info href='https://support.napaconnect.ca/net-promoter-score' /></h1>
                {
                    current !== -1 ? (
                        <h2>{ Math.round(((Number(promoters[current]) / total[current]) * 100) - ((Number(detractors[current]) / total[current]) * 100)) || 0 }</h2>
                    ) : (
                        <h2>{ Math.round(((Number(totals.promoters) / totals.total) * 100) - ((Number(totals.detractors) / totals.total) * 100)) || 0 }</h2>
                    )
                }
                {
                    current !== -1 && <h3>{ labels[current] }</h3>
                }
                <Line
                    data={ renderChartData }
                    height={60}
                    options={{
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    display: false
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    display: false,
                                    max: (Math.ceil(max / maxCeiling) * maxCeiling) + maxCeiling
                                }
                            }]
                        },
                        tooltips: {
                            enabled: false,
                            custom: (e) => {
                                const { opacity, dataPoints } = e
                                if (!opacity) return setCurrent(-1)
                                return setCurrent(dataPoints[0].index)
                            }
                        }
                    }}
                />
            </div>
        </div>
    )

}

function MyDay() {

    const { t } = useTranslation()

    const { error, loading, data } = useQuery(GET_MY_DAY, { 
        variables: {
            startDate: DateTime.local().startOf('day'),
            endDate: DateTime.local().endOf('day')
        },
        fetchPolicy: 'cache-and-network'
    })

    if (error) return t(`Sorry, we are having trouble getting your agenda for today.`)

    if (loading) return <Loader />

    return (
        <div className='myDayGrid'>
            <Card>
                { data.myDay && data.myDay.appointments ? data.myDay.appointments.total : 0 }<span className='title'>{ t(`myDayAppointmentsTotal`, `appointment`, { count: data.myDay && data.myDay.appointments ? data.myDay.appointments.total : 0 }) }</span>
            </Card>
            <Card>
            { data.myDay && data.myDay.appointments ? data.myDay.appointments.complete : 0 }<span className='title'>{ t(`myDayAppointmentsComplete`, `appointment completed`, { count: data.myDay && data.myDay.appointments ? data.myDay.appointments.complete : 0 }) }</span>
            </Card>
            <Card>
                { data.myDay && data.myDay.appointments ? (data.myDay.appointments.total - data.myDay.appointments.complete) : 0 }<span className='title'>{ t(`myDayAppointmentsRemaining`, `appointment remaining`, { count: data.myDay && data.myDay.appointments ? (data.myDay.appointments.total - data.myDay.appointments.complete) : 0 }) }</span>
            </Card>
        </div>
    )
}
import gql from 'graphql-tag'

export const GET_CUSTOMERS_BY_PHONE = gql`
    query GetCustomersByPhone($mobileNumber: String!) {
        customersByPhone(mobileNumber: $mobileNumber) {
            id
            fullName
            user {
                id
                mobileNumber
            }
            addresses {
                id
                address
                addressLine2
                city
                province
                postal
            }
        }
    }
`

export const GET_CUSTOMER_BY_ID = gql`
    query CustomerById($id: ID!) {
        customerById(id: $id) {
            id
            fullName
            npsRating
            revenue
            user {
                id
                mobileNumber
            }
            addresses {
                id
                address
                addressLine2
                city
                province
                postal
            }
        }
    }
`

export const CREATE_CUSTOMER = gql`
    mutation CreateCustomer($payload: CreateCustomerPayload!) {
        createCustomer(payload: $payload) {
            id
            fullName
            user {
                id
                mobileNumber
            }
            addresses {
                id
                address
                addressLine2
                city
                province
                postal
            }
        }
    }
`

export const GET_CUSTOMERS = gql`
    query Customers {
        customers {
            id
            firstName
            lastName
            user {
                id
                mobileNumber
            }
            addresses {
                id
                address
                addressLine2
                city
                province
                postal
            }
        }
    }
`

export const GET_CUSTOMER_APPOINTMENTS = gql`
    query GetCustomerAppointments($id: ID!) {
        customer: customerById(id: $id) {
            id
            fullName
            user {
                id
                mobileNumber
            }
            addresses {
                id
                address
                addressLine2
                city
                province
                postal
            }
            appointments {
                id
                appointmentStartAt
                description
                status
                isPaid
                mileage
                mileageType
                createdAt
                vehicle {
                    id
                    year
                    make
                    model
                }
                invoice {
                    id
                    totalPaid
                }
                survey {
                    id
                    score
                }
            }
        }
    }
` 

export const GET_CUSTOMER_SURVEYS = gql`
    query GetCustomerSurveys($id: ID!) {
        customerSurveys(id: $id) {
            id
            surveyType
            action
            medium
            score
            comment
            createdAt
            appointmentId
        }
    }
`

export const UPDATE_CUSTOMER = gql`
    mutation UpdateCustomer($payload: UpdateCustomerPayload!) {
        updateCustomer(payload: $payload) {
            id
            firstName
            lastName
            user {
                id
                mobileNumber
                email
            }
        }
    }
`
import React from 'react'

import { useTranslation } from 'react-i18next'

export default function FieldContainer({ children, isFocused, isFilled, inputId, label, helper, isTextarea, isCheckbox, required, error, noOptional }) {

    const { t } = useTranslation()

    return (
        <label htmlFor={ inputId }>
            <div className={`fieldContainer${ isFilled ? ' fieldContainer--filled' : '' }${ isFocused ? ' fieldContainer--focused' : '' }${ isTextarea ? ' fieldContainer--textarea' : '' } ${ isCheckbox ? ' fieldContainer--checkbox' : '' } ${ error ? 'fieldContainer--error' : '' }`}>
                <span className='field__label'>
                    { label }
                    { !required && !noOptional && <span className='field__optional'> { t('optionalField', '(Optional)') }</span> }
                </span>
                {
                    helper && <span className='field__helper'>{ helper }</span>
                }
                { children }
            </div>
        </label>
    )

}
import React from 'react'

import './onboarding.scss'
import { useParams, useHistory, NavLink } from 'react-router-dom'

import BusinessProfile from 'pages/Settings/BusinessProfile'
import Locale from 'pages/Settings/Locale'
import HoursOfOperation from 'pages/Settings/HoursOfOperation'
import SalesTaxes from 'pages/Settings/SalesTaxes'
import HourlyRate from 'pages/Settings/HourlyRate'

import Survey from 'components/Survey'

import { useTranslation } from 'react-i18next'

export default function Onboarding() {

    const { t } = useTranslation()

    const { state } = useParams()
    const history = useHistory()

    if (state === 'thank-you') {
        return (
            <div className='bodyContainer'
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    textAlign: 'center'
                }}
            >
                <div>
                    <i style={{ marginBottom: '4rem', fontSize: '48px', color: '#33A532' }}className='material-icons'>check_circle</i>
                    <h1>{ t('accountActive', 'Your account is active!') }</h1>
                    <Survey type='CES' action='ONBOARDING' question={ t('cesQuestionOnboarding', 'How easy was the activation process today?') }/>
                </div>
            </div>
        )
    }

    return (
        <div>
            {/* <div className='onboarding__header'>
                <Link to='/' className='onboarding__closeButton'><i className='material-icons'>close</i></Link>
            </div> */}
            <div className='bodyContainer'
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 480px 1fr',
                    gridGap: '2rem',
                    maxHeight: '100%',
                    boxSizing: 'border-box',
                }}
            >
                <ul className='onboardingSteps'>
                    <li><NavLink exact to='/onboarding'>{ t('businessDetails', 'Business details') }</NavLink></li>
                    <li><NavLink to='/onboarding/locale'>{ t('locale', 'Locale') }</NavLink></li>
                    <li><NavLink to='/onboarding/hours-of-operation'>{ t('hoursOfOperation', 'Hours of operation') }</NavLink></li>
                    <li><NavLink to='/onboarding/hourly-rate'>{ t('hourlyRate', 'Hourly rate') }</NavLink></li>
                    <li><NavLink to='/onboarding/sales-taxes'>{ t('salesTaxes', 'Sales taxes') }</NavLink></li>
                </ul>
                <div>

                    {
                        !state && (
                            <>
                                <h1>{ t('tellUsAboutYourBusiness', 'Tell us about your business') }</h1>
                                <h6>{ t('businessDetailsUse', 'We use your business details to help new customers discover you. Enter your business details as they appear to customers in the real world.') }</h6>
                                <BusinessProfile onboarding onSave={() => history.push('/onboarding/locale')} />
                            </>
                        )
                    }

                    {
                        state === 'locale' && (
                            <>
                                <h1>{ t('tellUsAboutYourBusiness', 'Tell us about your business') }</h1>
                                <h6>{ t('localeUse', 'Your business language and timezone allow us to send your customers the right message at the right time.') }</h6>
                                <Locale onboarding onSave={() => history.push('/onboarding/hours-of-operation')} />
                            </>
                        )
                    }

                    {
                        state === 'hours-of-operation' && (
                            <>
                                <h1>{ t('tellUsAboutYourBusiness', 'Tell us about your business') }</h1>
                                <h6>{ t('hoursOfOperationUse', "Let your customers know when you're open! If you need to specify holiday hours you can set these later on your settings page.") }</h6>
                                <HoursOfOperation onboarding onSave={() => history.push('/onboarding/hourly-rate')} />
                            </>
                        )
                    }

                    {
                        state === 'hourly-rate' && (
                            <>
                                <h1>{ t('tellUsAboutYourBusiness', 'Tell us about your business') }</h1>
                                <h6>{ t('hourlyRateUse', 'We use your hourly rate to make creating invoices and estimates easier.') }</h6>
                                <HourlyRate onboarding onSave={() => history.push('/onboarding/sales-taxes')} />
                            </>
                        )
                    }

                    {
                        state === 'sales-taxes' && (
                            <>
                                <h1>{ t('tellUsAboutYourBusiness', 'Tell us about your business') }</h1>
                                <h6>{ t('salesTaxUse', 'We use your sales tax information for accurate year-end reporting.') }</h6>
                                <SalesTaxes onboarding onSave={() => history.push('/onboarding/thank-you')} />
                            </>
                        )
                    }

                </div>
                <div/>
            </div>
        </div>
    )

}
import React from 'react'

import { Img } from 'components/Tueri'

import './estimate.scss'
import { FormatCurrency } from 'components/Format'

export default function Estimate({ estimate }) {

    const thumbnailOptions = {}

    const file = estimate.files[0]

    if (file.mimetype === 'application/pdf') {
        thumbnailOptions.fm = 'jpg'
    }

    return (
        <div className='estimate__container'>
            <div className='estimate__card'>
                <Img src={`https://cdn.tueri.io/68719476737/autoservice/${ file.key.replace('uploads/', '') }`} options={ thumbnailOptions } alt={ 'test' } />
            </div>
            <div className='estimate__amounts'>
                Amount: $<FormatCurrency>{ estimate.total }</FormatCurrency>
            </div>
        </div>
    )

}
import React from 'react'

import { Link, useHistory } from 'react-router-dom'

import './button.scss'
import { useApolloClient } from '@apollo/react-hooks'
import { useAuthentication } from 'hooks/authentication'
import { useTranslation } from 'react-i18next'

export default function Button({ children, onClick, className, style, to, disabled }) {

    if (to) return <Link to={ to } className={`button ${ className }`}>{ children }</Link>

    return (
        <button type='button' className={`button ${ className }`} onClick={ onClick } disabled={ disabled } style={style}>{ children }</button>
    )
}

export function CancelButton({ or, ...rest }) {

    const { t } = useTranslation()

    return (
        <>
            { or && <span className='button__or'>{ t('clickThisOrCancel', 'or') }</span> }
            <Button { ...rest } className='button__cancel' />
        </>
    )
}

export function AddRecordButton({  children, ...rest }) {
    return (
        <div
            style={{
                textAlign: 'center'
            }}
        >
            <Button { ...rest } className='button__addRecord'><i className='material-icons'>add_circle_outline</i>{ children }</Button>
        </div>
    )
}

export function PrimaryButton({ ...rest }) {
    return (
        <Button className='button__primary' { ...rest } />
    )
}

export function SecondaryButton({ className, ...rest }) {
    return (
        <Button className={`button__secondary ${ className ? className : '' }`} { ...rest } />
    )
}

export function DestroyButton({ className, ...rest }) {
    return (
        <Button className={`button__destroy ${ className ? className : '' }`} { ...rest } />
    )
}

export function CtaButton({ ...rest }) {
    return (
        <Button className='button__cta' { ...rest } />
    )
}

export function LogoutButton({ ...rest }) {

    const { setAuthenticated } = useAuthentication()
    const history = useHistory()
    const client = useApolloClient()

    return (
        <Button className='button__logout' { ...rest }
            onClick={() => {
                setAuthenticated(false)
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken')
                client.clearStore()
                history.replace('/')
            }}
        >Logout <i className='material-icons'>exit_to_app</i></Button>
    )
}

export function IconButton({ icon, children, ...rest }) {
    return (
    <Button className='button__icon' { ...rest }><i className='material-icons'>{ icon }</i>{ children }</Button>
    )
}
import React from 'react'

import { useQuery } from '@apollo/react-hooks'
import { GET_VEHICLE_BY_ID } from 'operations/Vehicle'

import { Img } from 'components/Tueri'
import Loader from 'components/Loader'

import Upload from 'components/Upload'

export default function VEHICLE({ vehicle: { id } }) {

    const { error, loading, data } = useQuery(GET_VEHICLE_BY_ID, { variables: { id } })

    if (error || loading) return <Loader />

    const vehicle = data.vehicleById

    return (
        <div className='appointment__vehicleContainer'>
            <div className='appointment__vehiclePicture'>
                {
                    !vehicle || !vehicle.images || vehicle.images.length < 1 ? (
                        <>{ vehicle ? <Upload type='vehicle' vehicleId={ vehicle.id } /> : '' }</>
                    ) : (
                        <Img src={ vehicle.images[0].location } alt={ `${ vehicle.year } ${ vehicle.make } ${ vehicle.model }` } />
                    )
                }
            </div>

            {
                vehicle && <div className='appointment__vehicleString'>{ vehicle.year } { vehicle.make } { vehicle.model }</div>
            }

            

        </div>
    )
}
import gql from 'graphql-tag'

export const GET_DEVICES = gql`
    query GetDevices {
        devices {
            id
            name
            isActive
            deviceType
            deviceInfo
        }
    }
`

export const LINK_DEVICE_REQUEST = gql`
    query LinkDeviceRequest($payload: LinkDeviceRequestPayload!) {
        linkDeviceRequest(payload: $payload) {
            id
            isActive
            deviceToken
        }
    }
`

export const LINK_DEVICE = gql`
    mutation LinkDevice($payload: LinkDevicePayload!) {
        device: linkDevice(payload: $payload) {
            id
            name
            isActive
            deviceType
            deviceInfo
            deviceToken
        }
    }
`

export const CHECK_DEVICE_STATUS = gql`
    query CheckDeviceStatus($device: String!) {
        checkDeviceStatus(device: $device) {
            isActive
        }
    }
`
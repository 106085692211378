import React, { useState } from 'react'

import Card from 'components/Card'
import { useMutation } from '@apollo/react-hooks'
import { ADD_SURVEY_RESULT } from 'operations/Survey'

import Form, { Field, Submit } from 'components/Form'

import { CancelButton } from 'components/Button'

import './survey.scss'
import { useHistory } from 'react-router-dom'

import { useTranslation, Trans } from 'react-i18next'

export default function Survey({ type, action, question }) {

    const { t } = useTranslation()

    const history = useHistory()

    const [ state, setState ] = useState('SCORE')
    const [ disabled, setDisabled ] = useState()
    const [ response, setResponse ] = useState()
    const [ addSurveyResult ] = useMutation(ADD_SURVEY_RESULT)
    
    async function handleCesResult(score) {

        setDisabled(true)

        try {
            const { data } = await addSurveyResult({
                variables: {
                    payload: {
                        ...(response && { id: response.id }),
                        surveyType: 'CES',
                        action,
                        completedBy: 'INSTALLER',
                        medium: 'BROWSER',
                        score,
                    }
                }
            })

            setResponse(data.addSurveyResult)
            setState('COMMENT')
            setDisabled()

        }
        catch (err) {
            console.log(err)
        }

    }

    const RATINGS = {
        CES: {
            1: t('veryDifficult', 'very difficult'),
            2: t('difficult', 'difficult'),
            3: t('neither', 'neither'),
            4: t('easy', 'easy'),
            5: t('veryEasy', 'very easy')
        },
        NPS: {
    
        }
    }

    return (
        <Card className='surveyContainer'>
            <h2>{ question }</h2>
            {
                type === 'CES' && state === 'SCORE' && (
                    <div className='cesSurvey'>
                        <CesButton onClick={() => handleCesResult(5)} disabled={ disabled }>{ t('cesVeryEasyAnswer', 'Very easy') }</CesButton>
                        <CesButton onClick={() => handleCesResult(4)} disabled={ disabled }>{ t('cesEasyAnswer', 'Easy') }</CesButton>
                        <CesButton onClick={() => handleCesResult(3)} disabled={ disabled }>{ t('cesNeitherAnswer', 'Neither') }</CesButton>
                        <CesButton onClick={() => handleCesResult(2)} disabled={ disabled }>{ t('cesDifficultAnswer', 'Difficult') }</CesButton>
                        <CesButton onClick={() => handleCesResult(1)} disabled={ disabled }>{ t('cesVeryDifficultAnswer', 'Very difficult') }</CesButton>
                    </div>
                )
            }

            {
                state === 'COMMENT' && (
                    <Form
                        onSubmit={ async ({ rawInputs, enableSubmit }) => {
                            try {
                                await addSurveyResult({
                                    variables: {
                                        payload: {
                                            id: response.id,
                                            comment: rawInputs.comment
                                        }
                                    }
                                })
                    
                                enableSubmit()
                                history.replace('/')
                    
                            }
                            catch (err) {
                                console.log(err)
                            }
                        }}
                    >
                        {() => {

                            const surveyResponse = RATINGS[response.surveyType][response.score]
                            
                            return (
                            <>
                                <h3><Trans i18nKey='surveyResponse'>You said it was <strong>{{ surveyResponse }}</strong>.</Trans><CancelButton onClick={() => setState('SCORE')}>{ t('changeSurveyAnswer', 'Change answer?') }</CancelButton></h3>
                                <Field type='textarea' name='comment' label={ t('anyComments', 'Any comments?') }/>
                                <div><Submit>{ t('addCommentButton', 'Add comment') }</Submit><CancelButton or onClick={() => history.replace('/')}>{ t('skipButton', 'Skip') }</CancelButton></div>
                            </>
                        )}}
                    </Form>
                )
            }
        </Card>
    )

}

function CesButton({ children, onClick, disabled }) {
    return <button className='surveyButton' onClick={ onClick }>{ children }</button>
}
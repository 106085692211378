import React, { useState } from 'react'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_INSTALLER, UPDATE_INSTALLER } from 'operations/Installer'

import Form, { Fieldset, Field, Submit } from 'components/Form'

import { Link } from 'react-router-dom'

import validator from 'validator'

import './hours.scss'
import Loader from 'components/Loader'

import { useTranslation } from 'react-i18next'

export default function HoursOfOperation({ onboarding, onSave }) {

    const { t } = useTranslation()

    const { error, loading, data } = useQuery(GET_INSTALLER)
    const [ updateInstaller ] = useMutation(UPDATE_INSTALLER)
    const [errors, setErrors] = useState({})

    if (error || loading) return <Loader />

    const { businessHours } = data.installer

    const defaultValues = {}
    businessHours && Object.keys(businessHours).map(day => {
        const today = businessHours[day]
        defaultValues[`${ day }.isOpen`] = { value: today.isOpen.toString(), rawValue: today.isOpen.toString() }
        today && today.hours && Object.keys(today.hours).map((hour) => {
            defaultValues[`${ day }.hours.${hour}.opensAt`] = { value: today.hours ? today.hours[hour].opensAt : '', rawValue: today.hours ? today.hours[hour].opensAt : '' }
            defaultValues[`${ day }.hours.${hour}.closesAt`] = { value: today.hours ? today.hours[hour].closesAt : '', rawValue: today.hours ? today.hours[hour].closesAt : '' }
            return null
        })
        return null
    })

    const Element = onboarding ? React.Fragment : 'div'

    return (
            <Element { ...(Element === 'div' && { className: 'bodyContainer'}) }>
            { !onboarding && <h1><Link to='/settings'>{ t('settings', 'Settings') }</Link><i className='material-icons'>chevron_right</i><span className='current'>{ t('hoursOfOperation', 'Hours of operation') }</span></h1> }
            <Form
                init={defaultValues}
                onSubmit={ async ({ inputs, enableSubmit }) => {

                    const updateData = {}
                    Object.keys(inputs).map(input => {
                        const fields = input.split('.')
                        if (!updateData[fields[0]]) updateData[fields[0]] = {hours: {}}
                        if (fields.length === 2 && fields[1] === 'isOpen') updateData[fields[0]] = {
                            ...updateData[fields[0]],
                            isOpen: inputs[input].rawValue === 'true' ? true : false
                        }
                        if (fields.length === 4) {
                            updateData[fields[0]].hours = {
                                ...updateData[fields[0]].hours,
                                [fields[2]]: {
                                    ...updateData[fields[0]].hours[fields[2]],
                                    [fields[3]]: inputs[input].rawValue
                                }
                            }
                        }
                        return null
                    })

                    const formErrors = {}
                    Object.keys(updateData).map(dayKey => {
                        const day = updateData[dayKey]
                        if (day.isOpen) {
                            return Object.keys(day.hours).map(key => {
                                if (validator.isEmpty(day.hours[key].opensAt) || validator.isEmpty(day.hours[key].closesAt)) return formErrors[dayKey] = true
                                return null
                            })
                        }
                        return null
                    })

                    if (Object.keys(formErrors).length > 0) {
                        enableSubmit()
                        return setErrors(formErrors)
                    }

                    try {
                        await updateInstaller({
                            variables: {
                                payload: {
                                    businessHours: updateData
                                }
                            }
                        })
                        enableSubmit()
                        onSave && onSave()
                    }
                    catch(err) {
                        console.log(err)
                        enableSubmit()
                    }
                }}
            >
                {({ rawInputs }) => {
                    return (
                    <div style={{ maxWidth: '480px' }}>
                        {['sunday','monday','tuesday','wednesday','thursday','friday','saturday'].map(day => {
                            return <React.Fragment key={ day }>
                                <Day
                                    isOpen={rawInputs[`${day}.isOpen`] === 'true' ? true : false} 
                                    day={ day } 
                                    businessHours={ businessHours && businessHours[day] }
                                    error={ errors[day] }
                                />
                            </React.Fragment>
                        })}
                        <Submit>{ onboarding ? t('next', 'Next') : t('save', 'Save') }</Submit>
                    </div>
                )}}
            </Form>
        </Element>
    )

}


function Day({ day, isOpen, businessHours, error }) {

    const { t } = useTranslation()

    const [ state, setState ] = useState(businessHours && businessHours.hours && Object.keys(businessHours.hours).length > 0 ? Object.keys(businessHours.hours).length - 1 : 0)

    const hourSets = []
    for (let i = 1; i <= state; i++) {
        hourSets.push(i)
    }

    const DAYS = {
        sunday: t('sunday', 'Sunday'),
        monday: t('monday', 'Monday'),
        tuesday: t('tuesday', 'Tuesday'),
        wednesday: t('wednesday', 'Wednesday'),
        thursday: t('thursday', 'Thursday'),
        friday: t('friday', 'Friday'),
        saturday: t('Saturday')
    }

    return (
        <Fieldset legend={DAYS[day]}>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 48px',
                    gridGap: '1rem'
                }}
            >
                <Field type='select' name={`${ day }.isOpen`} label={ t('hoursOfOperationStatus', 'Status') } options={[['true', t('businessHoursOpen', 'Open')], ['false', t('businessHoursClosed', 'Closed')]]} required error={ error } errorMessage={ t('required', 'Required') } />
                {
                    isOpen && (
                        <>
                            <Field type='select' name={`${ day }.hours.0.opensAt`} label={ t('opensAt', 'Opens at') } options={TIME} required error={ error } errorMessage={ t('required', 'Required') } />
                            <Field type='select' name={`${ day }.hours.0.closesAt`} label={ t('closesAt', 'Closes at') } options={TIME} required error={ error } errorMessage={ t('required', 'Required') } />
                            <div>{ state === 0 && <AddButton onClick={() => setState(state => (state + 1))} />}</div>
                            {
                                hourSets && hourSets.length > 0 && hourSets.map(set => {
                                    return (
                                        <React.Fragment key={set}>
                                            <div />
                                            <Field type='select' name={`${ day }.hours.${ set }.opensAt`} label={ t('opensAt', 'Opens at') } options={TIME} required error={ error } errorMessage={ t('required', 'Required') } />
                                            <Field type='select' name={`${ day }.hours.${ set }.closesAt`} label={ t('closesAt', 'Closes at') } options={TIME} required error={ error } errorMessage={ t('required', 'Required') } />
                                            <div style={{ textAlign: 'left' }}>
                                            { state < 2 && state === set && <AddButton onClick={() => setState(state => (state + 1))} /> }
                                            { state === set && <AddButton type='remove' onClick={() => setState(state => (state - 1))} /> }
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </>
                    )
                }
                
            </div>
        </Fieldset>
    )
}

function AddButton({ onClick, type }) {
    return (
        <button type='button' className='hoursAddButton' onClick={ () => onClick && onClick() }><i className='material-icons'>{ type === 'remove' ? 'remove_circle' : 'add_circle' }</i></button>
    )
}

const TIME = [
    '05:00',
    '05:15',
    '05:30',
    '05:45',
    '06:00',
    '06:15',
    '06:30',
    '06:45',
    '07:00',
    '07:15',
    '07:30',
    '07:45',
    '08:00',
    '08:15',
    '08:30',
    '08:45',
    '09:00',
    '09:15',
    '09:30',
    '09:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
    '13:00',
    '13:15',
    '13:30',
    '13:45',
    '14:00',
    '14:15',
    '14:30',
    '14:45',
    '15:00',
    '15:15',
    '15:30',
    '15:45',
    '16:00',
    '16:15',
    '16:30',
    '16:45',
    '17:00',
    '17:15',
    '17:30',
    '17:45',
    '18:00',
    '18:15',
    '18:30',
    '18:45',
    '19:00',
    '19:15',
    '19:30',
    '19:45',
    '20:00',
    '20:15',
    '20:30',
    '20:45',
    '21:00',
    '21:15',
    '21:30',
    '21:45',
]
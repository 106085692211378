import React, { createContext, useEffect } from 'react'
import { useLazyQuery, useSubscription } from '@apollo/react-hooks'
import { useAuthentication } from './authentication'
import { SUBSCRIBE_APPOINTMENT_UPDATED, GET_APPOINTMENT_BY_ID } from 'operations/Appointment'

const AppointmentContext = createContext()

export default function AppointmentProvider({ children }) {

    const { user } = useAuthentication()
    const [getAppointment] = useLazyQuery(GET_APPOINTMENT_BY_ID, { fetchPolicy: 'network-only' })
    const { data: subscriptionData, loading: subscriptionLoading } = useSubscription(SUBSCRIBE_APPOINTMENT_UPDATED, { variables: { filter: { installerId: user.asp } } })
    
    useEffect(() => {
        if (!subscriptionLoading && subscriptionData && subscriptionData.appointmentUpdated && subscriptionData.appointmentUpdated.id) getAppointment({ variables: { id: subscriptionData.appointmentUpdated.id } })
        // eslint-disable-next-line
    }, [subscriptionLoading, subscriptionData])

    return (
        <AppointmentContext.Provider>
            { children }
        </AppointmentContext.Provider>
    )

}
import React from 'react'

const EXCEPTIONED_INITIALS = [
    'BS',
    'VD',
    'FU',
    'BJ',
    'MF'
]

export default function Initials({ name }) {

    if (!name) return <i className='material-icons'>account_circle</i>

    const userName = name.split(' ')
    const firstInitial = userName[0].substr(0, 1)
    const lastInitial = userName[1].substr(0, 1)

    const initials = EXCEPTIONED_INITIALS.find((exception) => exception === firstInitial + lastInitial) ? firstInitial : firstInitial + lastInitial

    return (
        <>
            <div className='initials'>{ initials }</div>
        </>
    )
}
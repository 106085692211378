import React from 'react'

import Card, { CardTitle } from 'components/Card'

import { useAuthentication } from 'hooks/authentication'
import { useQuery } from '@apollo/react-hooks'
import { ME } from 'operations/Auth'
import Loader from 'components/Loader'
import { FormatPhone } from 'components/Format'

import { LogoutButton } from 'components/Button'

import { useTranslation } from 'react-i18next'

import './account.scss'

export default function Settings() {

    const { t } = useTranslation()

    const { user } = useAuthentication()

    const { error, loading, data } = useQuery(ME)

    if (error || loading) return <Loader />

    const { me } = data

    const USER_TYPES = {
        INSTALLER: t('userTypeAdministrator', 'Administrator'),
        SERVICE_ADVISOR: t('userTypeServiceAdvisor', 'Service Advisor'),
        TECHNICIAN: t('userTypeTechnician', 'Technician')
    }

    return (
        <div className='bodyContainer'>

            <h1>{ t('myAccount', 'My account') }</h1>

            <LogoutButton className='button__logout tour-sign-out' />

            <Card
                style={{
                    display: 'grid',
                    gridTemplateColumns: `
                        minMax(200px, 300px)
                        minMax(200px, 300px)
                        minMax(200px, 300px)
                        minMax(200px, 300px)
                    `
                }}
            >
                <CardTitle title={ t('fullName', 'Name') }>
                    { me.firstName } { me.lastName }
                </CardTitle>
                <CardTitle title={ t('userType', 'User type') }>
                    {
                        USER_TYPES[user.typ]
                    }                                
                </CardTitle>
                <CardTitle title={ t('email', 'Email') }>
                    {
                        me.email || t('notSpecified', 'Not specified')
                    }                                
                </CardTitle>
                <CardTitle title='Phone'>
                    {
                        me.mobileNumber ? <FormatPhone>{ me.mobileNumber }</FormatPhone> : t('notSpecified', 'Not specified')
                    }                                
                </CardTitle>
            </Card>      

        </div>
    )
}
import React, { useState } from 'react'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_INSTALLER, UPDATE_INSTALLER } from 'operations/Installer'

import Form, { Fieldset, Field, Submit } from 'components/Form'

import { Link } from 'react-router-dom'
import validator from 'validator'
import Loader from 'components/Loader'

import { useTranslation } from 'react-i18next'

export default function HourlyRate({ onboarding, onSave }) {

    const { t } = useTranslation()

    const { error, loading, data } = useQuery(GET_INSTALLER)
    const [ updateInstaller ] = useMutation(UPDATE_INSTALLER, { refetchQueries: [ { query: GET_INSTALLER } ] })
    const [ errors, setErrors ] = useState({})

    if (error || loading) return <Loader />

    const Element = onboarding ? React.Fragment : 'div'

    return (
        <Element { ...(Element === 'div' && { className: 'bodyContainer' }) }>
            { !onboarding && <h1><Link to='/settings'>{ t('settings', 'Settings') }</Link><i className='material-icons'>chevron_right</i><span className='current'>{ t('hourlyRateTitle', 'Hourly rate') }</span></h1> }
            <Form
                onSubmit={ async ({ rawInputs, enableSubmit }) => {

                    const formErrors = {}
                    if (validator.isEmpty(rawInputs.hourlyRate)) formErrors.hourlyRate = true

                    if (Object.keys(formErrors).length > 0) {
                        enableSubmit()
                        return setErrors(formErrors)
                    }

                    try {
                        await updateInstaller({
                            variables: {
                                payload: {
                                    hourlyRate: Number(rawInputs.hourlyRate)
                                }
                            }
                        })
                        enableSubmit()
                        onSave && onSave()
                    }
                    catch(err) {
                        console.log(err)
                        enableSubmit()
                    }
                }}
            >
                {() => (
                    <>
                        <Fieldset legend={ t('hourlyRateTitle', 'Hourly Rate') } style={{ maxWidth: '480px' }}>
                            <Field name='hourlyRate' label={ t('hourlyRateTitle', 'Hourly Rate') } type='decimal' helper-={ (data.installer.hourlyRate || 95).toFixed(2) } maxLength={6} defaultValue={ (data.installer.hourlyRate || '').toString() } required error={ errors.hourlyRate } errorMessage={ t('hourlyRateError', 'Please enter an hourly rate.') } />
                        </Fieldset>
                        <Submit>{ onboarding ? t('next', 'Next') : t('save', 'Save') }</Submit>
                    </>
                )}
            </Form>
        </Element>
    )

}
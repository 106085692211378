import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { useModal } from 'hooks/modal'

import Header from 'components/Header'
import Initials from 'components/Initials'
import { useQuery } from '@apollo/react-hooks'
import { ME } from 'operations/Auth'
import { VERSION } from 'pages/Release'
import { GET_UNREAD_MESSAGES } from 'operations/Message'
import { useSubscription } from 'hooks/subscription'
import { useTranslation } from 'react-i18next'

export default function Layout({ children }) {

    const { t } = useTranslation()

    const { modal } = useModal()
    const { subscriptionStatus } = useSubscription()

    const location = useLocation()

    return (
        <div className={`wrapper ${ modal || (['TRIAL_EXPIRED', 'CANCELLED'].includes(subscriptionStatus) && location.pathname !== '/settings/billing') ? 'wrapper--blur' : '' }`}>
            <Header>
                <div>
                    <IconLink exact to='/' icon='speed' >{ t('navLinkDashboard', 'Dashboard') }</IconLink>
                    <IconLink to='/schedule' className='tour-schedule' icon='view_column'>{ t('navLinkSchedule', 'Schedule') }</IconLink>
                    <IconLink to='/customers' className='tour-customers' icon='person'>{ t('navLinkCustomers', 'Customers') }</IconLink>
                    <ChatLink />
                    <IconLink to='/settings' icon='settings'>{ t('navLinkSettings', 'Settings') }</IconLink>
                </div>
                <div>
                    <VersionLink />
                    <SupportLink />
                    <UserLink />
                </div>
            </Header>

            <section className='container'>
                { children }
            </section>
        </div>
    )

}

function IconLink({ to, icon, children, exact, className }) {

    return (
        <NavLink to={ to } exact={exact} className={ className }>
            <i className='material-icons'>{ icon }</i>
            { children }
        </NavLink>
    )
}

function UserLink() {

    const { data } = useQuery(ME)

    // console.log(data)

    return (
        <NavLink to='/account' className='userLink'>
            <Initials name={ data ? data.me.firstName + ' ' + data.me.lastName : null } />
        </NavLink>
    )
}

function VersionLink() {

    const { t } = useTranslation()

    const location = useLocation()
    const [, setVersion] = useState(VERSION)

   useEffect(() => {
       const version = localStorage.getItem('version') || null
        setVersion(version)
   },[location])

    return (
        <a target="_blank" rel="noopener noreferrer" href='https://support.napaconnect.ca/changelog/changelog' className='versionLink'>{ t('versionNumberPrefix', 'Ver') } { VERSION }
            {/* { version !== VERSION && <div className='versionNotification'>!</div> } */}
        </a>
    )
}

function ChatLink() {
    
    const { t } = useTranslation()

    const { data } = useQuery(GET_UNREAD_MESSAGES, { pollInterval: 10000 })

    return (
        <IconLink to='/chat' icon='chat' className='chatLink'>{ t('chat', 'Chat') }
            { data && data.unreadMessages ? <div className='unreadMessagesNotification'>{ data && data.unreadMessages }</div> : null }
        </IconLink>
    )
}

function SupportLink() {

    const { t } = useTranslation()

    return (
        <a target="_blank" rel="noopener noreferrer" href='https://support.napaconnect.ca'><i className='material-icons'>help</i>{ t('navLinkSupport', 'Support') }</a>
    )
}
import gql from 'graphql-tag'

export const ADD_INVOICE = gql`
    mutation AddInvoice($payload: AddInvoicePayload!) {
        addInvoice(payload: $payload) {
            id
            status
            invoice {
                id
                description
                files
                currency
                subtotal
                taxCode
                taxRate
                tax
                total
                isPaid
                createdAt
                updatedAt
            }
        }
    }
`
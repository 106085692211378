import gql from 'graphql-tag'

export const ADD_TRANSACTION = gql`
    mutation AddTransaction($payload: AddTransactionPayload!) {
        addTransaction(payload: $payload) {
            id
            status
            isPaid
            invoice {
                id
                isPaid
                transactions {
                    id
                    paymentMethod
                    cardType
                    lastFourDigits
                    chequeNumber
                    confirmationNumber
                    amount
                }
            }
        }
    }
`

export const GET_STATS = gql`
    query GetStats($statsPayload: StatsPayload!) {
        stats {
            grossVolume(payload: $statsPayload) {
                year
                month
                day
                hour
                value
            }
            vehiclesServiced(payload: $statsPayload) {
                year
                month
                day
                hour
                value
            }
            avgRepairOrderAmount(payload: $statsPayload) {
                year
                month
                day
                hour
                value
            }
            avgAppointmentDuration(payload: $statsPayload) {
                year
                month
                day
                hour
                value
            }
            npsSurveyResults(payload: $statsPayload) {
                year
                month
                day
                hour
                total
                promoters
                passives
                detractors
            }
        }
    }
`
import React, { useState } from 'react'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_INSTALLER, UPDATE_INSTALLER } from 'operations/Installer'

import Form, { Fieldset, Field, Submit } from 'components/Form'

import { Link } from 'react-router-dom'

import validator from 'validator'
import Loader from 'components/Loader'

import { useTranslation } from 'react-i18next'

const PROVINCES = [
    ['AB', 'Alberta'],
    ['BC', 'British Columbia'],
    ['MB', 'Manitoba'],
    ['NB', 'New Brunswick'],
    ['NL', 'Newfoundland and Labrador'],
    ['NT', 'Northwest Territories'],
    ['NS', 'Nova Scotia'],
    ['NU', 'Nunavut'],
    ['ON', 'Ontario'],
    ['PE', 'Prince Edward Island'],
    ['QC', 'Quebec'],
    ['SK', 'Saskatchewan'],
    ['YT', 'Yukon']
]

export default function BusinessProfile({ onboarding, onSave }) {

    const { t } = useTranslation()

    const { error, loading, data } = useQuery(GET_INSTALLER)
    const [ updateInstaller ] = useMutation(UPDATE_INSTALLER, { refetchQueries: [ { query: GET_INSTALLER } ] })
    const [errors, setErrors] = useState({})

    if (error || loading) return <Loader />

    const Element = onboarding ? React.Fragment : 'div'

    return (
        <Element { ...( Element === 'div' && { className: 'bodyContainer' }) }>
            {
                !onboarding && (
                    <>
                        <h1><Link to='/settings'>{ t('settings', 'Settings') }</Link><i className='material-icons'>chevron_right</i><span className='current'>{ t('businessProfileTitle', 'Business profile') }</span></h1>
                        <h2>{ t('publicContactDetails', 'Public contact details') }</h2>
                    </>
                )
            }
            
            <Form
                onSubmit={ async ({ rawInputs, enableSubmit }) => {

                    const formErrors = {}

                    if (validator.isEmpty(rawInputs.businessName)) formErrors.businessName = true
                    if (validator.isEmpty(rawInputs.legalName)) formErrors.legalName = true
                    if (validator.isEmpty(rawInputs.ownerName)) formErrors.ownerName = true
                    if (validator.isEmpty(rawInputs.address)) formErrors.address = true
                    if (validator.isEmpty(rawInputs.city)) formErrors.city = true
                    if (validator.isEmpty(rawInputs.provinceCode)) formErrors.provinceCode = true
                    if (validator.isEmpty(rawInputs.postal) || (!validator.isPostalCode(rawInputs.postal, 'CA') && !validator.isPostalCode(rawInputs.postal, 'US'))) formErrors.postal = true
                    if (validator.isEmpty(rawInputs.phone) || !validator.isMobilePhone(rawInputs.phone, 'en-US')) formErrors.phone = true
                    if (validator.isEmpty(rawInputs.email) || !validator.isEmail(rawInputs.email)) formErrors.email = true
                    if (!validator.isEmpty(rawInputs.website) && !validator.isURL(rawInputs.website, { require_protocol: true })) formErrors.website = true
                    
                    if (Object.keys(formErrors).length > 0) {
                        enableSubmit()
                        return setErrors(formErrors)
                    }

                    try {
                        await updateInstaller({
                            variables: {
                                payload: {
                                    ...rawInputs,
                                    
                                }
                            }
                        })
                        enableSubmit()
                        onSave && onSave()
                    }
                    catch(err) {
                        console.log(err)
                        enableSubmit()
                    }
                }}
            >
                {() => (
                    <>
                        <Element
                            {
                                ...(Element === 'div' && {
                                    className: 'businessGrid'
                                })
                            }
                            
                        >
                            <Fieldset legend={ t('businessTitle', 'Business') }>
                                <Field name='businessName' label={ t('businessName', 'Business name') } defaultValue={ (data.installer.businessName || '') } required error={ errors.businessName } errorMessage={ t('businessNameError', 'Please enter the business name.') } />
                                <Field name='legalName' label={ t('legalName', 'Legal name') } defaultValue={ (data.installer.legalName || '') } required error={ errors.legalName } errorMessage={ t('legalNameError', 'Please enter the legal business name.') } />
                                <Field name='ownerName' label={ t('ownerName', `Owner's name`) } defaultValue={ (data.installer.ownerName || '') } required error={ errors.ownerName } errorMessage={ t('ownerNameError', `What is the business owner's name?`) } />
                            </Fieldset>
                            <Fieldset legend={ t('locationTitle', 'Location') }>
                                <Field name='address' label={ t('address', 'Address') } defaultValue={ (data.installer.address || '') } required error={ errors.address } errorMessage={ t('addressError', 'Please enter your business address.') } />
                                <Field name='addressLine2' label={ t('addressLine2', 'Address Line 2') } defaultValue={ (data.installer.addressLine2 || '') } />
                                <Field name='city' label={ t('city', 'City') } defaultValue={ (data.installer.city || '') } required error={ errors.city } errorMessage={ t('cityError', 'Please enter a city.') } />
                                <Field name='provinceCode' label={ t('province', 'Province') } type='select' options={PROVINCES} defaultValue={ (data.installer.provinceCode || '') } required error={ errors.provinceCode } errorMessage={ t('provinceError', 'Please select a province.') } />
                                {/* TODO: add provinces */}
                                <Field name='postal' label={ t('postalCode', 'Postal Code') } type='postal' defaultValue={ (data.installer.postal || '') } required error={ errors.postal } errorMessage={ t('postalCodeError', 'Please enter a valid postal code.') } />
                            </Fieldset>
                            <Fieldset legend='Contact'>
                                <Field name='phone' label={ t('phoneNumber', 'Phone Number') } type='tel' defaultValue={ (data.installer.phone || '') } required error={ errors.phone } errorMessage={ t('phoneError', 'Please enter a valid phone number.') } />
                                <Field name='email' label={ t('email', 'Email') } type='email' defaultValue={ (data.installer.email || '') } required error={ errors.email } errorMessage={ t('businessEmailError', 'Please enter your business email address.') } />
                                <Field name='website' label={ t('websiteUrl', 'Website') } type='url' defaultValue={ (data.installer.website || '') } helper={ t('urlHelper', 'https://example.com') } error={ errors.website } errorMessage={ t('websiteUrlError', 'Please enter a valid url: ie. https://example.com') } />
                            </Fieldset>
                        </Element>
                        <Submit>{ onboarding ? t('next', 'Next') : t('save', 'Save') }</Submit>
                    </>
                )}
            </Form>
        </Element>
    )

}
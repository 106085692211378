import React, { useState } from 'react'

import Form, { Fieldset, Field, Submit } from 'components/Form'
import validator from 'validator'

import { useTranslation } from 'react-i18next'

export default function AppointmentDetails({ onChange }) {

    const { t } = useTranslation()

    const [ errors, setErrors ] = useState({})

    return (
        <Form
            onSubmit={({rawInputs, enableSubmit}) => {

                const formErrors = {}
                if (validator.isEmpty(rawInputs.description)) formErrors.description = true
                if (validator.isEmpty(rawInputs.isPickup)) formErrors.isPickup = true

                if (Object.keys(formErrors).length > 0) {
                    enableSubmit()
                    return setErrors(formErrors)
                }

                onChange && onChange({
                    ...rawInputs,
                    isPickup: rawInputs.isPickup === 'true' ? true : false
                })
                enableSubmit()
            }}
        >
            {() => (
                <div className='modalForm'>
                    <Fieldset legend={ t('appointmentDetails', 'Appointment details') }>

                        <Field name='description' label={ t('description', 'Description') } helper='Visible to customer' type='textarea' required error={ errors.description } errorMessage={ t('appointmentDescriptionError', 'Please add a description for the appointment.') }/>

                        <Field type='select' name='isPickup' label={ t('vehiclePickupSelect', 'Vehicle Pickup') } options={[[true, t('vehicleWillPickUp', 'Pickup customer vehicle.')], [false, t('vehicleWillDropOff', 'Vehicle will be dropped off.')]]} required error={ errors.isPickup } errorMessage={ t('vehicleDropOffPickUpSelectError', 'Will this vehicle be dropped off or picked up?') } />
                        
                        <Submit>{ t('addAppointmentDetails', 'Add Appointment Details') }</Submit>
                    </Fieldset>
                </div>
            )}
        </Form>
    )

}
import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import Form, { Fieldset, Field, Submit } from 'components/Form'
import { useAuthentication } from 'hooks/authentication';
import { GET_DEVICE_LOGINS, AUTHENTICATE_TEAM_MEMBER } from 'operations/Auth'
import { useTranslation } from "react-i18next"
import ErrorMessage from 'components/ErrorMessage'
// import { Link } from 'react-router-dom'

import './login.scss'
// import { useDevice } from 'hooks/device';

import Card, { CardTitle, CardUserIcon } from 'components/Card'
import { CancelButton } from 'components/Button'

import NoData from 'components/NoData'

import validator from 'validator'
import Loader from 'components/Loader'

export default function Login() {

    const { t, i18n } = useTranslation()

	// const { t, i18n } = useTranslation()
	// const ERROR_MESSAGES = {
	// 	'USER_NOT_FOUND': t('loginErrorUserNotFound', 'Sorry, but we were unable to find an account with that username.'),
	// 	'BAD_PASSWORD_MATCH': t('loginErrorBadPasswordMatch', 'Sorry, but your password is incorrect.'),
	// 	'USER_INACTIVE': t('loginErrorUserInactive', 'Sorry, but your account has been deactivated.'),
	// 	'USER_INVALID_EMAIL': t('loginErrorUserInvalidEmail', 'Sorry, but your email has not been verified. Please check your inbox and spam/junk folders for an account activation email from accounts@mail.napalabourwarranty.com')
    // }

    const { setAuthenticated } = useAuthentication()

    // const { device } = useDevice()
    const { error: deviceLoginsError, loading, data } = useQuery(GET_DEVICE_LOGINS, { variables: { device: localStorage.getItem('deviceToken') }, fetchPolicy: 'network-only', pollInterval: 60000 })
    const [ authenticate ] = useMutation(AUTHENTICATE_TEAM_MEMBER)
    const [ user, setUser ] = useState()
    const [ errors, setErrors ] = useState({})
    const [error, setError] = useState()

    if (deviceLoginsError || loading) return <Loader />

    const { deviceLogins } = data

    const users = deviceLogins.users.filter(user => user.userType === deviceLogins.deviceType)
    const installers = deviceLogins.users.filter(user => user.userType === 'INSTALLER')

    const ERROR_MESSAGES = {
        'USER_NOT_FOUND': t('userNotFound', `We were unable to find a user with that username.`),
        'USER_INACTIVE': t('userDeactivated', 'Sorry, but this user has been deactivated.'),
        'BAD_PASSWORD_MATCH': t('badPasswordMatch', 'Sorry, but your password is incorrect.'),
        'BAD_PIN_MATCH': t('badPinMatch', 'Sorry, but your pin is incorrect.')
    }
    
    const DEVICE_TYPES = {
        'INSTALLER': t('administrators', 'Administrators'),
        'SERVICE_ADVISOR': t('serviceAdvisors', 'Service advisors'),
        'TECHNICIAN': t('technicians', 'Technicians')
    }
    

    return (
        <div className='login__container'>

            {/* <div className='login__sidebar'>

                <i className='material-icons'>directions_car</i>

            </div> */}

            <div className='login__body'>

                <div>

                <div className='logo'><i className='material-icons'>directions_car</i></div>

                <div className='deviceDescriptorContainer'><div className='deviceDescriptor'>{ t('deviceName', 'Device:') } { deviceLogins.name }</div></div>
				
                    <Form className='login' onSubmit={({rawInputs, enableSubmit}) => {

                        const formErrors = {}
                        if (validator.isEmpty(rawInputs.pinCode)) formErrors.pinCode = true
                        if (!user.hasPinCode && (validator.isEmpty(rawInputs.pinCodeConfirmation) || !validator.equals(rawInputs.pinCode, rawInputs.pinCodeConfirmation))) formErrors.pinCodeConfirmation = true

                        if (!user.hasPinCode && (user.userType === 'INSTALLER' && validator.isEmpty(rawInputs.password))) formErrors.password = true

                        if (Object.keys(formErrors).length > 0) {
                            enableSubmit()
                            return setErrors(formErrors)
                        }

                        authenticate({
                            variables: {
                                payload: {
                                    deviceToken: localStorage.getItem('deviceToken'),
                                    userId: user.id,
                                    pinCode: rawInputs.pinCode,
                                    password: rawInputs.password
                                }
                            }
                        })
                        .then(({ data: { authenticateTeamMember: authenticate } }) => {

                            console.log(data)
                            localStorage.setItem('accessToken', authenticate.accessToken)
                            localStorage.setItem('refreshToken', authenticate.refreshToken)
                            setAuthenticated(true)
                        })
                        .catch((err) => {
                            const { graphQLErrors } = err
                            graphQLErrors.forEach(({ message }) => {
                                setError(ERROR_MESSAGES[message])
                            })
                            enableSubmit()
                        })

                    }}>
                        {() => (
                            <>
                                {/* <h1>{ t('login', 'Login') }<button type='button' className='languageButton' onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en')}>{ i18n.language === 'fr' ? 'English' : 'Français' }</button></h1> */}
                                { user ? <h1>{ user.firstName } { user.lastName }</h1> : <h1>{ t('signIn', 'Sign in') }</h1> }

                                {
                                    error && (
                                        <ErrorMessage>{ error }</ErrorMessage>
                                    )
                                }

                                { !user && deviceLogins.deviceType !== 'INSTALLER' && <h2>{ DEVICE_TYPES[deviceLogins.deviceType] }</h2> }

                                {
                                    !user && deviceLogins.deviceType !== 'INSTALLER' && users && users.length < 1 && (<NoData><h2>{ t('noUsersOfType', "There aren't any {{ userType }} linked to this account.", { userType: deviceLogins.deviceType === 'SERVICE_ADVISOR' ? t('serviceAdvisorsSmall', 'service advisors') : t('techniciansSmall', 'technicians') }) }</h2>{ t('pleaseAddUsersOfType', 'Please add a {{ userType }} from your team member settings.', { userType: deviceLogins.deviceType === 'SERVICE_ADVISOR' ? t('serviceAdvisorSmall', 'service advisor') : t('technicianSmall', 'technician') }) }</NoData>)
                                }

                                {
                                    !user && deviceLogins.deviceType !== 'INSTALLER' && users && users.map(user => <User key={ user.id } data={ user } onClick={() => setUser(user)} />)
                                }

                                {
                                    !user && <h2>{ t('administrators', 'Administrators') }</h2>
                                }

                                {
                                    !user && installers && installers.map(user => <User key={ user.id } data={ user } onClick={() => setUser(user)} />)
                                }

                                {/* <PrimaryButton onClick={ () => refetch() } type='button'>Refresh { device.typ === 'SERVICE_ADVISOR' ? 'service advisor' : 'technician' } list</PrimaryButton> */}

                                {
                                    user && (
                                        <>
                                            {
                                                user.hasPinCode ? (
                                                    <Field label={ t('pin', 'PIN') } name="pinCode" type='pin' required errorMessage={ t('pinError', 'Please enter your pin code.') } focus/>
                                                ) : (
                                                    <>
                                                        <Fieldset legend={ t('setYourPinCode', 'Set your new pin code') }>
                                                            <Field label={ t('newPinCode', 'New PIN code') } type='pin' name="pinCode" required error={ errors.pinCode } errorMessage={ t('newPinCodeError', 'Please enter a pin code with 4 or more digits.') } focus helper={ t('newPinCodeHelper', 'Four or more digits') }/>
                                                            <Field label={ t('confirmPinCode', 'Confirm PIN code') } type='pin' name="pinCodeConfirmation" required error={ errors.pinCodeConfirmation } errorMessage={ t('confirmPinCodeError', 'PIN codes do not match.') } />
                                                        </Fieldset>
                                                        {
                                                            user.userType === 'INSTALLER' && (
                                                                <Fieldset legend={ t('enterPasswordConfirm', 'Enter your password to confirm') }>
                                                                    <Field label={ t('password', 'Password') } type='password' name='password' required error={ errors.password } errorMessage={ t('passwordError', 'Your password is incorrect.') } />
                                                                </Fieldset>
                                                            )                                                     
                                                        }
                                                    </>
                                                )
                                            }
                                            <Submit>{ t('signIn', 'Sign in') }</Submit><CancelButton onClick={() => setUser()} or>{ t('cancel', 'Cancel') }</CancelButton>
                                        </>
                                    )
                                }
                                
                                <div style={{ textAlign: 'right' }}><button type='button' className='languageSwitcher' onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en')}>{ t('languageSwitcher', 'Français') }</button></div>
                            
                            </>
                        )}
                    
                </Form>

                            {/* { !user && <div className='loginSignupSwitch'><Link to='/log-in'>Shop owner sign in</Link></div> } */}

                <div style={{ clear: 'both' }} />

            </div>

        </div>

    </div>
    )
    
    
}

function User({ data, onClick }) {

    const { t } = useTranslation()

    return (
        <Card
            style={{
                display: 'grid',
                gridTemplateColumns: `
                    70px
                    auto
                `,
                gridGap: '1rem'
            }}
            onClick={ onClick }
        >
            <CardUserIcon name={`${ data.firstName } ${ data.lastName }`}/>
            <CardTitle title={ t('name', 'Name') }>
                { data.firstName } { data.lastName }
            </CardTitle>
        </Card>
    )

}
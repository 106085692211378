import React from 'react'
// import { Link } from 'react-router-dom'

import './subNav.scss'

export function SubNav({ children }) {
    return (
        <div className='subNavContainer'>
            { children }
        </div>
    )
}
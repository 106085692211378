import { useEffect } from 'react'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-167628642-1')

export default function GAListener({ location }) {

    const page = location.pathname + location.search

    useEffect(() => {
        if (
            !page.includes('/oauth/')
            && !page.includes('/a/')
            && !page.includes('/r/')
        ) {
            ReactGA.set({ page })
            ReactGA.pageview(page)
        }
    }, [page])
    return null
}
import React, { useContext, createContext } from 'react'

// import { injectGlobal } from 'emotion'
// import { darken } from 'polished'

const ThemeContext = createContext()

function ThemeProvider({ children }) {

    // const { loading, error, data } = useQuery(GET_ORGANIZATION)

    // if (loading) return 'Loading...'
    // if (error) return 'Error loading theme'

    // const { organization } = data

    // injectGlobal`
    //     .theme__background--primary {
    //         background: ${ organization.styleConfig.theme.colorPrimary }
    //     }
    //     .theme__background--secondary {
    //         background: ${ organization.styleConfig.theme.colorSecondary }
    //     }
    //     .theme__color--primary {
    //         color: ${ organization.styleConfig.theme.colorPrimary }
    //     }
    //     .theme__color--secondary {
    //         color: ${ organization.styleConfig.theme.colorSecondary }
    //     }
    //     .theme__color--primaryDarken10 {
    //         color: ${ darken(10, organization.styleConfig.theme.colorPrimary) }
    //     }
    // `

    return (
        <ThemeContext.Provider
            value={{
                // ...(organization && organization.styleConfig)
                // ...organization.styleConfig,
                // name: organization.name,
                // logo: organization.logo.url.replace('https://encampment.nyc3.cdn.digitaloceanspaces.com/','http://cdn.tueri.io/68719476739/encampment/'),
                // tagline: organization.tagline,
            }}
        >
            { children }
        </ThemeContext.Provider>
    )        

}

function useTheme() {
    return useContext(ThemeContext)
}

export {
    useTheme
}

export default ThemeProvider
import gql from 'graphql-tag'

export const GET_CUSTOMER_VEHICLES = gql`
    query GetCustomerVehicles($customerId: ID!) {
        customerVehicles(customerId: $customerId) {
            id
            year
            make
            model
            vin
            images {
                location
            }
        }
    }
`

export const GET_VEHICLE_BY_ID = gql`
    query GetVehicleById($id: ID!) {
        vehicleById(id: $id) {
            id
            year
            make
            model
            vin
            images {
                location
            }
        }
    }
`

export const GET_VEHICLE_YEARS = gql`
    query GetVehicleYears {
        vehicleYears {
            minYear
            maxYear
        }
    }
`

export const GET_VEHICLE_MAKES = gql`
    query GetVehicleMakes($year: Int!) {
        vehicleMakes(year: $year)
    }
`

export const GET_VEHICLE_MODELS = gql`
    query GetVehicleModels($year: Int! $make: String!) {
        vehicleModels(year: $year make: $make)
    }
`

export const GET_VEHICLE_TRIMS = gql`
    query GetVehicleTrims($year: Int! $make: String! $model: String!) {
        vehicleTrims(year: $year make: $make model: $model)
    }
`

export const CREATE_VEHICLE = gql`
    mutation CreateVehicle($payload: CreateVehiclePayload!) {
        createVehicle(payload: $payload) {
            id
            year
            make
            model
            mileage
            mileageType
            vin
            images {
                location
            }
        }
    }    
`

export const SET_VEHICLE_IMAGE = gql`
    mutation SetVehicleImage($payload: SetVehicleImagePayload!) {
        setVehicleImage(payload: $payload) {
            id
            images {
                location
            }
        }
    }
`
import React from 'react'
import moment from 'moment'
import Blur from 'components/Blur'

export function FormatDate({ date, format }) {

    if (!date) return null

    return moment.unix(date.substr(0,10)).format(format || 'MMMM Do YYYY, h:mm a')

}

export function FormatPhone({ children }) {
    if (!children) return null
    const phone = children.replace('+1', '').replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
    if (process.env.REACT_APP_BLUR) return <Blur>{ phone }</Blur>
    return phone
}

export function FormatCurrency({ children }) {
    const formattedNumber = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(children || 0).replace(/[A-Z$]/g, "")
    return formattedNumber
}
import React, { useState } from 'react'

import './tooltip.scss'

export default function Tooltip({ children }) {

    const [ expanded, setExpanded ] = useState(false)

    return (
        <div className='tooltip__container'>
            <button type='button' className='tooltip__button' onClick={() => setExpanded(!expanded) }><div className='tooltip__icon'>i</div></button>
            <div className={`tooltip__expander ${ expanded ? 'tooltip__expander--expanded' : '' }`}>{ children }</div>
        </div>
    )

}
import React, { createContext, useContext, useState } from 'react'
import jwtDecode from 'jwt-decode'
import { useQuery } from '@apollo/react-hooks'
import { CHECK_DEVICE_STATUS } from 'operations/Device'
import { useEffect } from 'react'

const DeviceContext = createContext()

export default function Modal({ children }) {

    const [device, setDevice] = useState(() => {
        const deviceToken = localStorage.getItem('deviceToken')
        if (deviceToken) return deviceToken
        return null
    })

    const { data } = useQuery(CHECK_DEVICE_STATUS, { variables: { device }, pollInterval: 60000, skip: !device })

    useEffect(() => {

        if (data && (!data.checkDeviceStatus || !data.checkDeviceStatus.isActive)) {
            localStorage.removeItem('deviceToken')
            localStorage.removeItem('deviceId')
            setDevice(null)
        }

    }, [data])

    return (
        <DeviceContext.Provider
            value={{
                device: device ? jwtDecode(device) : null,
                setDevice: (device) => setDevice(device)
            }}
        >
            { children }
        </DeviceContext.Provider>
    )

}

export const useDevice = () => useContext(DeviceContext)
import { useTranslation } from 'react-i18next'

export default function() {

    const { t } = useTranslation()

    const vehicle = ''

    const MESSAGES = {
        // ARRIVAL_PICKUP: `Your ${ vehicleString } is booked for: ${ appointmentStartAt }.\nWe'll let you know when we are on our way.`,
        ARRIVAL_PICKUP_COMPLETE: t(`We are on our way to pickup your {{ vehicle }}.`, { vehicle }),
        // ARRIVAL_DROP: `Your ${ vehicleString } is booked for: ${ appointmentStartAt }.\nPlease drop off your ${ vehicleString } 15 minutes prior.`,
        STANDARD_SERVICE: t(`We have started working on your {{ vehicle }}.`, { vehicle }),
        DIAGNOSIS: t(`We have started diagnosing your {{ vehicle }}.`, { vehicle }),
        ESTIMATE_SENT: t(`We have identified the issue with your {{ vehicle }}. View your estimate at {{ url }}`, { vehicle, url: '' }),
        WORK_STARTED: t(`We have started working on your {{ vehicle }}.`, { vehicle }),
        INVOICE_SENT: t(`We are all finished working on your {{ vehicle }}. Please view your invoice at {{ url }}`, { vehicle, url: '' }),
        DEPARTURE_PICKUP: t(`Your {{ vehicle }} is ready for pickup!`, { vehicle }),
        DEPARTURE_DROP_COMPLETE: t(`Your {{ vehicle }} is all finished. We are on our way to drop it off.`, { vehicle }),
    }

    console.log(MESSAGES)

    return null

}
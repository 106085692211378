import React, { useRef, createContext, useContext, useState } from 'react'
import shortid from 'shortid'

import { Link } from 'react-router-dom'

import './accordion.scss'

const AccordionContext = createContext()

export default function Accordion({ children }) {

    const [ items, setItems ] = useState({})

    function handleToggleItem(key) {
        setItems(items => {
            
            const newItems = items
            Object.keys(items).map(item => {
                if (item !== key) return newItems[item] = false
                return null
            })

            return {
                ...newItems,
                [key]: !items[key]
            }
        })
    }

    return (
        <AccordionContext.Provider
            value={{
                items,
                toggleItem: handleToggleItem
            }}
        >
            <div className='accordionContainer'>
                { children }
            </div>
        </AccordionContext.Provider>
    )

}

const useAccordion = () => useContext(AccordionContext)

export function AccordionItem({ title, complete, children }) {
    const { items, toggleItem } = useAccordion()

    const { current: item } = useRef(shortid.generate())

    if (complete) {
        return (
            <div className='accordionItem'>
                <div className='accordionItem__title accordionItem__title--complete'><i className='material-icons'>check_circle</i>{ title }</div>
            </div>
        )
    }

    return (
        <div className={`accordionItem ${ items[item] ? 'accordionItem--active' : '' }`}>
            <div onClick={() => toggleItem(item)} className={`accordionItem__title ${ items[item] ? 'accordionItem__title--active' : '' }`}><i className='material-icons'>keyboard_arrow_right</i>{ title }</div>
            {
                items[item] && <div className='accordionItem__text'>{ children }</div>
            }
        </div>
    )
}

export function AccordionButton({ children, to, onClick, loading, disabled }) {

    const El = onClick ? 'button' : Link

    const props = {}
    if (to) props.to = to
    if (onClick) props.onClick = (e) => onClick && onClick(e)

    return (
        <div>
            <El 
                className={`accordionButton ${ loading ? 'accordionButton--loading' : '' }`} 
                { ...props }
                disabled={ disabled }
            >
                { children }
                { loading && <div className='formLoader'/> }
            </El>
        </div>
    )
}
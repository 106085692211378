import React, { useState, useEffect } from 'react'
import './header.scss'
import './hamburger.scss'

import Button from 'components/Button'
import { useLocation } from 'react-router-dom'

export default function Header({ children }) {

    const [ expanded, setExpanded ] = useState()

    const location = useLocation()

    useEffect(() => {
        setExpanded()
    }, [location])

    return (
        <header className={`header theme__background--primary ${ expanded ? 'header--expanded' : '' }`}>
            <div className='fixed'>
                <Button className={`hamburgerMenu ${ expanded ? 'hamburgerMenu--active' : '' }`} onClick={() => setExpanded(expanded => !expanded)}>
                    <span className='hamburgerBox'>
                        <span className='hamburgerInner'></span>
                    </span>
                </Button>
                <nav className={`menu ${ expanded ? 'menu--expanded' : '' }`}>{ children }</nav>
            </div>
        </header>
    )
}
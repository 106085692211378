import React from 'react'

import { PrimaryButton } from 'components/Button'

import { useTranslation } from 'react-i18next'

export default function Verified({ verified }) {

    const { t } = useTranslation()

    return (
        <div className='appointment__container'>
            <div className='appointment__bodyContainer'>

                    <div>
                        <h1>{ verified ? t('emailVerificationSuccess', 'Thanks for confirming your email') : t('emailVerificationFailed', 'Sorry, we were unable to verify your email') }</h1>

                        <PrimaryButton to='/'>{ t('continue', 'Continue') }</PrimaryButton>

                    </div>

            </div>
        </div>
    )
}
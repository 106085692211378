import React from 'react'
import { useSubscription } from 'hooks/subscription'
import { useLocation } from 'react-router-dom'
import './subscriptionNotice.scss'
import { useAuthentication } from 'hooks/authentication'
import { PrimaryButton } from 'components/Button'
import { useTranslation } from 'react-i18next'

export default function SubscriptionNotice() {
    
    const { t } = useTranslation()

    const { subscriptionStatus } = useSubscription()
    const location = useLocation()
    const { user } = useAuthentication()

    const MESSAGES = {
        TRIAL_EXPIRED: {
            heading: t('trialExpiredHeading', 'Your trial period has expired.'),
            installer: t('trialExpiredInstallerMessage', 'Please add your billing information to continue.'),
            otherUser: t('trialExpiredOtherMessage', 'Please ask your administrator to upgrade your shop account.'),
            buttonText: t('trialExpiredButton', 'Add my billing information')
        },
        PAST_DUE: {
            heading: t('pastDueHeading', 'Your latest invoice is past due.'),
            installer: t('pastDueInstallerMessage', 'Please update your billing information to continue.'),
            otherUser: t('pastDueOtherMessage', 'Please ask your administrator to update the billing information on your shop account.'),
            buttonText: t('pastDueButton', 'Update billing information')
        },
        CANCELLED: {
            heading: t('cancelledHeading', 'Your subscription has been cancelled.'),
            installer: t('cancelledInstallerMessage', 'Please upgrade your subscription to continue.'),
            otherUser: t('cancelledOtherMessage', 'Please ask your administrator to upgrade your shop subscription.'),
            buttonText: t('cancelledButton', 'Upgrade my subscription')
        }
    }

    if (location.pathname === '/settings/billing') return null

    if (!['TRIAL_EXPIRED', 'PAST_DUE', 'CANCELLED',].includes(subscriptionStatus)) return null

    return (
        <div className='subscriptionNoticeWrapper'>
            <div className='subscriptionNoticeContainer'>
                <h1 style={{ color: 'red' }}>{ MESSAGES[subscriptionStatus].heading }</h1>
                {
                    user.typ === 'INSTALLER' ? (
                       <h2>{ MESSAGES[subscriptionStatus].installer }</h2>
                    ) : (
                        <h2>{ MESSAGES[subscriptionStatus].otherUser }</h2>
                    )
                }
                {
                    user.typ === 'INSTALLER' && <PrimaryButton to='/settings/billing'>{ MESSAGES[subscriptionStatus].buttonText }</PrimaryButton>
                }
            </div>
        </div>
    )
}
import gql from 'graphql-tag'

export const GET_UNREAD_MESSAGES = gql`
    query UnreadMessages {
        unreadMessages
    }
`

export const GET_CHAT_MESSAGES = gql`
    query GetChatMessages {
        installer {
            id
            messagingNumber
        }
        chat {
            customer {
                id
                fullName
                user {
                    id
                    mobileNumber
                }
            }
            message {
                id
                isInbound
                body
                isRead
                isAutomated
            }
        }
    }
`

export const GET_MESSAGES = gql`
    query GetMessages($filter: MessageFilter!) {
        messages(filter: $filter) {
            id
            customerId
            senderId
            messageStatus
            to
            from
            body
            isRead
            isAutomated
            media {
                url
                contentType
            }
        }
    }
`

export const SEND_MESSAGE = gql`
    mutation SendMessage($payload: SendMessagePayload!) {
        sendMessage(payload: $payload) {
            id
            customerId
            senderId
            messageStatus
            to
            from
            body
            isRead
            isAutomated
            media {
                url
                contentType
            }
        }
    }
`

export const ON_NEW_MESSAGE = gql`
    subscription OnNewMessage($filter: OnNewMessageFilter) {
        newMessage(filter: $filter) {
            id
            customerId
            messageStatus
            to
            from
            body
            senderId
            isRead
            isAutomated
            media {
                url
                contentType
            }
        }
    }
`
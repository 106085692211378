import React, { useState, useEffect } from 'react'

import { useQuery } from '@apollo/react-hooks'
import { GET_CUSTOMERS_BY_PHONE } from 'operations/Customer'

import Form, { Field, Fieldset, Submit } from 'components/Form'
import { FormatPhone } from 'components/Format'

import { useTranslation } from 'react-i18next'

/**
 * 1. Enter phone number -> click next
 * 2a. Found customer, select/add vehicle
 * 2b. No customer found, enter name
 */


export default function CustomerLookup({ onChange }) {

    const { t } = useTranslation()

    const [ customer, setCustomer ] = useState()
    const [ errors, setErrors ] = useState({})

    return (
        <Form
            onSubmit={({ enableSubmit}) => {

                const formErrors = {}
                if (!customer) formErrors.mobileNumber = true

                if (Object.keys(formErrors).length > 0) {
                    enableSubmit()
                    return setErrors(formErrors)
                }

                onChange && onChange(customer)
                enableSubmit()
            }}
        >
            {({ rawInputs }) => (
                <div className='modalForm'>
                    <Fieldset legend={ t('findYourCustomer', 'Find your customer') }>
                        <Field name='mobileNumber' type='tel' label={ t('mobilePhoneNumber', 'Mobile Phone Number') } autoComplete='off' maxLength={10} helper={ t('mobilePhoneHelperText', '(321) 456-7890') } required error={ errors.mobileNumber } errorMessage={ t('customerMobilePhoneError', `Please enter your customer's mobile phone number.`) } />
                        { rawInputs.mobileNumber && rawInputs.mobileNumber.length === 10 && <CustomerAutocomplete mobileNumber={ rawInputs.mobileNumber } onChange={ (customer) => setCustomer(customer) } disabled={ !customer } /> }
                    </Fieldset>
                </div>
            )}
        </Form>
    )

}

function CustomerAutocomplete({ mobileNumber, onChange, disabled }) {

    // TODO: fix memory leak

    const { error, loading, data } = useQuery(GET_CUSTOMERS_BY_PHONE, { variables: { mobileNumber }, fetchPolicy: 'network-only' })

    useEffect(() => {
        if (data) {
            onChange(data.customersByPhone.length < 1 ? mobileNumber : data.customersByPhone[0])
        }
        // eslint-disable-next-line
    }, [data])
    
    if (error || loading) return <div style={{ position:'absolute' }}>Loading...</div>

    if (!data.customersByPhone || data.customersByPhone.length < 1) {
        return <Submit disabled={ disabled }>New Customer</Submit>
    }

    const customer = data.customersByPhone[0]

    return (
        <Submit disabled={ disabled } fullWidth>{ customer.fullName } - <span className='autoComplete__mobileNumber'><FormatPhone>{ customer.user.mobileNumber }</FormatPhone></span>
        </Submit>
    )
}
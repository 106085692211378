import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import GAListener from './GAListener'

import Layout from 'components/Layout'
import Modal from 'components/Modal'

import Refresh from 'components/Refresh'

import ActiveInstallerCheck from 'components/ActiveInstallerCheck'

import Dashboard from 'pages/Dashboard'
import Login from 'pages/Login'
import DeviceLogin from 'pages/Login/DeviceLogin'
import SignUp from 'pages/SignUp'
import LinkDevice from 'pages/LinkDevice'

import Board from 'pages/Board'
import Appointment from 'pages/Appointment'
import Onboarding from 'pages/Onboarding'

import Customers from 'pages/Customers'
import Customer from 'pages/Customer'
import CustomerProfile from 'pages/Customer/Profile'

import Chat from 'pages/Chat'

import Settings from 'pages/Settings'
import Payments from 'pages/Settings/Payments'
import Billing from 'pages/Settings/Billing'
import HourlyRate from 'pages/Settings/HourlyRate'
import SalesTaxes from 'pages/Settings/SalesTaxes'
import HoursOfOperation from 'pages/Settings/HoursOfOperation'
import BusinessProfile from 'pages/Settings/BusinessProfile'
import Locale from 'pages/Settings/Locale'
import Devices from 'pages/Settings/Devices'
import Device from 'pages/Settings/Devices/Device'
import TeamMembers from 'pages/Settings/TeamMembers'
import AddTeamMember from 'pages/Settings/TeamMembers/AddTeamMember'

import Account from 'pages/Account'
import AccountVerified from 'pages/Account/Verified'

import ReleaseNotes from 'pages/Release'

import SubscriptionNotice from 'components/SubscriptionNotice'

import { useAuthentication } from 'hooks/authentication'
import BoardProvider from 'hooks/board'
import { useDevice } from 'hooks/device'

// import Tour from 'Tour'

export default function Routes() {

    const { isAuthenticated } = useAuthentication()
    const { device } = useDevice()

    if (!isAuthenticated) return (
        <Router>
            <Switch>
                <Route exact path='/' component={ device ? DeviceLogin : Login } />
                { device && <Route path='/log-in' component={ Login } /> }
                <Route path='/sign-up' component={ SignUp } />
                <Route path='/link' component={ LinkDevice } />
                <Route path='/account/verified' render={ () => <Modal fullscreen><AccountVerified verified /></Modal> } />
                <Route path='/account/unverified' render={ () => <Modal fullscreen><AccountVerified /></Modal> } />
                {/* IF LOGGED OUT, replace */}
                <Route render={({ history }) => history.replace('/')} />
            </Switch>
            <Route component={ Refresh } />
            <Route component={ GAListener } />
        </Router>
    )

    return (
        <Router>
            <Route component={ SubscriptionNotice } />
            <Layout>
                <Switch>
                    <Route exact path='/' component={ Dashboard } />
                    <Route path='/onboarding/:state?' render={ () => <Modal fullscreen><Onboarding /></Modal> } />
                    <Route path='/schedule/:view?' render={() => <ActiveInstallerCheck><BoardProvider><Board/></BoardProvider></ActiveInstallerCheck>} />
                    <Route path='/appointment/customer/:customerId' render={ () => <Modal fullscreen><Appointment /></Modal> } />
                    <Route path='/appointment/:appointmentId?' render={ () => <Modal fullscreen><Appointment /></Modal> } />

                    <Route exact path='/customers' render={ () => <ActiveInstallerCheck><Customers/></ActiveInstallerCheck> } />
                    <Route path='/customers/new-customer' render={ () => <Modal fullscreen><Customer newCustomer/></Modal> } />
                    <Route path='/customers/:customerId/profile' component={ CustomerProfile } />
                    <Route path='/customers/:customerId' component={ Customer } />

                    <Route path='/chat/:customerId?' component={ Chat } />

                    <Route exact path='/settings' component={ Settings } />
                    <Route path='/settings/payments' component={ Payments } />
                    <Route path='/settings/hourly-rate' component={ HourlyRate } />
                    <Route path='/settings/sales-taxes' component={ SalesTaxes } />
                    <Route path='/settings/hours-of-operation' component={ HoursOfOperation } />
                    <Route path='/settings/business-profile' component={ BusinessProfile } />
                    <Route path='/settings/locale' component={ Locale } />
                    <Route exact path='/settings/devices' component={ Devices } />
                    <Route path='/settings/devices/:deviceId' render={ () => <Modal fullscreen><Device /></Modal> } />
                    <Route exact path='/settings/team-members' component={ TeamMembers } />
                    <Route path='/settings/team-members/:userId' render={ () => <Modal fullscreen><AddTeamMember /></Modal> } />
                    <Route path='/settings/billing' component={ Billing } />

                    <Route exact path='/account' component={ Account } />
                    <Route path='/account/verified' render={ () => <Modal fullscreen><AccountVerified verified /></Modal> } />
                    <Route path='/account/unverified' render={ () => <Modal fullscreen><AccountVerified /></Modal> } />

                    <Route path='/release-notes' component={ ReleaseNotes } />
                </Switch>
            </Layout>
            <Route component={ Refresh } />
            <Route component={ GAListener } />
            {/* <Tour /> */}
        </Router>
    )

}
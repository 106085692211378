import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import {
    EDIT_APPOINTMENT_DESCRIPTION,
    // ADD_APPOINTMENT_ISSUE,
    // EDIT_APPOINTMENT_ISSUE,
    ADD_APPOINTMENT_COMMENT,
    EDIT_APPOINTMENT_COMMENT,
    // GET_APPOINTMENT_BY_ID
} from 'operations/Appointment'

import TechnicianAssignment from 'components/AssignTo'

import {
    // FormatDate,
    // FormatPhone,
    FormatCurrency
} from 'components/Format'

import ContentBox from 'components/ContentBox'
import Tooltip from 'components/Tooltip'
// import { Img } from 'components/Tueri'
import HR from 'components/HR'
import { NewEstimate, EstimateApproval, NewIssue, NewInvoice, PayInvoice } from './Actions'

import Estimate from 'components/Estimate'
import Issue from 'components/Issue'
// import Loader from 'components/Loader'

import { useTranslation } from 'react-i18next'

// import Modal from 'components/Modal'
// import { useModal } from 'hooks/modal'

export default function Customer({ appointment }) {

    const { t } = useTranslation()

    // const { modal, setModal } = useModal()
    const [ step, setStep ] = useState()

    // const { error, loading, data } = useQuery(GET_APPOINTMENT_BY_ID, { variables: { id: appointmentData.id } })
    const [ editAppointmentDescription ] = useMutation(EDIT_APPOINTMENT_DESCRIPTION)
    // const [ addAppointmentIssue ] = useMutation(ADD_APPOINTMENT_ISSUE)
    // const [ editAppointmentIssue ] = useMutation(EDIT_APPOINTMENT_ISSUE)
    const [ addAppointmentComment ] = useMutation(ADD_APPOINTMENT_COMMENT)
    const [ editAppointmentComment ] = useMutation(EDIT_APPOINTMENT_COMMENT)


    // if (error || loading) return <Loader />

    // const { appointment } = data

    // let newIssuekey = 0 + (appointment.issues ? appointment.issues.length : 0)
    let newCommentKey = 0 + (appointment.comments ? appointment.comments.length : 0)

    return (
        <>           

                {
                    step ? (
                        <div
                            style={{
                                textAlign: 'center',
                                padding: '4rem',
                                flex: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <div style={{ minWidth: '480px' }}>
                                <AppointmentAction type={ step } appointment={ appointment } onComplete={() => setStep()} />
                            </div>
                        </div>
                    ) : (
                        <div className='customer'>
                        <div className='customerJobContainer'>
                            {/* <h1><FormatDate date={ appointment.appointmentStartAt } /></h1> */}

                            { appointment.invoice && appointment.invoice.total && <div className='appointment__balance'>{ t('amountOwing', 'Amount owing:') } $<FormatCurrency>{ (appointment.invoice.total - appointment.invoice.totalPaid) }</FormatCurrency></div> }

                            <h2>{ t('Description') } <Tooltip>{ t('descriptionToolTip', 'The appointment description is visible to your customer {{ fullName }}.', { fullName: appointment.customer.fullName }) }</Tooltip></h2>

                            <ContentBox buttonText={ t('addADescriptionButton', 'Add a description') } meta={{ createdAt: appointment.descriptionCreatedAt, updatedAt: appointment.descriptionUpdatedAt }} emptyEditText={ t('emptyDescriptionPlaceholder', 'Enter a description...') } onSave={(state) => editAppointmentDescription({ variables: { payload: { appointmentId: appointment.id, description: state, create: appointment.description ? false : true } } })}>{ appointment.description }</ContentBox>

                            <HR/>
                            <TechnicianAssignment appointmentId={ appointment.id } />

                            {
                                !['APPOINTMENT_REQUEST', 'ARRIVAL_PICKUP', 'ARRIVAL_PICKUP_COMPLETE', 'ARRIVAL_DROP', 'ARRIVAL_DROP_COMPLETE'].includes(appointment.status) && (
                                    <>
                                    <HR/>
                                    <h2>{ t('issues', 'Issues') }</h2>
                                    {
                                        appointment.issues && appointment.issues.map(issue => <Issue key={ issue.id } issue={ issue } />)
                                    }

                                    {/* <ContentBox key={ 'issue--' + newIssuekey } buttonText='Add an issue' clearOnSave emptyEditText='Enter an issue...' onSave={(issue) => addAppointmentIssue({ variables: { payload: { appointmentId: appointment.id, issue } } })}></ContentBox> */}

                                    { ['DIAGNOSIS', 'STANDARD_SERVICE', 'WORK_STARTED'].includes(appointment.status) && <button className='appointmentButton' type='button' onClick={() => setStep('ISSUE')}>{ t('addAnIssue', 'Add an issue') }</button> }

                                    </>
                                )
                            }

                            {
                                (['IDENTIFIED'].includes(appointment.status) || (appointment.estimates && appointment.estimates.length > 0)) && (
                                    <>
                                    <HR/>
                                    <h2>{ t('estimates', 'Estimates') }</h2>
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', gridGap: '2rem' }}>
                                    {
                                        appointment.estimates && appointment.estimates.map((estimate) => <Estimate key={ estimate.id } estimate={ estimate } />) 
                                    }
                                    </div>

                                    { ['IDENTIFIED'].includes(appointment.status) && <button className='appointmentButton' type='button' onClick={() => setStep('ESTIMATE')}>{ t('addAnEstimate', 'Add an estimate') }</button> }

                                    { ['ESTIMATE_SENT'].includes(appointment.status) && <p>{ t('waitingForCustomerApproval', 'Waiting for {{ fullName }} to approve the estimate.', { fullName: appointment.customer.fullName }) }<br/><button className='appointmentButton' type='button' onClick={() => setStep('ESTIMATE_APPROVAL')}>{ t('estimateApprovalOverrideButton', 'Approve estimate on behalf of customer') }</button></p> }

                                    {/* <ContentBox key={ 'issue--' + newIssuekey } buttonText='Add an issue' clearOnSave emptyEditText='Enter an issue...' onSave={(issue) => addAppointmentIssue({ variables: { payload: { appointmentId: appointment.id, issue } } })}></ContentBox> */}

                                    </>
                                )
                            }

                            {
                                (['WORK_STARTED', 'STANDARD_SERVICE'].includes(appointment.status) || appointment.invoice) && (
                                    <>
                                    <HR/>
                                    <h2>{ t('invoice', 'Invoice') }</h2>
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', gridGap: '2rem' }}>
                                    {
                                        appointment.invoice && <Estimate key={ appointment.invoice.id } estimate={ appointment.invoice } />
                                    }
                                    </div>

                                    { ['WORK_STARTED', 'STANDARD_SERVICE'].includes(appointment.status) && !appointment.invoice && <button className='appointmentButton' type='button' onClick={() => setStep('INVOICE')}>{ t('addAnInvoice', 'Add an invoice') }</button> }

                                    { ['INVOICE_SENT'].includes(appointment.status) && <button className='appointmentButton' type='button' onClick={() => setStep('PAY_INVOICE')}>{ t('addAPayment', 'Add a payment') }</button> }

                                    {/* <ContentBox key={ 'issue--' + newIssuekey } buttonText='Add an issue' clearOnSave emptyEditText='Enter an issue...' onSave={(issue) => addAppointmentIssue({ variables: { payload: { appointmentId: appointment.id, issue } } })}></ContentBox> */}

                                    </>
                                )
                            }

                            <HR/>

                            <h2>{ t('comments', 'Comments') } <Tooltip>{ t('commentsTooltip', 'Comments are for internal use and are not visible to your customer {{ fullName }}.', { fullName: appointment.customer.fullName }) }</Tooltip></h2>

                            {
                                appointment.comments && appointment.comments.map(comment => <ContentBox key={ comment.id } meta={ comment } buttonText={ t('addAComment', 'Add a comment') } emptyEditText={ t('commentPlaceholder', 'Enter a comment...' ) } onSave={(state) => editAppointmentComment({ variables: { payload: { commentId: comment.id, comment: state } } })}>{ comment.comment }</ContentBox>)
                            }

                            <ContentBox key={ 'comment--' + newCommentKey } buttonText={ t('addAComment', 'Add a comment') } clearOnSave emptyEditText={ t('commentPlaceholder', 'Enter a comment...' ) } onSave={(comment) => addAppointmentComment({ variables: { payload: { appointmentId: appointment.id, comment } } })}></ContentBox>
                        </div>
                        {/* <Actions appointment={ appointment } /> */}
                        </div>
                    )
                }

            

            {
                // modal && <Modal><AppointmentAction type={ modal } appointment={ appointment } /></Modal>
            }

        </>
    )

}

function AppointmentAction({ type, appointment, onComplete }) {

    if (type === 'ISSUE') return <NewIssue appointmentId={ appointment.id } onComplete={() => onComplete && onComplete()} />
    
    if (type === 'ESTIMATE') return <NewEstimate appointmentId={ appointment.id } onComplete={() => onComplete && onComplete()} />

    if (type === 'ESTIMATE_APPROVAL') return <EstimateApproval appointmentId={ appointment.id } onComplete={() => onComplete && onComplete()} />

    if (type === 'INVOICE') return <NewInvoice appointmentId={ appointment.id } onComplete={() => onComplete && onComplete()} />

    if (type === 'PAY_INVOICE') return <PayInvoice appointmentId={ appointment.id } onComplete={() => onComplete && onComplete()} />

}
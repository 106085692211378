import React from 'react'
import './card.scss'
import { Link } from 'react-router-dom'

import { Img } from 'components/Tueri'

import moment from 'moment'
import Initials from 'components/Initials'

export default function Card({ children, type, className, style, to, onClick }) {

    const Element = to ? Link : 'div'

    return (
        <Element className={`card ${ type ? 'card--' + type : '' } ${ className ? className : '' } ${ onClick ? 'card--button' : '' }`} to={ to } onClick={ (e) => onClick && onClick(e) } style={ style }>
            { children }
        </Element>
    )

}

export function CardImage({ children }) {

    return (
        <div className='card__image'>
            { children || <i className='material-icons'>directions_car</i> }
        </div>
    )

}

export function CardHeader({ children }) {
    return (
        <div className='card__header'>
            { children }
        </div>
    )
}

export function CardText({ children }) {
    return (
        <div className='card__text'>
            { children }
        </div>
    )
}

export function VehicleCard({ vehicle, ...rest}) {
    return (
        <Card type='vehicle' { ...rest }>
            <CardImage>{ vehicle.images && <Img src={ vehicle.images[0].location } alt={`${ vehicle.year } ${ vehicle.make } ${ vehicle.model }`} /> }</CardImage>
            <CardText>{ vehicle.year } { vehicle.make } { vehicle.model }</CardText>
        </Card>
    )
}

export function SettingsCard({ title, children, ...rest }) {

    return (
        <Card type='settings' { ...rest }>
            <div className='cardSettings__title'>{ title }</div>
            { children }
        </Card>
    )
}

export function CardTitle({ title, children, style }) {
    return (
        <div className='cardTitle' style={ style }>
            <div className='title'>{ title }</div>
            { children }
        </div>
    )
}

export function CardEditButton({ onClick }) {
    return (
        <button type='button' className='cardEditButton' onClick={onClick}><i className='material-icons'>create</i></button>
    )
}

export function CardIcon({ icon, children }) {
    return (
        <div className='cardIcon'>
            <i className='material-icons'>{ icon || children || 'help' }</i>
        </div>
    )
}

export function CardUserIcon({ name }) {
    return (
        <div className='cardUserIcon'>
            <Initials name={ name } />
        </div>
    )
}

export function CardDate({ date, tense }) {

    return (
        <div className={`cardDate cardDate--${ tense }`}>
            <div>
                { 
                    date ? (
                        <>{ moment(Number(date)).format('MMM') }<div className='day'>{ moment(Number(date)).format('D') }</div></>
                    ) : (
                        'N/A'
                    )
                }
                
            </div>
        </div>
    )

}
import React from 'react'

import { useAuthentication } from 'hooks/authentication'

import { SettingsCard } from 'components/Card'

import './settings.scss'

import { useTranslation } from 'react-i18next'

export default function Settings() {

    const { t } = useTranslation()

    const { user } = useAuthentication()

    return (
        <div className='bodyContainer'>

            <h1>Settings</h1>

            {
                ['INSTALLER', 'SERVICE_ADVISOR'].includes(user.typ) && (
                    <>
                        <h2>{ t('payments', 'Payments') }</h2>
                            <div className='settingsGrid'>
                                {
                                    user.typ === 'INSTALLER' && (
                                        <SettingsCard title={ t('payments', 'Payments') } to='/settings/payments'>
                                            { t('acceptPaymentsMessage', 'Accept payments from your customers.') }
                                        </SettingsCard>
                                    )
                                }

                                <SettingsCard title={ t('salesTaxes', 'Sales taxes') } to='/settings/sales-taxes'>
                                    { t('salesTaxMessage', 'Update your your sales taxes charged on customer invoices.') }
                                </SettingsCard>

                                <SettingsCard title={ t('hourlyRate', 'Hourly rate') } to='/settings/hourly-rate'>
                                    { t('hourlyRateMessage', 'Set your your hourly rate charged on customer invoices.') }
                                </SettingsCard>
                            </div>
                    </>
                )
            }

            {
                ['INSTALLER', 'SERVICE_ADVISOR'].includes(user.typ) && (
                    <>
                        <h2>{ t('yourBusiness', 'Your business' )}</h2>
                        <div  className='settingsGrid'>
                            <SettingsCard title={ t('businessProfile', 'Business profile') } to='/settings/business-profile'>
                                { t('businessInfoMessage', 'Update your business name, address, and other public contact details.') }
                            </SettingsCard>

                            <SettingsCard title={ t('locale', 'Locale') } to='/settings/locale'>
                                { t('localeMessage', 'Update your business language and timezone settings.') }
                            </SettingsCard>

                            <SettingsCard title={ t('hoursOfOperation', 'Hours of operation') } to='/settings/hours-of-operation'>
                                { t('hoursOfOperationMessage', 'Let people know when you are open for business.') }
                            </SettingsCard>
                        </div> 
                    </>
                )
            }

            {
                ['INSTALLER', 'SERVICE_ADVISOR'].includes(user.typ) && (
                    <>

                        <h2>{ t('teamAndSecurity', 'Team and security') }</h2>
                        <div className='settingsGrid'>
                            {
                                user.typ === 'INSTALLER' && (
                                    <>
                                        <SettingsCard title={ t('teamMembers', 'Team members') } to='/settings/team-members'>
                                            { t('teamMembersMessage', 'Add or remove team members from your account.') }
                                        </SettingsCard>
                                        
                                        <SettingsCard title={ t('devices', 'Devices') } to='/settings/devices'>
                                            { t('devicesMessage', 'Manage devices your team members can log in from.' )}
                                        </SettingsCard>
                                    </>
                                )
                            }
                            
                            <SettingsCard title={ t('securityHistory', 'Security history') }>
                                { t('securityHistoryMessage', 'Review login history and changes to your account.') }
                            </SettingsCard>
                        </div>

                    </>
                )
            }

            {
                ['INSTALLER'].includes(user.typ) && (
                    <>
                        <h2>{ t('billing', 'Billing') }</h2>
                        <div className='settingsGrid'>
                            {/* <form method="POST" action={`${ process.env.REACT_APP_API_URL }/stripe/create-customer-portal-session`}>
                                <button type="submit">
                                    Manage billing
                                </button>
                            </form> */}
                            <SettingsCard title={ t('billing', 'Billing') } to='/settings/billing'>
                                { t('billingMessage', 'Manage your billing settings and payment history.') }
                            </SettingsCard>
                        </div>
                    </>
                )
            }    

        </div>
    )
}
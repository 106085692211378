import React, { useState, useRef } from 'react'

import { GET_APPOINTMENT_ASSIGNMENTS, ASSIGN_TECHNICIAN } from 'operations/Appointment'
import Initials from 'components/Initials'
import Loader from 'components/Loader'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useTranslation, Trans } from 'react-i18next'

import './assignTo.scss'
import { useAssignments } from 'hooks/assignments'

import Fuse from 'fuse.js'

export default function TechnicianAssignment({appointmentId}) {

    const { t } = useTranslation()

    const { loading, error, data } = useQuery(GET_APPOINTMENT_ASSIGNMENTS, { variables: { appointmentId } })

    return (
        <div>

            <h2>{ t('Technicians') }</h2>

            <TechnicianDropdown assignments={ data } appointmentId={ appointmentId } />

            {
                loading && <Loader />
            }
            {
                error && 'Unable to get assignments :('
            }
            {
                !loading && data && data.appointmentAssignments && data.appointmentAssignments.map(assignment => {
                    return <div className='assignedToLabel' key={assignment.id}>{ assignment.firstName } { assignment.lastName }</div>   
                })
            }
            
        </div>
    )
}

function filterResults(value, options) {

    const newOptions = []
    options.map(option => newOptions.push({
        ...option,
        fullName: option.firstName + ' ' + option.lastName
    }))

    const fuse = new Fuse(newOptions, {
        keys: ['fullName'],
        threshold: 0.3
    })

    if (!value.length) return options

    return fuse.search(value)

}

function TechnicianDropdown({ assignments, appointmentId }) {

    const { t } = useTranslation()

    const [ value, setValue ] = useState('')
    const [ focus, setFocus ] = useState(false)

    const { teamMembers } = useAssignments()

    const [ assign ] = useMutation(ASSIGN_TECHNICIAN, { refetchQueries: [{ query: GET_APPOINTMENT_ASSIGNMENTS, variables: { appointmentId } }] })

    const inputRef = useRef(null)
    const wrapperRef = useRef(null)
    const assignmentIds = assignments && assignments.appointmentAssignments ? assignments.appointmentAssignments.map(assignment => assignment.id) : []

    function handleBlur(e) {
        const currentTarget = wrapperRef.current
        setTimeout(() => {
            if (!currentTarget.contains(document.activeElement)) {
                setFocus(false)
            }
        }, 0)
    }

    const technicians = filterResults(value, teamMembers)

    return (
        <div className={`technicianAssignmentContainer ${ value ? 'technicianAssignmentContainer--filled' : '' }`}
            ref={ wrapperRef }
            onBlur={(e) => handleBlur(e)}
        >
            <label htmlFor='assignment'>{ t('Assign to...') }</label>
            <input id='assignment' ref={ inputRef } onChange={ (e) => setValue(e.target.value) } value={ value } onFocus={ () => setFocus(true)  }
            //  onBlur={ () => setFocus(false) } 
                onBlur={(e) => handleBlur(e)}
             />
            {
                focus && (
                    <ul
                        onBlur={(e) => handleBlur(e)}
                    >
                        {
                            technicians.map(data => {

                                const teamMember = data.item ? data.item : data

                             return <li key={ teamMember.id }><button type='button' className={`${ assignmentIds.includes(teamMember.id) ? 'assigned' : '' }`} onClick={() => assign({variables: { payload: { appointmentId, technicianId: teamMember.id, assign: !assignmentIds.includes(teamMember.id) } }})}><i className='material-icons'>done</i><Initials name={`${ teamMember.firstName } ${ teamMember.lastName }`} />{ teamMember.firstName } { teamMember.lastName }</button></li>
                            })
                        }
                        {
                            teamMembers.length > 0 && technicians.length < 1 && <li style={{ padding: '1rem 1.5rem' }}>{ t('No technicians found.') }</li>
                        }
                        {
                            teamMembers.length < 1 && <li style={{ padding: '1rem 1.5rem' }}><Trans i18nKey='noTechniciansLinked'>Looks like you don't have any technicians linked to your account. Go to <strong>Settings > Team members</strong> to add technicians.</Trans></li>
                        }
                    </ul>
                )
            }
        </div>
    )

}
import gql from 'graphql-tag'

export const GET_APPOINTMENT_BY_ID = gql`
    query GetAppointmentById($id: ID!) {
        appointment: appointmentById(id: $id) {
            id
            appointmentStartAt
            appointmentEndAt
            description
            descriptionCreatedAt
            descriptionUpdatedAt
            status
            isPaid
            mileage
            mileageType
            workStartAt
            workEndAt
            pickupAndDrop
            createdAt
            updatedAt
            confirmationStatus
            customer {
                id
                fullName
                phone
                addresses {
                    id
                    address
                    addressLine2
                    city
                    province
                    postal
                    country
                }
            }
            vehicle {
                id
                year
                make
                model
                images {
                    location
                }
            }
            comments {
                id
                userId
                comment
                createdAt
                updatedAt
            }
            issues {
                id
                userId
                issue
                createdAt
                updatedAt
                files
            }
            estimates {
                id
                description
                files
                currency
                subtotal
                taxCode
                taxRate
                tax
                total
                isApproved
                createdAt
                updatedAt
            }
            invoice {
                id
                description
                files
                currency
                subtotal
                taxCode
                taxRate
                tax
                total
                totalPaid
                isPaid
                createdAt
                updatedAt
            }
            assignments {
                id
                firstName
                lastName
            }
        }
    }
`

export const GET_APPOINTMENTS = gql`
    query GetAppointments($filter: AppointmentFilter $range: AppointmentRange) {
        appointments(filter: $filter range: $range) {
            id
            appointmentStartAt
            appointmentEndAt
            description
            descriptionCreatedAt
            descriptionUpdatedAt
            status
            isPaid
            mileage
            mileageType
            workStartAt
            workEndAt
            pickupAndDrop
            createdAt
            updatedAt
            confirmationStatus
            customer {
                id
                fullName
                phone
            }
            vehicle {
                id
                year
                make
                model
            }
            assignments {
                id
                firstName
                lastName
            }
        }
    }
`

export const GET_APPOINTMENTS_BOARD = gql`
    query GetAppointmentsBoard($startDate: String! $endDate: String) {
        appointments: appointmentsBoard(startDate: $startDate endDate: $endDate) {
            id
            appointmentStartAt
            appointmentEndAt
            description
            descriptionCreatedAt
            descriptionUpdatedAt
            status
            isPaid
            mileage
            mileageType
            workStartAt
            workEndAt
            pickupAndDrop
            confirmationStatus
            createdAt
            updatedAt
            customer {
                id
                fullName
                phone
            }
            vehicle {
                id
                year
                make
                model
            }
            assignments {
                id
                firstName
                lastName
            }
        }
    }
` 

export const EDIT_APPOINTMENT_DESCRIPTION = gql`
    mutation EditAppointmentDescription($payload: EditAppointmentDescriptionPayload!) {
        editAppointmentDescription(payload: $payload) {
            id
            description
            descriptionCreatedAt
            descriptionUpdatedAt
            createdAt
            updatedAt
        }
    }
`

export const ADD_APPOINTMENT_ISSUE = gql`
    mutation AddAppointmentIssue($payload: AddAppointmentIssuePayload!) {
        addAppointmentIssue(payload: $payload) {
            id
            issues {
                id
                userId
                issue
                files
                createdAt
                updatedAt
            }
        }
    }
`

export const EDIT_APPOINTMENT_ISSUE = gql`
    mutation EditAppointmentIssue($payload: EditAppointmentIssuePayload!) {
        editAppointmentIssue(payload: $payload) {
            id
            userId
            issue
            createdAt
            updatedAt
        }
    }
`

export const ADD_APPOINTMENT_COMMENT = gql`
    mutation AddAppointmentComment($payload: AddAppointmentCommentPayload!) {
        addAppointmentComment(payload: $payload) {
            id
            comments {
                id
                userId
                comment
                createdAt
                updatedAt
            }
        }
    }
`

export const EDIT_APPOINTMENT_COMMENT = gql`
    mutation EditAppointmentComment($payload: EditAppointmentCommentPayload!) {
        editAppointmentComment(payload: $payload) {
            id
            userId
            comment
            createdAt
            updatedAt
        }
    }
`

export const SET_APPOINTMENT_STATUS = gql`
    mutation SetAppointmentStatus($payload: SetAppointmentStatusPayload!) {
        setAppointmentStatus(payload: $payload) {
            id
            status
            pickupAndDrop
        }
    }
`

export const SUBSCRIBE_APPOINTMENT_UPDATED = gql`
    subscription OnAppointmentUpdated($filter: AppointmentSubscriptionFilter!) {
        appointmentUpdated(filter: $filter) {
            id
        }
    }
`

export const CREATE_APPOINTMENT = gql`
    mutation CreateAppointment($payload: CreateAppointmentPayload!) {
        createAppointment(payload: $payload) {
            id
            appointmentStartAt
            appointmentEndAt
            description
            descriptionCreatedAt
            descriptionUpdatedAt
            status
            isPaid
            mileage
            mileageType
            workStartAt
            workEndAt
            pickupAndDrop
            createdAt
            updatedAt
            customer {
                id
                fullName
                phone
            }
            vehicle {
                id
                year
                make
                model
            }
        }
    }
`

export const UPDATE_APPOINTMENT_TIME = gql`
    mutation UpdateAppointmentTime($payload: UpdateAppointmentTimePayload!) {
        updateAppointmentTime(payload: $payload) {
            id
            appointmentStartAt
        }
    }
`

export const GET_APPOINTMENT_ASSIGNMENTS = gql`
    query GetAppointmentAssignments($appointmentId: ID!) {
        appointmentAssignments(appointmentId: $appointmentId) {
            id
            firstName
            lastName
        }
    }
`

export const ASSIGN_TECHNICIAN = gql`
    mutation AssignTechnician($payload: AssignTechnicianPayload!) {
        assignTechnician(payload: $payload) {
            id
            assignments {
                id
                firstName
                lastName
            }
        }
    }
`

export const DESTROY_APPOINTMENT = gql`
    mutation DestroyAppointment($payload: DestroyAppointmentPayload!) {
        destroyAppointment(payload: $payload)
    }
`
import React, { useState, useEffect, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { v4 as uuid } from 'uuid'
import axios from 'axios'

import './upload.scss'
import { useMutation } from '@apollo/react-hooks'
import { SET_VEHICLE_IMAGE } from 'operations/Vehicle'

export default function Upload({ vehicleId, type }) {

    const [upload, setUpload] = useState()
    const [progress, setProgress] = useState(0)
    const [uploadResponse, setUploadResponse] = useState()
    const [imagePreview, setImagePreview] = useState()

    const [ setVehicleImage ] = useMutation(SET_VEHICLE_IMAGE)

    const onDrop = useCallback(acceptedFiles => {

        acceptedFiles.map(file => {

            const upload = {
                file,
                key: uuid(),
                uploadComplete: false,
                data: {}
            }

            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                setImagePreview(reader.result)   
            }
            reader.readAsDataURL(file)

            return setUpload(upload)

        })

    }, [])

    useEffect(() => {

        if (upload) {
            const data = new FormData()
            data.append('upload', upload.file, upload.file.name)

            axios({
                method: 'POST',
                url: process.env.NODE_ENV === 'development' ? 'http://localhost:4200/upload-vehicle' : 'https://autoserviceapi.dsmedia.ca/upload-vehicle',
                data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: ({ loaded, total }) => {
                    setProgress(loaded / total * 100)
                }
            })
            .then(response => {
                const data = response.data[0]
                setUploadResponse(data)
            })
            .catch(err => {
                setUpload(upload => {
                    upload.uploadError = 'Error'
                    return upload
                })
            })
        }

    }, [upload])

    useEffect(() => {
        if (uploadResponse) {
            setVehicleImage({
                variables: {
                    payload: {
                        vehicleId,
                        image: [uploadResponse]
                    }
                }
            })
        }
        // eslint-disable-next-line
    }, [uploadResponse])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, 
        accept: 'image/jpeg, image/png, image/webp, image/gif, image/bmp, image/tiff, image/pjpeg'
    })

    // const [progressOne, progressTwo] = calculateProgress(progress)

    return (
        <div { ...getRootProps() } className='upload__container'>
            {
                upload && (
                    <div className='progressContainer'>
                        { imagePreview && <img className='imagePreview' src={imagePreview} alt='Preview' /> }
                        <div className='spinner__progressContainer'>
                            {
                                Math.round(progress) + '%'
                            }
                            {/* <div className='spinner__dialContainer spinner__dialContainer--container1'>
                                <div className='spinner__wedge' style={{ transform: `rotate(-${progressOne}deg)` }} />
                            </div>
                            <div className='spinner__dialContainer spinner__dialContainer--container2'>
                                <div className='spinner__wedge' style={{ transform: `rotate(-${progressTwo}deg)` }}/>
                            </div> */}
                        </div>
                    </div>
                )
            }
            
            <input { ...getInputProps() } />
            {
                !upload && (
                    isDragActive ? (
                        <p>&nbsp;<br/>Drop files here<br/>&nbsp;</p>
                    ) : (
                        <div className='upload__icon'>
                            <i className='material-icons'>photo_camera</i>
                            Upload vehicle photo
                        </div>
                    )
                )
            }

        </div>
    )
    
}

// function calculateProgress(progress) {
//     const firstProgress = progress * 2 <= 100 ? progress * 2 : 100
//     const secondProgress = progress - 50 > 0 ? progress - 50 : 0 
//     return [
//         180 - (firstProgress * 1.8),
//         180 - (secondProgress * 2 * 1.8)
//     ]
// }
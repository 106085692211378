import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { SET_APPOINTMENT_STATUS, ADD_APPOINTMENT_ISSUE, GET_APPOINTMENT_BY_ID } from 'operations/Appointment'
import { GET_INSTALLER } from 'operations/Installer'
import { ADD_ESTIMATE } from 'operations/Estimate'
import { ADD_INVOICE } from 'operations/Invoice'
import { ADD_TRANSACTION } from 'operations/Transaction'

import Card from 'components/Card'
// import { Link } from 'react-router-dom'

// import { useBoard } from 'hooks/board'

// import Label from 'components/Label'
import { Upload } from 'components/Forms'
import Form, { Field, Submit } from 'components/Form'

import { Img } from 'components/Tueri'

// import { FormatCurrency } from 'components/Format'
// import ActionGroup, { useActions } from 'components/Actions'

import { useModal } from 'hooks/modal'

// import Calendar from 'react-calendar'
// import 'react-calendar/dist/Calendar.css'

import './actions.scss'

import { CancelButton } from 'components/Button'

// import moment from 'moment'
import SalesTax from 'components/SalesTax'
import validator from 'validator'
import Loader from 'components/Loader'

import { useTranslation } from 'react-i18next'

export default function Actions({ appointment }) {

    const { t } = useTranslation()

    // const { setSelectedCard } = useBoard()
    const { setModal } = useModal()

    const [ setAppointmentStatus ] = useMutation(SET_APPOINTMENT_STATUS)

    const vehicleString = appointment.vehicle ? `${ appointment.vehicle.make } ${appointment.vehicle.model }` : 'vehicle'

    // const appointmentStartAt = moment(appointment.appointmentStartAt).format('dddd MMM D, YY @ h:mma')

    const MESSAGES = {
        ARRIVAL_PICKUP: [
            t('arrivalPickupMessagePreview', `We are on our way to pick up your {{ vehicle }}.`, { vehicle: vehicleString })
        ],
        ARRIVAL_PICKUP_COMPLETE: [
            t('standardServiceMessagePreview', `Standard Service: We have started working on your {{ vehicle }}.`, { vehicle: vehicleString }),
            t('diagnosisMessagePreview', `Diagnosis: We have started diagnosing your {{ vehicle }}.`, { vehicle: vehicleString })
        ],
        ARRIVAL_DROP_COMPLETE: [
            t('standardServiceMessagePreview', `Standard Service: We have started working on your {{ vehicle }}.`, { vehicle: vehicleString }),
            t('diagnosisMessagePreview', `Diagnosis: We have started diagnosing your {{ vehicle }}.`, { vehicle: vehicleString })
        ],
        IDENTIFIED: [
            t('identifiedMessagePreview', `We have identified an issue with your {{ vehicle }}. We'll give you a call shortly to discuss!`, { vehicle: vehicleString })
        ],
        CUSTOMER_APPROVED: [
            t('customerApprovedMessagePreview', `We have started working on your {{ vehicle }}.`, { vehicle: vehicleString })
        ],
        WORK_STARTED: [
            t('workStartedMessagePreview', `Your {{ vehicle }} is all finished. Please pay your invoice at your earliest convenience.`, { vehicle: vehicleString })
        ],
        STANDARD_SERVICE: [
            t('standardServiceMessagePreview', `Your {{ vehicle }} is all finished. Please pay your invoice at your earliest convenience.`, { vehicle: vehicleString })
        ],
        DEPARTURE_DROP: [
            t('departureDropMessagePreview', `Your {{ vehicle }} is all finished. We are on our way to drop it off.`, { vehicle: vehicleString })
        ],
        // INVOICE_SENT: `Your ${ vehicleString } is ready for pickup!`,
    }

    if (appointment.confirmationStatus === 'CANCELLED') return <div className='customerActionContainer'>{t('cancelledStatus', `Cancelled`)}</div>

    return (
        <>
        <div className='customerActionContainer'>

                {/* <button type='button' className='close' onClick={() => {setSelectedCard(); setModal()}}><i className='material-icons'>close</i></button> */}

                {/* <Link to='/board'>Close</Link> */}

                {/* TODO: IDENTIFY WHICH COLUMN THIS APPOINTMENT IS IN */}
                {/* <div style={{ textAlign: 'right', marginBottom: '1rem' }}><Label>{ appointment.status }</Label></div> */}

                {
                    appointment.status === 'ARRIVAL_PICKUP' && (
                        <Form
                            onSubmit={() => {
                                setAppointmentStatus({
                                    variables: {
                                        payload: {
                                            appointmentId: appointment.id,
                                            status: 'ARRIVAL_PICKUP_COMPLETE'
                                        }
                                    }
                                })
                                // setSelectedCard()
                                setModal()
                            }}
                        >
                            {/* <Select name='action' label='Vehicle will be...' options={[['ARRIVAL_PICKUP', 'Picked up'], ['ARRIVAL_DROP', 'Dropped off']]} /> */}
                            {() => (
                                <Submit fullWidth>{ t('pickupVehicleButton', 'Pickup Vehicle') }</Submit>
                            )}
                        </Form>
                    )
                }
                {
                    appointment.status === 'ARRIVAL_DROP' && (
                        <Form
                            onSubmit={() => {
                                setAppointmentStatus({
                                    variables: {
                                        payload: {
                                            appointmentId: appointment.id,
                                            status: 'ARRIVAL_DROP_COMPLETE'
                                        }
                                    }
                                })
                                // setSelectedCard()
                                setModal()
                            }}
                        >
                            {() => (
                                <Submit fullWidth>{ t('vehicleHasArrivedButton', 'Vehicle has arrived') }</Submit>
                            )}
                        </Form>
                    )
                }
                {
                    ['ARRIVAL_PICKUP_COMPLETE', 'ARRIVAL_DROP_COMPLETE'].includes(appointment.status) && (
                        <Form
                            onSubmit={({ rawInputs: inputs }) => {
                                setAppointmentStatus({
                                    variables: {
                                        payload: {
                                            appointmentId: appointment.id,
                                            status: inputs.action
                                        }
                                    }
                                })
                                // setSelectedCard()
                                setModal()
                            }}
                        >
                            {({ rawInputs }) => (
                                <>
                                    <Field name='action' label={ t('startJobAs', 'Start job as...') } type='select' options={[['STANDARD_SERVICE', t('selectStandardService', 'Standard service')], ['DIAGNOSIS', t('selectDiagnosis', 'Diagnose vehicle')]]} required errorMessage={ t('selectJobTypeError', 'What type of job is this?') } />
                                    <Submit disabled={ !rawInputs.action } fullWidth>{ t('startJobButton', 'Start Job') }</Submit>
                                </>
                            )}
                        </Form>
                    )
                }
                {
                    (['DIAGNOSIS'].includes(appointment.status) || (['STANDARD_SERVICE'].includes(appointment.status) && appointment.issues && appointment.issues.length > 0)) && (
                        <Form
                            onSubmit={() => {
                                setAppointmentStatus({
                                    variables: {
                                        payload: {
                                            appointmentId: appointment.id,
                                            status: 'IDENTIFIED'
                                        }
                                    }
                                })
                                // setSelectedCard()
                                setModal()
                            }}
                        >
                            {() => (
                                <>
                                    <Submit fullWidth disabled={ !appointment.issues || appointment.issues.length < 1 }>{ (!appointment.issues || appointment.issues.length < 1) ? t('addIssueButton', 'Add an issue') : t('issueIdentifiedButton', 'Issue identified') }</Submit>
                                </>
                            )}
                        </Form>
                    )
                }
                {/* TODO: NEED TO FIGURE OUT AT WHAT STAGE THE ESTIMATE IS SENT HERE. LEANING TOWARD 'WAITING' status, but may need to change name */}
                {
                    ['IDENTIFIED'].includes(appointment.status) && (
                        <Form
                            onSubmit={() => {
                                setAppointmentStatus({
                                    variables: {
                                        payload: {
                                            appointmentId: appointment.id,
                                            status: 'ESTIMATE_SENT'
                                            // status: appointment.pickupAndDrop ? 'DEPARTURE_DROP' : 'DEPARTURE_PICKUP'
                                        }
                                    }
                                })
                                // setSelectedCard()
                                setModal()
                            }}
                        >
                            {() => (
                                <>
                                    {/* TODO: Block submit when no estimate or issues */}
                                    <Submit fullWidth disabled={ !appointment.estimates || appointment.estimates.length < 1 }>{(!appointment.estimates || appointment.estimates.length < 1) ? t('addAnEstimateButton', 'Add an estmate') : t('sendEstimateButton', 'Send estimate') }</Submit>
                                </>
                            )}
                        </Form>
                    )
                }
                {/* TODO: for a DECLINED estimate, need a way to resolve it. */}
                {
                    ['CUSTOMER_APPROVED'].includes(appointment.status) && (
                        <Form
                            onSubmit={() => {
                                setAppointmentStatus({
                                    variables: {
                                        payload: {
                                            appointmentId: appointment.id,
                                            status: 'WORK_STARTED'
                                            // status: appointment.pickupAndDrop ? 'DEPARTURE_DROP' : 'DEPARTURE_PICKUP'
                                        }
                                    }
                                })
                                // setSelectedCard()
                                setModal()
                            }}
                        >
                            {() => (
                                <>
                                    {/* TODO: Block submit when no estimate or issues */}
                                    <Submit fullWidth>{ t('beginWorkButton', 'Begin Work') }</Submit>
                                </>
                            )}
                        </Form>
                    )
                }
                {
                    (['WORK_STARTED'].includes(appointment.status) || (['STANDARD_SERVICE'].includes(appointment.status) && (!appointment.issues || appointment.issues.length < 1))) && (
                        <Form
                            onSubmit={() => {
                                setAppointmentStatus({
                                    variables: {
                                        payload: {
                                            appointmentId: appointment.id,
                                            status: 'INVOICE_SENT'
                                            // status: appointment.pickupAndDrop ? 'DEPARTURE_DROP' : 'DEPARTURE_PICKUP'
                                        }
                                    }
                                })
                                // setSelectedCard()
                                setModal()
                            }}
                        >
                            {() => (
                                <>
                                    {/* TODO: Block submit when no estimate or issues */}
                                    <Submit fullWidth disabled={ !appointment.invoice }>{ !appointment.invoice ? t('addAnInvoiceButton', 'Add an invoice') : t('sendInvoiceButton', 'Send invoice') }</Submit>
                                    
                                </>
                            )}
                        </Form>
                    )
                }
                {
                    ['DEPARTURE_DROP'].includes(appointment.status) && (
                        <Form
                            onSubmit={() => {
                                setAppointmentStatus({
                                    variables: {
                                        payload: {
                                            appointmentId: appointment.id,
                                            status: 'DEPARTURE_DROP_COMPLETE'
                                        }
                                    }
                                })
                                // setSelectedCard()
                                setModal()
                            }}
                        >
                            {() => (
                                <>
                                    {/* TODO: Block submit when no estimate or issues */}
                                    <Submit fullWidth>{ t('dropOffVehicleButton', 'Drop off vehicle') }</Submit>
                                </>
                            )}
                        </Form>
                    )
                }
                {
                    ['DEPARTURE_PICKUP'].includes(appointment.status) && (
                        <Form
                            onSubmit={() => {
                                setAppointmentStatus({
                                    variables: {
                                        payload: {
                                            appointmentId: appointment.id,
                                            status: 'DEPARTURE_PICKUP_COMPLETE'
                                        }
                                    }
                                })
                                // setSelectedCard()
                                setModal()
                            }}
                        >
                            {() => (
                                <>
                                    {/* TODO: Block submit when no estimate or issues */}
                                    <Submit fullWidth>{ t('vehiclePickedUpButton', 'Vehicle Picked Up') }</Submit>
                                </>
                            )}
                        </Form>
                    )
                }

                {
                    ['ESTIMATE_SENT'].includes(appointment.status) && t('waitingForApproval', 'Waiting for {{ customerName }} to approve the estimate', { customerName: appointment.customer.fullName })
                }

                {
                    ['INVOICE_SENT'].includes(appointment.status) && t('waitingForPayment', 'Waiting for {{ customerName }} to pay their invoice', { customerName: appointment.customer.fullName })
                }
        </div>
        { [
            'ARRIVAL_PICKUP',
            'ARRIVAL_PICKUP_COMPLETE',
            'ARRIVAL_DROP_COMPLETE',
            'IDENTIFIED',
            'CUSTOMER_APPROVED',
            'WORK_STARTED',
            'STANDARD_SERVICE',
            'DEPARTURE_DROP'].includes(appointment.status) && (
            <div className='actionNotificationContainer'>
                <i className='material-icons'>notification_important</i>
                
                <Card className='actionNotificationHover'>
                    <h2>{ t('customerNotificationHeading', 'Customer notification:') }</h2>
                    {
                        MESSAGES[appointment.status].map((msg, i) => <p key={i}>{ msg }</p>)
                    }
                </Card>
            </div>
        )}
        </>
    )

}

export function NewEstimate({ appointmentId, onComplete }) {

    const { t } = useTranslation()

    // const { setSelectedCard } = useBoard()
    // const { setModal } = useModal()
    const { error, loading, data } = useQuery(GET_INSTALLER)
    const [ addEstimate ] = useMutation(ADD_ESTIMATE, { refetchQueries: [{ query: GET_APPOINTMENT_BY_ID, variables: { id: appointmentId } }] })
    const [errors, setErrors] = useState({})

    const [ files, setFiles ] = useState([])

    if (error || loading) return <Loader />

    const { salesTaxes } = data.installer

    return (
        <Form
            onSubmit={async({ rawInputs: inputs, enableSubmit }) => {

                const formErrors = {}

                if (!files) formErrors.files = true
                if (Number(inputs.subtotal) < .01) formErrors.subtotal = true

                if (Object.keys(formErrors).length > 0) {
                    enableSubmit()
                    return setErrors(formErrors)
                }
                try {
                    await addEstimate({
                        variables: {
                            payload: {
                                appointmentId: appointmentId,
                                subtotal: Number(inputs.subtotal),
                                files
                            }
                        }
                    })
                    // setSelectedCard()
                    onComplete && onComplete()
                }
                catch(err) {
                    alert(err)
                }
            }}
        >
                {({ rawInputs: inputs }) => {

                    const subtotal = inputs.subtotal ? inputs.subtotal : 0

                    return (
                        <>
                            {/* <ModalClose /> */}
                            <h1>{ t('addAnEstimate', 'Add an Estimate') }</h1>
                            <Upload onUploadComplete={(e) => setFiles(e)} />
                            <Field name='subtotal' label={ t('subtotal', 'Subtotal') } type='decimal' autoComplete='off' formatting={['currency']} required error={ errors.subtotal } errorMessage={ t('subtotalError', 'Please enter the subtotal.') } />
                            <SalesTax subtotal={ subtotal } salesTaxes={ salesTaxes } />
                            <Submit disabled={ !files || Number(inputs.subtotal) < .01 }>{ t('addEstimate', 'Add estimate') }</Submit><CancelButton or onClick={ onComplete }>{ t('cancel', 'Cancel') }</CancelButton>
                        </>
                    )

                }}
        </Form>
    )
}

export function NewInvoice({ appointmentId, onComplete }) {

    const { t } = useTranslation()

    // const { setSelectedCard } = useBoard()
    // const { setModal } = useModal()
    const { error, loading, data } = useQuery(GET_INSTALLER)
    const [ addInvoice ] = useMutation(ADD_INVOICE, { refetchQueries: [{ query: GET_APPOINTMENT_BY_ID, variables: { id: appointmentId } }] })
    const [ files, setFiles ] = useState([])
    const [errors, setErrors] = useState({})

    if (error || loading) return <Loader />

    const { salesTaxes } = data.installer

    return (
        <Form
            onSubmit={async ({ rawInputs: inputs, enableSubmit }) => {

                const formErrors = {}

                if (!files) formErrors.files = true
                if (Number(inputs.subtotal) < .01) formErrors.subtotal = true

                if (Object.keys(formErrors).length > 0) {
                    enableSubmit()
                    return setErrors(formErrors)
                }

                try {
                    await addInvoice({
                        variables: {
                            payload: {
                                appointmentId: appointmentId,
                                subtotal: Number(inputs.subtotal),
                                files
                            }
                        }
                    })
                    // setSelectedCard()
                    onComplete && onComplete()
                }
                catch(err) {
                    alert(err)
                }
            }}
        >
                {({ rawInputs: inputs }) => {

                    const subtotal = inputs.subtotal ? inputs.subtotal : 0

                    return (
                        <>
                            {/* <ModalClose /> */}
                            <h1>{ t('addAnInvoice', 'Add an invoice') }</h1>
                            <Upload onUploadComplete={(e) => setFiles(e)} />
                            <Field name='subtotal' label={ t('subtotal', 'Subtotal') } type='decimal' autoComplete='off' formatting={['currency']} required error={ errors.subtotal } errorMessage={ t('subtotalError', 'Please enter the subtotal.') } />
                            <SalesTax subtotal={ subtotal } salesTaxes={ salesTaxes } />
                            <Submit disabled={ !files || Number(inputs.subtotal) < .01 }>{ t('addInvoice', 'Add invoice') }</Submit><CancelButton or onClick={ onComplete }>{ t('cancel', 'Cancel') }</CancelButton>
                        </>
                    )

                }}
        </Form>
    )
}

export function Thumbnail({ file }) {

    const thumbnailOptions = {
        // w: 300,
        w: 100
        // h: 48
    }

    if (file.mimetype === 'application/pdf') {
        thumbnailOptions.fm = 'jpg'
    }

    return (
        <div style={{ position: 'relative', height: '100px' }}><Img src={`https://cdn.tueri.io/68719476737/autoservice/${ file.key.replace('uploads/', '') }`} options={ thumbnailOptions } alt={ 'test' } /></div>
    )
}

export function NewIssue({ appointmentId, onComplete }) {

    const { t } = useTranslation()

    // const { setSelectedCard } = useBoard()
    // const { setModal } = useModal()
    const [ addAppointmentIssue ] = useMutation(ADD_APPOINTMENT_ISSUE)
    const [errors, setErrors] = useState({})
    const [ files, setFiles ] = useState([])

    return (
        <Form
            onSubmit={async({ rawInputs: inputs, enableSubmit }) => {

                const formErrors = {}

                // if (!files) formErrors.files = true
                if (validator.isEmpty(inputs.issue)) formErrors.issue = true

                if (Object.keys(formErrors).length > 0) {
                    enableSubmit()
                    return setErrors(formErrors)
                }


                try {
                    await addAppointmentIssue({
                        variables: {
                            payload: {
                                appointmentId: appointmentId,
                                issue: inputs.issue,
                                files
                            }
                        }
                    })

                    // setSelectedCard()
                    onComplete && onComplete()
                }
                catch (err) {
                    alert(err)
                }
            }}
        >
                {() => {


                    return (
                        <>
                            {/* <ModalClose /> */}
                            <h1>{ t('addAnIssue', 'Add an issue') }</h1>
                            <Upload onUploadComplete={(e) => setFiles(e)} />
                            <Field name='issue' label={ t('issue', 'Issue') } autoComplete='off' required error={ errors.issue } errorMessage={ t('issueError', 'Please describe the issue.') } />
                            <Submit>{ t('addIssue', 'Add issue') }</Submit><CancelButton or onClick={ onComplete }>{ t('cancel', 'Cancel') }</CancelButton>
                        </>
                    )

                }}
            
        </Form>
    )
}

export function EstimateApproval({ appointmentId, onComplete }) {

    const { t } = useTranslation()

    // const { setSelectedCard } = useBoard()
    // const { setModal } = useModal()
    const [ setAppointmentStatus ] = useMutation(SET_APPOINTMENT_STATUS)

    return (
        <Form
            onSubmit={async () => {

                try {
                    await setAppointmentStatus({
                        variables: {
                            payload: {
                                appointmentId,
                                status: 'CUSTOMER_APPROVED'
                            }
                        }
                    })
                    onComplete && onComplete()
                }
                catch (err) {
                    alert(err)
                }
            }}
        >
                {() => {


                    return (
                        <>
                            {/* <ModalClose /> */}
                            <h1>{ t('approvalCheck', `Are you sure you want to approve this estimate on your customer's behalf?`) }</h1>
                            <Submit>{ t('approveEstimate', 'Approve estimate') }</Submit><CancelButton or onClick={ onComplete }>{ t('cancel', 'Cancel') }</CancelButton>
                        </>
                    )

                }}
            
        </Form>
    )
}

export function PayInvoice({ appointmentId, onComplete }) {

    const { t } = useTranslation()

    const [errors, setErrors] = useState({})
    
    const { error, loading, data } = useQuery(GET_APPOINTMENT_BY_ID, { variables: { id: appointmentId } })
    const [ addTransaction ] = useMutation(ADD_TRANSACTION, { refetchQueries: [{ query: GET_APPOINTMENT_BY_ID, variables: { id: appointmentId } }] })

    if (error || loading) return <Loader />

    const { appointment } = data

    return (
        <Form
            onSubmit={async ({ rawInputs, enableSubmit }) => {

                const formErrors = {}

                if (validator.isEmpty(rawInputs.amount) || rawInputs.amount < 0.01) formErrors.amount = true
                if (validator.isEmpty(rawInputs.paymentMethod)) formErrors.paymentMethod = true
                if (rawInputs.paymentMethod === 'CREDIT' && validator.isEmpty(rawInputs.cardType)) formErrors.cardType = true
                if (['CREDIT', 'DEBIT'].includes(rawInputs.paymentMethod) && (validator.isEmpty(rawInputs.lastFourDigits) || rawInputs.lastFourDigits.length !== 4)) formErrors.lastFourDigits = true
                if (rawInputs.paymentMethod === 'CHEQUE' && validator.isEmpty(rawInputs.chequeNumber)) formErrors.chequeNumber = true
                if (rawInputs.paymentMethod === 'E_TRANSFER' && validator.isEmpty(rawInputs.confirmationNumber)) formErrors.confirmationNumber = true

                if (Object.keys(formErrors).length > 0) {
                    enableSubmit()
                    return setErrors(formErrors)
                }

                try {
                    await addTransaction({
                        variables: {
                            payload: {
                                appointmentId: appointment.id,
                                invoiceId: appointment.invoice.id,
                                ...rawInputs,
                                amount: Number(rawInputs.amount)
                            }
                        }
                    })
                    onComplete && onComplete()
                }
                catch (err) {
                    alert(err)
                }
            }}
        >
                {({ rawInputs }) => {


                    return (
                        <>
                            {/* <ModalClose /> */}
                            <h1>{ t('addAPayment', 'Add a payment') }</h1>
                            <Field name='amount' label={ t('amount', 'Amount') } type='decimal' autoComplete='off' formatting={['currency']} required error={ errors.amount } errorMessage={ t('amountError', 'Please enter an amount.' ) } defaultValue={ ((appointment.invoice.total - appointment.invoice.totalPaid) || 0).toFixed(2) } />
                            <Field name='paymentMethod' label={ t('methodOfPayment', 'Method of payment') } type='select' options={[['CREDIT', t('paymentMethodCredit', 'Credit')], ['DEBIT', t('paymentMethodDebit', 'Debit')], ['CHEQUE', t('paymentMethodCheque', 'Cheque')], ['E_TRANSFER', t('paymentMethodETransfer', 'E-transfer')], ['CASH', t('paymentMethodCash', 'Cash')]]} required error={ errors.paymentMethod } errorMessage={ t('paymentMethodError', 'What is the method of payment?') } />
                            {
                                rawInputs.paymentMethod === 'CREDIT' && <Field name='cardType' label={ t('cardType', 'Card type') } type='select' options={[['VISA', t('cardTypeVisa', 'Visa')], ['MASTERCARD', t('cardTypeMastercard', 'Mastercard')], ['AMERICAN_EXPRESS', t('cardTypeAmex', 'American Express')]]} required error={ errors.cardType } errorMessage={ t('cardTypeError', 'What type of credit card was used?') } />
                            }
                            {
                                ['CREDIT', 'DEBIT'].includes(rawInputs.paymentMethod) && <Field name='lastFourDigits' label={ t('lastFourDigits', 'Last 4 digits of card') } type='numeric' required error={ errors.lastFourDigits } errorMessage={ rawInputs.paymentMethod === 'CREDIT' ? t('lastFourDigitsCreditError', `What are the last four digits of the credit card?`) : t('lastFourDigitsDebitError', `What are the last four digits of the debit card?`) } maxLength={4} />
                            }
                            {
                                rawInputs.paymentMethod === 'CHEQUE' && <Field name='chequeNumber' label={ t('chequeNumber', 'Cheque number') } type='numeric' required error={ errors.chequeNumber } errorMessage={ t('chequeNumberError', 'What is the cheque number?') } />
                            }
                            {
                                rawInputs.paymentMethod === 'E_TRANSFER' && <Field name='confirmationNumber' label={ t('eTransferConfirmationNumber', 'Confirmation number') } type='numeric' required error={ errors.confirmationNumber } errorMessage={ t('eTransferConfirmationNumberError', 'What is the e-transfer confirmation number?') } />
                            }
                            <Submit>{ t('addPayment', 'Add payment') }</Submit><CancelButton or onClick={ onComplete }>{ t('cancel', 'Cancel') }</CancelButton>
                        </>
                    )

                }}
            
        </Form>
    )
}
import React from 'react'

export default function Fieldset({ children, legend, style, className }) {

    return (
        <fieldset style={ style } className={ className }>
            <legend>{ legend }</legend>
            { children }
        </fieldset>
    )

}
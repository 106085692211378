import React from 'react'

import './label.scss'

import { useTranslation } from 'react-i18next'

export default function Label ({ children: status, large }) {

    const { t } = useTranslation()

    const statuses = {
        'APPOINTMENT_REQUEST': t('appointmentRequestStatus', 'New lead'),
        'ARRIVAL_PICKUP': t('pickUpStatus', 'Pick up'),
        'ARRIVAL_PICKUP_COMPLETE': t('pickUpStatusComplete', 'Picked up'),
        'ARRIVAL_DROP': t('dropOffStatus', 'Drop off'),
        'ARRIVAL_DROP_COMPLETE': t('dropOffStatusComplete', 'Dropped off'),
        'STANDARD_SERVICE': t('standardServiceStatus', 'Standard'),
        'DIAGNOSIS': t('diagnosisStatus', 'Diagnosis'),
        'IDENTIFIED': t('identifiedStatus', 'Identified'),
        'ESTIMATE_SENT': t('estimateSentStatus', 'Awaiting approval'),
        'CUSTOMER_APPROVED': t('customerApprovedStatus', 'Approved'),
        'CUSTOMER_DECLINED': t('customerDeclinedStatus', 'Declined'),
        'WORK_STARTED': t('workStartedStatus', 'In progress'),
        'INVOICE_SENT': t('invoiceSentStatus', 'Invoiced'),
        'INVOICE_PAID': t('invoicePaidStatus', 'Invoice paid'),
        'DEPARTURE_PICKUP': t('pickUpStatus', 'Pick up'),
        'DEPARTURE_PICKUP_COMPLETE': t('pickUpStatusComplete', 'Picked up'),
        'DEPARTURE_DROP': t('dropOffStatus', 'Drop off'),
        'DEPARTURE_DROP_COMPLETE': t('dropOffStatusComplete', 'Dropped off'),
        'CANCELLED': t('cancelledStatus', 'Cancelled')
    }

    return <div className={`labelPill labelPill--${ status.toLowerCase() } ${ large ? 'labelPill--large' : 'labelPill--small' }`}>{ statuses[status] }</div>
}
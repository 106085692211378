import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_APPOINTMENTS_BOARD } from "operations/Appointment";

import { useParams } from "react-router-dom";
import Card from "components/Card";

// import { useModal } from 'hooks/modal'
import { ModalButton } from "components/Modal";
import {
  FormatPhone,
  // FormatDate
} from "components/Format";
import Label from "components/Label";

// import moment from "moment";
import { DateTime } from "luxon";

import { PrimaryButton } from "components/Button";

// import Customer from './Customer'
// import { useBoard } from 'hooks/board'

// import Form, { Input, Submit, FormContext, AutoComplete } from 'components/Forms'

// import debounce from 'debounce'

// import { Img } from 'components/Tueri'

// import { NewAppointment, ExistingAppointment as Appointment } from '../Appointment'

import "./board.scss";
import "./customer.scss";
// import { GET_CUSTOMERS_BY_PHONE } from 'operations/Customer'
import Loader from "components/Loader";
import Week, { StyleSwitcher } from "./Week";
import Day from "./Day";

import { useTranslation } from "react-i18next";
import TechnicianFilter from "components/TechnicianFilter";
import Initials from "components/Initials";
import { useAssignments } from "hooks/assignments";

const COLUMNS = {
  INBOX: ["APPOINTMENT_REQUEST"],
  NEXT_UP: [
    "ARRIVAL_PICKUP",
    "ARRIVAL_PICKUP_COMPLETE",
    "ARRIVAL_DROP",
    "ARRIVAL_DROP_COMPLETE",
  ],
  IN_PROGRESS: [
    "STANDARD_SERVICE",
    "DIAGNOSIS",
    "IDENTIFIED",
    "ESTIMATE_SENT",
    "CUSTOMER_APPROVED",
    "CUSTOMER_DECLINED",
    "WORK_STARTED",
  ],
  FINISHED: [
    "INVOICE_SENT",
    "INVOICE_PAID",
    "DEPARTURE_PICKUP",
    "DEPARTURE_PICKUP_COMPLETE",
    "DEPARTURE_DROP",
    "DEPARTURE_DROP_COMPLETE",
  ],
};

export default function Board() {
  const { t } = useTranslation();

  // const { modal, setModal } = useModal()
  // const { selectedCard } = useBoard()

  const { view } = useParams();

  // const { selectedCard, setSelectedCard } = useBoard()
  const { error, loading, data } = useQuery(GET_APPOINTMENTS_BOARD, {
    variables: {
      startDate: DateTime.local().startOf("day"),
      endDate: DateTime.local().endOf("day"),
    },
    pollInterval: 10000,
  });

  // const [getAppointment] = useLazyQuery(GET_APPOINTMENT_BY_ID, { fetchPolicy: 'network-only' })
  // const { data: subscriptionData, loading: subscriptionLoading } = useSubscription(SUBSCRIBE_APPOINTMENT_UPDATED, { variables: { filter: { installerId: user.asp } } })
  // useEffect(() => {
  //     // console.log(subscriptionData)
  //     if (!subscriptionLoading && subscriptionData && subscriptionData.appointmentUpdated && subscriptionData.appointmentUpdated.id) getAppointment({ variables: { id: subscriptionData.appointmentUpdated.id } })
  //     // eslint-disable-next-line
  // }, [subscriptionLoading, subscriptionData])

  if (error || loading) return <Loader />;

  const { appointments } = data;

  // if (appointments.length < 1) return (
  //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
  //         <div>
  //             <h1 style={{ marginBottom: '6rem' }}>There aren't any appointment scheduled for { moment().format('dddd, MMMM Do YYYY') }.</h1>
  //             <center><PrimaryButton to='/appointment'>Book an appointment</PrimaryButton></center>
  //         </div>
  //     </div>
  // )

  return (
    <>
      {!view && (
        <div className="boardContainer">
          <div className="boardHeader">
            {DateTime.local().toLocaleString(DateTime.DATE_HUGE)}
            <StyleSwitcher type={t("scheduleButton", `Schedule`)} />
            <TechnicianFilter />
          </div>
          {appointments.length < 1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                background: "#ffffff",
              }}
            >
              <div>
                <h1 style={{ marginBottom: "6rem" }}>
                  {t(
                    "noAppointmentsScheduled",
                    "There aren't any appointments scheduled for {{ todaysDate }}.",
                    {
                      todaysDate: DateTime.local().toLocaleString(
                        DateTime.DATE_HUGE
                      ),
                    }
                  )}
                </h1>
                <center>
                  <PrimaryButton to="/appointment">
                    {t("bookAnAppointmentButton", "Book an appointment")}
                  </PrimaryButton>
                </center>
              </div>
            </div>
          ) : (
            <div className="boardColumns">
              <BoardSection
                label={t("inboxColumn", "Inbox")}
                appointments={appointments.filter((appointment) =>
                  COLUMNS["INBOX"].includes(appointment.status)
                )}
              />
              <BoardSection
                label={t("nextUpColumn", "Next Up")}
                appointments={appointments.filter((appointment) =>
                  COLUMNS["NEXT_UP"].includes(appointment.status)
                )}
              />
              <BoardSection
                label={t("inProgressColumn", "In Progress")}
                appointments={appointments.filter((appointment) =>
                  COLUMNS["IN_PROGRESS"].includes(appointment.status)
                )}
              />
              <BoardSection
                label={t("completeColumn", "Complete")}
                appointments={appointments.filter((appointment) =>
                  COLUMNS["FINISHED"].includes(appointment.status)
                )}
              />
            </div>
          )}
        </div>
      )}

      {view === "week" && <Week />}
      {view === "day" && <Day />}

      <ModalButton
        modal="NEW_APPOINTMENT"
        to="/appointment"
        hoverText={t("addAnAppointment", "Add an appointment")}
      />

      {/* {
                modal === 'NEW_APPOINTMENT' && <Modal fullscreen><ModalButton modal='NEW_APPOINTMENT' hoverText='Add an appointment' /><NewAppointment /></Modal>
            } */}

      {/* {
                modal === 'NEW_APPOINTMENT' && <Modal fullscreen><NewAppointment appointment={ selectedCard } /></Modal>
            }
            {
                modal === 'APPOINTMENT' && <Modal fullscreen><Appointment appointment={ selectedCard } /></Modal>
            } */}
    </>
  );
}

function BoardSection({ label, appointments }) {
  const { assignmentFilter } = useAssignments();

  return (
    <div className="boardSection">
      <h2>
        {label}{" "}
        <span>
          {appointments.length}{" "}
          {appointments.length > 1 || appointments.length === 0
            ? "jobs"
            : "job"}
        </span>
      </h2>

      {appointments &&
        appointments.map((appointment) => {
          let isAssigned = true;

          if (assignmentFilter && assignmentFilter.length > 0) {
            if (appointment.assignments && appointment.assignments.length > 0) {
              isAssigned = false;
              appointment.assignments.map((assignment) => {
                if (assignmentFilter.includes(assignment.id)) isAssigned = true;
                return null;
              });
            }
          }

          if (!isAssigned) return null;

          return (
            <React.Fragment key={appointment.id}>
              <Card to={`/appointment/${appointment.id}`}>
                <div className="boardCard__container">
                  <div className="boardCard__customerName">
                    {appointment.customer.fullName}
                  </div>
                  <div className="boardCard__customerPhone">
                    <FormatPhone>{appointment.customer.phone}</FormatPhone>
                  </div>
                  <div className="boardCard__vehicle">
                    {/* TODO: TRANSLATE DATE HERE */}
                    {appointment.vehicle && (
                      <>
                        {appointment.vehicle.year} {appointment.vehicle.make}{" "}
                        {appointment.vehicle.model}
                        {appointment.appointmentStartAt &&
                          [
                            "ARRIVAL_PICKUP",
                            "ARRIVAL_PICKUP_COMPLETE",
                            "ARRIVAL_DROP",
                            "ARRIVAL_DROP_COMPLETE",
                          ].includes(appointment.status) && (
                            <div>
                              {appointment.appointmentStartAt &&
                                DateTime.fromMillis(
                                  Number(appointment.appointmentStartAt)
                                ).toLocaleString(DateTime.DATETIME_SHORT)}
                            </div>
                          )}
                      </>
                    )}
                  </div>
                  <div className="boardCard__statusContainer">
                    {appointment.status &&
                      appointment.status !== "APPOINTMENT_REQUEST" && (
                        <Label>
                          {appointment.confirmationStatus === "CANCELLED"
                            ? "CANCELLED"
                            : appointment.status}
                        </Label>
                      )}
                  </div>
                  {/* { moment.unix(appointment.updatedAt.substr(0,10)).fromNow() } */}
                  {/* TODO: look into showing time since last status change */}
                  <div className="assignments">
                    {appointment.assignments &&
                      appointment.assignments.map((assignment) => (
                        <div
                          key={assignment.id}
                          className="assignment"
                          title={
                            assignment.firstName + " " + assignment.lastName
                          }
                        >
                          <Initials
                            name={
                              assignment.firstName + " " + assignment.lastName
                            }
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </Card>
            </React.Fragment>
          );
        })}
    </div>
  );
}

// function Card2({ appointment }) {

//     // const { selectedCard, setSelectedCard } = useBoard()
//     // const { setModal } = useModal()

//     // const isSelectedCard = selectedCard && selectedCard.id === appointment.id

//     return (
//         <Link
//         // isSelectedCard={ isSelectedCard }
//         // onClick={() => {
//         //     setSelectedCard(appointment)
//         //     setModal(appointment.status === 'APPOINTMENT_REQUEST' ? 'NEW_APPOINTMENT' : 'APPOINTMENT')
//         // }}
//         to={`/appointment/${ appointment.id }`}
//         >
//             <div className='boardCard__container'>
//                 <div className='boardCard__customerName'>{ appointment.customer.fullName }</div>
//                 <div className='boardCard__customerPhone'><FormatPhone>{ appointment.customer.phone }</FormatPhone></div>
//                 <div className='boardCard__vehicle'>
//                     { appointment.vehicle && <>{ appointment.vehicle.year } { appointment.vehicle.make } { appointment.vehicle.model }{ appointment.appointmentStartAt && ['ARRIVAL_PICKUP', 'ARRIVAL_PICKUP_COMPLETE', 'ARRIVAL_DROP', 'ARRIVAL_DROP_COMPLETE'].includes(appointment.status) && <span> @ { appointment.appointmentStartAt && moment.unix(appointment.appointmentStartAt.substr(0,10)).format('h:mma') }</span> }</> }
//                 </div>
//                 <div className='boardCard__statusContainer'>
//                     { appointment.status && appointment.status !== 'APPOINTMENT_REQUEST' && <Label>{ appointment.status }</Label> }
//                 </div>
//                 {/* { moment.unix(appointment.updatedAt.substr(0,10)).fromNow() } */}
//                 {/* TODO: look into showing time since last status change */}
//             </div>
//             {/* { isSelectedCard && appointment.status !== 'APPOINTMENT_REQUEST' && <Customer appointmentId={ selectedCard.id } /> } */}
//         </Link>
//     )

// }

// function CardType({ isSelectedCard, children, onClick, to }) {

//     if (isSelectedCard) return (
//         <div className='boardCard boardCard--fullscreen'>
//             { children }
//         </div>
//     )

//     return (
//         <button type='type' className='boardCard'
//             onClick={ onClick }
//             to={ to }
//         >
//             { children }
//         </button>
//     )

// }

import React from 'react'

import './blur.scss'

export default function Blur({ children }) {

    if (process.env.REACT_APP_BLUR) return <span className='blur'>{ children }</span>

    return children

}
import React from 'react'
import { useRefresh } from '../../hooks/refresh'
import './refresh.css'
import { useTranslation } from 'react-i18next'

const location = window.location

export default function Refresh() {

    const { t } = useTranslation()

    const { shouldRefresh } = useRefresh()

    if (shouldRefresh) return (
        <div className='refreshContainer'>
            { t('newFeaturesBannerText', `New features are available. Refresh the page to see what's new:`) } <button type='button' onClick={() => location.reload(true)}>{ t('refreshButton', 'Click to Refresh') }</button>
        </div>
    )

    return null
}
import gql from 'graphql-tag'

export const ADD_ESTIMATE = gql`
    mutation AddEstimate($payload: AddEstimatePayload!) {
        addEstimate(payload: $payload) {
            id
            status
            estimates {
                id
                description
                files
                currency
                subtotal
                taxCode
                taxRate
                tax
                total
                isApproved
                createdAt
                updatedAt
            }
        }
    }
`
import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import Form, { Field, Submit } from "components/Form";
import { useAuthentication } from "hooks/authentication";
import { CREATE_INSTALLER_ACCOUNT } from "operations/Auth";
import { useTranslation } from "react-i18next";
import ErrorMessage from "components/ErrorMessage";
import { Link, useHistory } from "react-router-dom";
import owasp from "owasp-password-strength-test";

import validator from "validator";
// import HCaptcha from '@hcaptcha/react-hcaptcha'

import "../Login/login.scss";

export default function Login() {
  const { t } = useTranslation();

  // const captcharef = useRef(null)
  const history = useHistory();

  const { setAuthenticated } = useAuthentication();
  // const [ captchaToken, setCaptchaToken ] = useState()

  const [error, setError] = useState();
  const [passwordTestsPassed, setPasswordTestsPassed] = useState([]);
  const [passwordTestsFailed, setPasswordTestsFailed] = useState([]);
  const [isPassphrase, setPassphrase] = useState(false);
  const [errors, setErrors] = useState({});

  const [authenticate] = useMutation(CREATE_INSTALLER_ACCOUNT);

  const ERROR_MESSAGES = {
    EXISTING_USER: t(
      "existingUserError",
      "A user with that email already exists. Please sign in instead."
    ),
    PASSWORD_REQUIREMENTS_FAILED: t(
      "passwordRequirementsFailedError",
      "Sorry, your password does not meet minimum strength requirements."
    ),
    PASSWORD_CONFIRMATION_FAILED: t(
      "passwordConfirmationNotMatch",
      "Sorry, but your passwords do not match."
    ),
  };

  const passwordTests = [
    t(
      "passwordTooShortError",
      "Your password must be at least 10 characters long."
    ),
    t(
      "passwordTooLongError",
      "Your password must be fewer than 128 characters."
    ),
    t(
      "passwordNoSequence",
      "Your password may not contain sequences of three or more repeated characters."
    ),
    t(
      "passwordNeedsLowerCase",
      "Your password must contain at least one lowercase letter."
    ),
    t(
      "passwordNeedsUpperCase",
      "Your password must contain at least one uppercase letter."
    ),
    t("passwordNeedsNumber", "Your password must contain at least one number."),
    t(
      "passwordNeedsSpecialChar",
      "Your password must contain at least one special character."
    ),
  ];

  return null;

  return (
    <div className="login__container">
      <div className="login__sidebar">
        <div>
          <ul>
            <li>
              <h2>
                <i className="material-icons">check_circle</i>
                {t("quickSignUpBenefit", "Quick and free sign up")}
              </h2>
              {t(
                "quickSignUpBenefitMessage",
                "Enter your email address to create your Automotive Service Provider account."
              )}
            </li>
            <li>
              <h2>
                <i className="material-icons">check_circle</i>
                {t(
                  "customerExpectationsBenefit",
                  "Easily manage customer expectations"
                )}
              </h2>
              {t(
                "customerExpectationsBenefitMessage",
                "Send automatic SMS notifications and keep your customers updated on the status of their appointment."
              )}
            </li>
            <li>
              <h2>
                <i className="material-icons">check_circle</i>
                {t("onlinePaymentsBenefit", "Start accepting online payments")}
              </h2>
              {t(
                "onlinePaymentsBenefitMessage",
                "Integrate online payments in just a few minutes."
              )}
            </li>
          </ul>
        </div>
      </div>

      <div className="login__body">
        <div>
          <div className="logo">
            <i className="material-icons">directions_car</i>
          </div>

          <Form
            className="login"
            onSubmit={({
              rawInputs: {
                firstName,
                lastName,
                username,
                password,
                confirmPassword,
                emailOptIn,
              },
              enableSubmit,
            }) => {
              const formErrors = {};

              if (validator.isEmpty(firstName)) formErrors.firstName = true;
              if (validator.isEmpty(lastName)) formErrors.lastName = true;
              if (validator.isEmpty(username) || !validator.isEmail(username))
                formErrors.username = true;
              if (validator.isEmpty(password)) formErrors.password = true;
              if (validator.isEmpty(confirmPassword))
                formErrors.confirmPassword = true;

              if (Object.keys(formErrors).length > 0) {
                enableSubmit();
                return setErrors(formErrors);
              }

              authenticate({
                variables: {
                  payload: {
                    firstName,
                    lastName,
                    username,
                    password,
                    confirmPassword,
                    emailOptIn: emailOptIn ? true : false,
                  },
                },
              })
                .then(({ data: { createInstallerAccount } }) => {
                  localStorage.setItem(
                    "accessToken",
                    createInstallerAccount.accessToken
                  );
                  localStorage.setItem(
                    "refreshToken",
                    createInstallerAccount.refreshToken
                  );
                  setAuthenticated(true);
                  history.replace("/onboarding");
                })
                .catch((err) => {
                  const { graphQLErrors } = err;
                  graphQLErrors.forEach(({ message }) => {
                    setError(ERROR_MESSAGES[message]);
                  });
                  enableSubmit();
                });
            }}
          >
            {({ rawInputs }) => (
              <>
                {/* <h1>{ t('login', 'Login') }<button type='button' className='languageButton' onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en')}>{ i18n.language === 'fr' ? 'English' : 'Français' }</button></h1> */}
                <h1>{t("signUpHeading", "Sign up for a shop account")}</h1>

                {error && <ErrorMessage>{error}</ErrorMessage>}

                <Field
                  label={t("firstName", "First name")}
                  name="firstName"
                  required
                  error={errors.firstName}
                  errorMessage={t(
                    "signUpFirstNameError",
                    "Please enter your first name."
                  )}
                />
                <Field
                  label={t("lastName", "Last name")}
                  name="lastName"
                  required
                  error={errors.lastName}
                  errorMessage={t(
                    "signupLastNameError",
                    "Please enter your last name."
                  )}
                />
                <Field
                  label={t("email", "Email")}
                  type="email"
                  name="username"
                  required
                  error={errors.username}
                  errorMessage={t(
                    "signUpEmailError",
                    "Please enter your email address."
                  )}
                />
                <Field
                  label={t("password", "Password")}
                  type="password"
                  name="password"
                  required
                  error={errors.password}
                  errorMessage={t(
                    "signUpPasswordError",
                    "Please enter a secure password."
                  )}
                  autoComplete="off"
                  onChange={(e) => {
                    const owaspTests = owasp.test(e.target.rawValue);
                    setPasswordTestsPassed(owaspTests.passedTests);
                    setPasswordTestsFailed(owaspTests.failedTests);
                    setPassphrase(owaspTests.isPassphrase);
                  }}
                />
                {rawInputs.password && (
                  <div style={{ marginBottom: "1.5rem" }}>
                    {isPassphrase ? (
                      passwordTestsFailed[0] === 2 ? (
                        <div className={`passwordTest passwordTest--failed`}>
                          <i
                            className={`material-icons`}
                            style={{ verticalAlign: "bottom", color: "red" }}
                          >
                            block
                          </i>{" "}
                          {passwordTests[2]}
                        </div>
                      ) : (
                        <div className="passwordTest">
                          <i
                            className={`material-icons`}
                            style={{ verticalAlign: "bottom", color: "green" }}
                          >
                            done
                          </i>{" "}
                          {t(
                            "strongPassphraseRequirement",
                            "Strong passphrase."
                          )}
                        </div>
                      )
                    ) : (
                      passwordTests.map((test, key) => {
                        if ([1, 2].indexOf(key) !== -1) {
                          if (passwordTestsFailed.indexOf(key) !== -1) {
                            return (
                              <div key={key} className="passwordTest">
                                <i
                                  className={`material-icons`}
                                  style={{
                                    verticalAlign: "bottom",
                                    color: "red",
                                  }}
                                >
                                  block
                                </i>{" "}
                                {test}
                              </div>
                            );
                          }
                          return null;
                        }
                        const isPassed =
                          passwordTestsPassed.indexOf(key) !== -1
                            ? true
                            : false;
                        return (
                          <div
                            key={key}
                            className={`passwordTest ${
                              isPassed
                                ? "passwordTest--passed"
                                : "passwordTest--failed"
                            }`}
                          >
                            <i
                              className={`material-icons`}
                              style={{ verticalAlign: "bottom" }}
                            >
                              done
                            </i>{" "}
                            {test}
                          </div>
                        );
                      })
                    )}
                  </div>
                )}
                <Field
                  label={t("confirmPassword", "Confirm password")}
                  type="password"
                  name="confirmPassword"
                  autocomplete="off"
                  style={{ marginTop: "1rem" }}
                  required
                  error={errors.confirmPassword}
                  errorMessage={t(
                    "signUpConfirmPasswordError",
                    "Please confirm your password."
                  )}
                />
                <Field
                  label={t(
                    "emailOptIn",
                    "Get emails about product updates, industry news, and events?"
                  )}
                  type="checkbox"
                  name="emailOptIn"
                  required
                />
                {/* You can unsubscribe at any time. View our Privacy Policy or Contact Us. */}
                {/* <HCaptcha ref={captcharef} sitekey="107a5e02-3a23-4fdd-ae44-f1304a072e1a" size='invisible' onVerify={ token => setCaptchaToken(token) }/> */}
                <Submit>{t("signUp", "Sign up")}</Submit>
              </>
            )}
          </Form>

          <div className="loginSignupSwitch">
            {t("alreadyHaveAnAccountQuestion", "Already have an account?")}{" "}
            <Link to="/">{t("signIn", "Sign in")}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react'

import { useQuery } from '@apollo/react-hooks'
import { GET_CUSTOMER_VEHICLES } from 'operations/Vehicle'

import Error from 'components/Error'
import { useTranslation } from 'react-i18next'

import Loader from 'components/Loader'
import NoData from 'components/NoData'
// import { VehicleLarge } from 'components/Vehicle'
import { VehicleCard } from 'components/Card'

export default function Vehicles({ customerId }) {

    const { t } = useTranslation()

    const { error, loading, data } = useQuery(GET_CUSTOMER_VEHICLES, {
        variables: {
            customerId
        }
    })

    if (error) return <Error>{ t('Oops. We are having trouble finding any vehicles for this customer.') }</Error>

    if (loading) return <Loader />

    const { customerVehicles } = data

    if (customerVehicles.length < 1) return <NoData>{ t('This customer does not have any vehicles.') }</NoData>

    return (
        <>
            {
                customerVehicles.map(vehicle => {
                    return (
                        <VehicleCard key={ vehicle.id } vehicle={ vehicle } />
                    )
                })
            }
        </>
    )

}
import React from 'react'

import { useQuery } from '@apollo/react-hooks'
import { GET_TEAM_MEMBERS } from 'operations/Auth'

import { Link } from 'react-router-dom'

import Card, { CardTitle } from 'components/Card'
import { PrimaryButton } from 'components/Button'
import { FormatPhone } from 'components/Format'
import Loader from 'components/Loader'

import { useTranslation } from 'react-i18next'

export default function Devices({ onSave }) {

    const { t } = useTranslation()

    const { error, loading, data } = useQuery(GET_TEAM_MEMBERS)
    // const [ errors, setErrors ] = useState({})

    if (error || loading) return <Loader />

    const { teamMembers } = data

    const serviceAdvisors = teamMembers.filter(teamMember => teamMember.userType === 'SERVICE_ADVISOR')
    const technicians = teamMembers.filter(teamMember => teamMember.userType === 'TECHNICIAN')

    return (
        <div className='bodyContainer' >
            <h1><Link to='/settings'>{ t('settings', 'Settings') }</Link><i className='material-icons'>chevron_right</i><span className='current'>{ t('teamMembers', 'Team members') }</span></h1>
 
            {
                (!teamMembers || teamMembers.length < 1) && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <div>
                            <h1 style={{ marginBottom: '6rem' }}>{ t('noTeamMembersYet', "Looks like you haven't added any team members to your account yet.") }</h1>
                            <center><PrimaryButton to='/settings/team-members/add-team-member'>{ t('addATeamMember', 'Add a team member') }</PrimaryButton></center>
                        </div>
                    </div>
                )
            }

            { serviceAdvisors && serviceAdvisors.length > 0 && <h2>{ t('serviceAdvisors', 'Service advisors') }</h2> }
            {
                serviceAdvisors && serviceAdvisors.map((teamMember) => <TeamMember key={ teamMember.id } data={ teamMember } />)
            }

            { technicians && technicians.length > 0 && <h2>{ t('technicians', 'Technicians') }</h2> }
            {
                technicians && technicians.map((teamMember) => <TeamMember key={ teamMember.id } data={ teamMember } />)
            }

            { teamMembers && teamMembers.length > 0 && <PrimaryButton to='/settings/team-members/add-team-member'>{ t('addATeamMember', 'Add a team member') }</PrimaryButton> }

        </div>
    )

}

function TeamMember({ data }) {

    const { t } = useTranslation()

    return (
        <Card
            style={{
                display: 'grid',
                gridTemplateColumns: `
                    minMax(40px, 200px)
                    minMax(40px, 200px)
                    minMax(40px, 200px)
                    auto
                `,
                gridGap: '1rem'
            }}
        >
            <CardTitle title={ t('name', 'Name') }>
                { data.firstName } { data.lastName }
            </CardTitle>
            <CardTitle title={ t('email', 'Email')}>
                { data.email || t('noEmailProvided', 'No email provided')}
            </CardTitle>
            <CardTitle title={ t('mobileNumber', 'Mobile number') }>
                { data.mobileNumber ? <FormatPhone>{ data.mobileNumber }</FormatPhone> : t('noMobileNumberProvided', 'No mobile number provided') }
            </CardTitle>
        </Card>
    )
}
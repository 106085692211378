import React, { useState, useEffect, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { v4 as uuid } from 'uuid'
import axios from 'axios'

import { Img } from 'components/Tueri'

import './upload.scss'

export default function Upload({ onUploadComplete }) {

    const [uploads, setUploads] = useState([])
    
    useEffect(() => {
        if (uploads.length > 0) {
            if (uploads.every(upload => upload.uploadComplete)) {

                const uploadResults = []
                uploads.map(upload => uploadResults.push(upload.data))

                onUploadComplete && onUploadComplete(uploadResults)
            }
        }
        else {
            onUploadComplete && onUploadComplete(false)
        }
        // eslint-disable-next-line
    }, [uploads])

    const onDrop = useCallback(acceptedFiles => {
        
        acceptedFiles.map(file => {

            onUploadComplete && onUploadComplete(false)

            const upload = {
                file,
                key: uuid(),
                uploadComplete: false,
                data: {}
            }

            return setUploads(uploads => [
                ...uploads,
                upload
            ])
            
        })
        // eslint-disable-next-line
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    function handleComplete(key, data) {
        setUploads(uploads => {
            uploads.map((upload) => {
                if (upload.key === key) {
                    upload.uploadComplete = true
                    upload.data = data
                }
                return null
            })
            return [...uploads]
        })
    }

    return (
        <>
            <div {...getRootProps()} className='upload__dropzone'>
                <input {...getInputProps()} />
                {
                    isDragActive ? (
                        <p>&nbsp;<br/>Drop files here<br/>&nbsp;</p>
                    ) : (
                        <p>Drop files here<br/>or<br/>click to browse files</p>
                    )
                }
            </div>
            {
                uploads && uploads.length > 0 ? (
                    <div className='fileList__container'>
                        {
                            uploads.map((upload, i) => (
                                <ListItem 
                                    key={upload.key} 
                                    file={ upload } 
                                    // onDelete={ () => handleDelete(upload.key) } 
                                    // disabled={ disabled } 
                                    // claimKey={ claimKey } 
                                    onItemUploadComplete={ (data) => handleComplete(upload.key, data) } 
                                />
                            ))
                        }
                    </div>
                ) : null
            }
        </>
    )

}

function ListItem({ file, onItemUploadComplete }) {

    const [uploadResponse, setUploadResponse] = useState({})
    const [progress, setProgress] = useState(0)
    const [upload, setUpload] = useState({})

    useEffect(() => {

        setUpload(file)

        const data = new FormData()
        data.append('upload', file.file, file.file.name)
        // data.append('claimKey', claimKey)

        axios({
            method: 'POST',
            url: process.env.NODE_ENV === 'development' ? 'http://localhost:4200/upload' : 'https://autoserviceapi.dsmedia.ca/upload',
            data,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: ({ loaded, total }) => {
                setProgress(loaded / total * 100)
            }
        })
        .then(response => {
            const data = response.data[0]
            setUploadResponse(data)
            onItemUploadComplete(data)
        })
        .catch(err => {
            setUpload(upload => {
                upload.uploadError = 'Error'
                return upload
            })
        })
        // eslint-disable-next-line
    }, [])

    if (!upload.file) return null

    const [progressOne, progressTwo] = calculateProgress(progress)

    const thumbnailOptions = {
        w: 48,
        h: 48
    }

    if (uploadResponse.mimetype === 'application/pdf') {
        thumbnailOptions.fm = 'jpg'
    }

    return (
        <div className='fileList__fileContainer'>
            <div className='fileList__image' style={{ width: '48px' }}>
                {
                    uploadResponse && uploadResponse.key ? (
                        <Img src={`https://cdn.tueri.io/68719476737/autoservice/${ uploadResponse.key.replace('uploads/', '') }`} options={ thumbnailOptions } alt={ 'Upload' } />
                    ) : (
                        <div className='fileList__progressContainer'>
                            <div className='fileList__dialContainer fileList__dialContainer--container1'>
                                <div className='fileList__wedge' style={{ transform: `rotate(-${progressOne}deg)` }} />
                            </div>
                            <div className='fileList__dialContainer fileList__dialContainer--container2'>
                                <div className='fileList__wedge' style={{ transform: `rotate(-${progressTwo}deg)` }}/>
                            </div>
                        </div>
                    )
                }
                {/* <Img src={ file.url } ext='pdf' options={{ fm: 'jpg' }} alt='Invoice' /> */}
            </div>
            <div className='fileList__filename'>{ upload.file.name }</div>           
        </div>
    )

}

function calculateProgress(progress) {
    const firstProgress = progress * 2 <= 100 ? progress * 2 : 100
    const secondProgress = progress - 50 > 0 ? progress - 50 : 0 
    return [
        180 - (firstProgress * 1.8),
        180 - (secondProgress * 2 * 1.8)
    ]
}


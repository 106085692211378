import React, { createContext, useContext, useState, useEffect } from 'react'

const FormContext = createContext()

export default function Form({ children, onChange, onSubmit, init = {}, className }) {
    
    const [ isSubmitted, setSubmitted ] = useState(false)
    const [inputs, setInputs] = useState(init)

    useEffect(() => {
        if (onChange) {
            onChange({ inputs, isSubmitted })
        }
    })

    function handleSetState(input, id) {

        if (id > -1) {
            setInputs(inputs => ({
                ...inputs,
                [Object.keys(input)[0]]: { ...inputs[Object.keys(input)[0]], [id]: input[Object.keys(input)[0]] }
            }))
        }

        else {
            setInputs(inputs => ({
                ...inputs,
                ...input
            }))
        }
    }

    function handleUnsetInput(input) {
        setInputs(inputs => {
            const newInputs = inputs
            delete newInputs[input]
            return ({
                ...newInputs
            })
        })
    }

    function handleFormReset() {
        setInputs(init)
    }

    function handleEnableSubmit() {
        setSubmitted(false)
    }

    function rawInputs() {
        const rawInputs = {}
        Object.keys(inputs).map((input) => {
            return rawInputs[input] = inputs[input] ? inputs[input].rawValue : ''
        })
        return rawInputs
    }

    return(
        <FormContext.Provider
            value={{
                inputs,
                rawInputs: rawInputs(),
                setInput: (input, id) => handleSetState(input, id),
                unsetInput: handleUnsetInput,
                isSubmitted,
            }}
        >
            
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    setSubmitted(true)

                    if (onSubmit) {
                        onSubmit({inputs, rawInputs: rawInputs(), isSubmitted, resetForm: handleFormReset, enableSubmit: handleEnableSubmit})
                    }
                }}
                className={className}
            >
                <FormContext.Consumer>
                    { children }
                </FormContext.Consumer>
            </form>
        </FormContext.Provider>
    )

}

function useForm() {
    return useContext(FormContext)
}

export {
    useForm,
    FormContext
}
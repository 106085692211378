import React, { createContext, useContext, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_TEAM_MEMBERS } from 'operations/Auth'

const AssignmentContext = createContext()

export default function AssignmentProvider({ children }) {

    const [ assignmentFilter, setAssignmentFilter ] = useState([])

    const { data } = useQuery(GET_TEAM_MEMBERS, { variables: { userType: 'TECHNICIAN' }, fetchPolicy: 'cache-and-network' })

    return (
        <AssignmentContext.Provider
            value={{
                teamMembers: data && data.teamMembers ? data.teamMembers : [],
                assignmentFilter,
                setAssignmentFilter: (e) => setAssignmentFilter(e)
            }}
        >
            { children }
        </AssignmentContext.Provider>
    )

}

export const useAssignments = () => useContext(AssignmentContext)
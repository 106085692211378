import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'
import Routes from './Routes'

import { ApolloProvider } from '@apollo/react-hooks'
import client from './Apollo'

import SubscriptionProvider from 'hooks/subscription'
import RefreshProvider from 'hooks/refresh'
import TueriProvider from 'components/Tueri'
import ModalProvider from 'hooks/modal'
import ThemeProvider from 'hooks/theme'
import DeviceProvider from 'hooks/device'
import AuthenticationProvider from 'hooks/authentication'
import AppointmentProvider from 'hooks/appointments'
import AssignmentProvider from 'hooks/assignments'

import Loader from 'components/Loader'

import './ExtraTranslations'

// import { EmailVerificationBanner } from 'components/Banner'

import 'styles/default.scss'

import * as Sentry from '@sentry/browser';

import './i18n'

// import { Settings } from 'luxon'

// Settings.defaultLocale ='fr-CA'

Sentry.init({
  dsn: "https://e99d8a090e264a1387b5254aebeaceed@o398316.ingest.sentry.io/5253834",
  // release: 'autoservice-installer-gui@' + process.env.REACT_APP_COMMIT_REF
  environment: process.env.NODE_ENV
});

ReactDOM.render(
  // <React.StrictMode>
    <RefreshProvider>
      <ApolloProvider client={ client }>
          <ThemeProvider>
            <DeviceProvider>
              <AuthenticationProvider>
                <AppointmentProvider>
                  <AssignmentProvider>
                    <Suspense fallback={ <Loader /> }>
                      <SubscriptionProvider>
                        {/* <EmailVerificationBanner /> */}
                        <TueriProvider replacements={[
                          ['https://autoservice.nyc3.cdn.digitaloceanspaces.com/uploads/', 'https://cdn.tueri.io/68719476738/autoservice/'],
                          ['https://autoservice.nyc3.digitaloceanspaces.com/uploads/', 'https://cdn.tueri.io/68719476738/autoservice/']
                        ]}>
                          <ModalProvider>
                            <Routes />
                          </ModalProvider>
                        </TueriProvider>
                      </SubscriptionProvider>
                    </Suspense>
                    </AssignmentProvider>
                  </AppointmentProvider>
              </AuthenticationProvider>
            </DeviceProvider>
          </ThemeProvider>
      </ApolloProvider>
    </RefreshProvider>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { PrimaryButton } from 'components/Button'

import { useTranslation } from 'react-i18next'

import Loader from 'components/Loader'

export default function ActiveInstallerCheck({ children }) {

    const { t } = useTranslation()

    const { error, loading, data } = useQuery(gql`
        query ActiveInstallerCheck {
            installer {
                id
                isActive
                messagingNumber
            }
        }
    `)

    if (error) return 'Error getting installer activation status.'

    if (loading) return <Loader/>

    if (!data.installer.isActive) return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', background: '#ffffff' }}>
            <div>
                <h1 style={{ marginBottom: '6rem' }}>{ t(`Please complete your business information before creating appointments.`) }</h1>
                <center><PrimaryButton to='/onboarding'>{ t('Complete my business information') }</PrimaryButton></center>
            </div>
        </div>
    )

    if (!data.installer.messagingNumber) return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', background: '#ffffff' }}>
            <div>
                <h1 style={{ marginBottom: '6rem' }}>{ t('noMessagingNumberChosen', "Looks like you don't have a local messaging number yet.") }</h1>
                <center><PrimaryButton to='/chat'>{ t('chooseYourNumber', 'Choose your messaging phone number') }</PrimaryButton></center>
            </div>
        </div>
    )

    return children
}
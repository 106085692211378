import gql from 'graphql-tag'

export const AUTHENTICATE = gql`
    mutation Authenticate($payload: AuthPayload!) {
        authenticate(payload: $payload) {
            accessToken
            refreshToken
        }
    }
`
export const REFRESH_TOKEN = gql`
    mutation RefreshToken($payload: RefreshTokenPayload!) {
        refreshToken(payload: $payload) {
            accessToken
        }
    }
`

export const GENERATE_CONNECT_STATE = gql`
    query GenerateConnectState {
        generateConnectState
    }
`

export const CREATE_INSTALLER_ACCOUNT = gql`
    mutation CreateInstallerAccount($payload: CreateInstallerAccountPayload!) {
        createInstallerAccount(payload: $payload) {
            accessToken
            refreshToken
        }
    }
`

export const ME = gql`
    query Me {
        me {
            id
            email
            mobileNumber
            mobileCountryCode
            firstName
            lastName
            userType
            language
            isVerified
            emailOptIn
        }
    }
`

export const GET_DEVICE_LOGINS = gql`
    query GetDeviceLogins($device: String!) {
        deviceLogins(device: $device) {
            name
            deviceType
            users {
                id
                firstName
                lastName
                hasPinCode
                userType
            }
        }
    }
`

export const CREATE_TEAM_MEMBER = gql`
    mutation CreateTeamMember($payload: CreateTeamMemberPayload!) {
        createTeamMember(payload: $payload) {
            id
            firstName
            lastName
        }
    }
`

export const GET_TEAM_MEMBERS = gql`
    query GetTeamMembers($userType: String) {
        teamMembers(userType: $userType) {
            id
            firstName
            lastName
            email
            mobileNumber
            mobileCountryCode
            userType
            isVerified
        }
    }
`

export const AUTHENTICATE_TEAM_MEMBER = gql`
    mutation AuthenticateTeamMember($payload: AuthTeamMemberPayload!) {
        authenticateTeamMember(payload: $payload) {
            accessToken
            refreshToken
        }
    }
`

export const CHECK_IS_VERIFIED = gql`
    query CheckIsVerified {
        me {
            id
            userType
            email
            isVerified
            createdAt
        }
    }
`

export const RESEND_VERIFICATION_EMAIL = gql`
    mutation ResendVerificationEmail {
        resendVerificationEmail
    }
`

export const GET_MY_DAY = gql`
    query GetMyDay($startDate: String $endDate: String) {
        myDay {
            appointments(startDate: $startDate, endDate: $endDate) {
                total
                complete
            }
        }
    }
`
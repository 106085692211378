import React from 'react'

import './info.scss'

import { useTranslation } from 'react-i18next'

export default function Info({ href, style }) {

    const { t } = useTranslation()

    return <a target="_blank" style={ style } rel='noreferrer noopener' className='info' href={href} title={ t('learnMore', 'Learn more') }>?</a>

}
import React from 'react'

import { Img } from 'components/Tueri'
import { FormatDate } from 'components/Format'

import './issue.scss'

export default function Issue({ issue }) {
    return (
        <div className='issue__container'>
            <div className='issue__status'>
                    { issue.createdAt && <>Created on <FormatDate format='MMM D, h:mm A' date={ issue.createdAt } /> { issue.updatedAt > issue.createdAt && ' • edited' }</> }
                </div>
            <div className='issue__text'>{ issue.issue }</div>
            {
                issue.files && issue.files.map(file => {
                    const thumbnailOptions = { h: 60 }

                    if (file.mimetype === 'application/pdf') {
                        thumbnailOptions.fm = 'jpg'
                    }
                    
                    return <div key={`${ issue.id }-${ file.etag }`} className='issue__file'><Img src={`https://cdn.tueri.io/68719476737/autoservice/${ file.key.replace('uploads/', '') }`} options={ thumbnailOptions } alt={ 'test' } /></div>
                })
            }
        </div>
    )
}
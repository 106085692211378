import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'

import { Link, useHistory, useParams } from 'react-router-dom'

import './schedule.scss'
import { useQuery } from '@apollo/react-hooks'

import { GET_APPOINTMENTS } from 'operations/Appointment'
import Loader from 'components/Loader'
import Error from 'components/Error'

import Button, { SecondaryButton } from 'components/Button'

import { useTranslation } from 'react-i18next'

import { DateTime } from 'luxon'

import { useInterval } from 'hooks/interval'
import TechnicianFilter from 'components/TechnicianFilter'
import Initials from 'components/Initials'
import { useAssignments } from 'hooks/assignments'

const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
const HOUR_HEIGHT = 72


// const today = moment().format('YYMMDD')

const today = DateTime.local()

function getStartDate(view) {
    if (view === 'day') {
        return today.startOf('day')
    }
    if (view === 'week') {
        return today.startOf('week').startOf('day')
    }
}

function getEndDate(view, startDate) {
    if (view === 'day') {
        return startDate.endOf('day')
    }
    if (view === 'week') {
        return startDate.endOf('week').endOf('day')
    }
}

function getToday(view) {
    if (view === 'day') {
        return today.startOf('day')
    }
    if (view === 'week') {
        return today.startOf('week').startOf('day')
    }
}

function incrementDate(view, date) {
    if (view === 'day') {
        return date.plus({ day: 1 })
    }
    if (view === 'week') {
        return date.plus({ week: 1 })
    }
}

function decrementDate(view, date) {
    if (view === 'day') {
        return date.minus({ day: 1 })
    }
    if (view === 'week') {
        return date.minus({ week: 1 })
    }
}

export default function Week({ onStyleChange }) {

    const { t } = useTranslation()

    const { view } = useParams()

    const scrollRef = useRef(null)

    const { teamMembers, assignmentFilter } = useAssignments()

    const [ startDate, setStartDate ] = useState(() => getStartDate(view))

    const [ currentTime, setCurrentTime ] = useState(DateTime.local())

    useInterval(() => {
        setCurrentTime(DateTime.local())
    }, 60000)

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = DateTime.local().minus({ hour: 1 }).hour * HOUR_HEIGHT
        }
        // eslint-disable-next-line
    }, [scrollRef.current])

    useEffect(() => {
        setStartDate(getStartDate(view))
    }, [view])

    const endDate = getEndDate(view, startDate)

    const { error, loading, data } = useQuery(GET_APPOINTMENTS, { variables: {
            range: { start: startDate, end: endDate},
            filter: { status: { ne: 'APPOINTMENT_REQUEST' } }
    } })

    if (error) return <Error error={ error } />

    if (loading) return <Loader />

    const assignments = [{
        id: 'unassigned',
        firstName: t('Unassigned'),
        lastName: ''
    }]

    teamMembers.map(teamMember => assignments.push(teamMember))

    return (
        <div className={`bodyContainer--schedule schedule--${ view }`}>
            <div className='schedule__mainHeader'>

                <SecondaryButton className='scheduleButton' onClick={() => setStartDate(() => getToday(view))}>{ t('todayButton', 'Today') }</SecondaryButton>
                
                <Button className='schedule__previous' onClick={() => setStartDate(date => decrementDate(view, date))}><i className='material-icons'>navigate_before</i></Button>
                <Button className='schedule__next' onClick={() => setStartDate(date => incrementDate(view, date))}><i className='material-icons'>navigate_next</i></Button>

                {/* TODO: TRANSLATE - date */}
                { startDate.toFormat('MMM d') } - { endDate.toFormat('MMM d, yyyy') }

                <StyleSwitcher type={view === 'day' ? t(`dayButton`, 'Day') : t(`calendarButton`, 'Week')} onStyleChange={onStyleChange} />
                <TechnicianFilter />
                {/* <CalendarSwitcher calendarStyle={ calendarStyle } onChange={(style) => setCalendarStyle(style)} /> */}
            </div>

            <div className='schedule__container schedule__container--week'>

                <div className='schedule__header'>

                    <div className='schedule__hours' style={{ width: '60px' }} />
                    <div style={{
                        gridTemplateColumns: `repeat(${ assignments.length }, 1fr)`,
                        display: 'grid',
                        width: '100%'
                    }}>
                        {
                            assignments.map((assignment, i) => {
                                return (
                                    <div key={i} className={`schedule__day`}>
                                        <div className='schedule__day__name'>{ assignment.firstName + ' ' + assignment.lastName }</div>
                                        <div className='schedule__day__date'><Initials name={ assignment.firstName + ' ' + assignment.lastName } /></div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

                <div className='schedule__bodyContainer' ref={ scrollRef }>
                    <div className='schedule__body' style={{ height: `${ HOUR_HEIGHT * 24 }px` }}>

                        <div className='schedule__hours'>
                            {/* TODO: TRANSLATE - hours */}
                            {
                                hours.map((hour, i) => <div key={i} className={`schedule__hourLine`} style={{ top: `${ hour * HOUR_HEIGHT }px` }}><div className='hour'>{ hour < 12 ? hour === 0 ? '' : `${hour} AM` : `${hour > 12 ? hour - 12 : hour} PM` }</div><div className='line'/></div>)
                            }
                        </div>

                        <div className='schedule__currentTime' style={{ top: `${ ((currentTime.toFormat('HH') * HOUR_HEIGHT) + ((HOUR_HEIGHT / 60) * currentTime.toFormat('mm'))) }px` }}/>

                        <div className='schedule__body__days'
                            style={{
                                gridTemplateColumns: `repeat(${ assignments.length }, 1fr)`,
                                display: 'grid'
                            }}
                        >
                            {
                                assignments.map((assignment, i) => {

                                    const appointments = data.appointments.filter((appointment) => {
                                        console.log(assignment.id, appointment.assignments)
                                        if (appointment.id !== 'unassigned') {
                                            const checkAssignments = appointment.assignments.filter(appointmentAssignment => appointmentAssignment.id === assignment.id )
                                            if (checkAssignments && checkAssignments.length > 0) return true
                                        }
                                        if (assignment.id === 'unassigned' && appointment.assignments.length === 0){
                                            return true
                                        } 
                                        return false
                                    })

                                    let appointmentGroup = 0
                                    const groupedAppointments = [[]]
                                    appointments.map((appointment, i) => {
                                        const previousAppointment = i === 0 ? {} : appointments[i - 1]
                                        previousAppointment.appointmentEndAt = previousAppointment.appointmentEndAt || DateTime.fromMillis(Number(previousAppointment.appointmentStartAt)).plus({ minutes: 29 })
                                        appointment.appointmentEndAt = appointment.appointmentEndAt || DateTime.fromMillis(Number(appointment.appointmentStartAt)).plus({ minutes: 29 })
                                        if ((Number(appointment.appointmentStartAt) <= Number(previousAppointment.appointmentEndAt) && Number(appointment.appointmentEndAt) >= Number(previousAppointment.appointmentStartAt)) || i === 0) {}
                                        else {
                                            appointmentGroup += 1
                                            groupedAppointments[appointmentGroup] = []
                                        }
                                        return groupedAppointments[appointmentGroup].push(appointment)
                                    })

                                    return (
                                        <div key={i} className={`schedule__day`}>
                                            {
                                                groupedAppointments.map((appointments, i) => {

                                                    const numberOfAppointments = groupedAppointments[i].length
                                                    const width = 100 / numberOfAppointments

                                                    return appointments.map((appointment, i) => {

                                                        const appointmentTime = moment(Number(appointment.appointmentStartAt))
                                                        const hour = appointmentTime.format('H')
                                                        const minute = appointmentTime.format('m')

                                                        let isAssigned = true

                                                        if (assignmentFilter && assignmentFilter.length > 0) {
                                                            if (appointment.assignments && appointment.assignments.length > 0) {
                                                                isAssigned = false
                                                                appointment.assignments.map(assignment => {
                                                                    if (assignmentFilter.includes(assignment.id)) isAssigned = true
                                                                    return null
                                                                })
                                                            }
                                                        }

                                                        if (!isAssigned) return null

                                                        return (
                                                            <Link className={`appointment appointment--${ appointment.confirmationStatus }`} key={ appointment.id } to={`/appointment/${ appointment.id }`} style={{ position: 'absolute', top: `${ (hour * HOUR_HEIGHT) + ((minute / 60) * HOUR_HEIGHT) }px`, width: `${ width }%`, left: `${ width * i }%`, maxHeight: `${ HOUR_HEIGHT / 2 }px` }}
                                                                title={`${ appointment.vehicle.year } ${ appointment.vehicle.make } ${ appointment.vehicle.model }: ${ appointment.customer.fullName } @ ${ appointmentTime.format('h:mm a') }`}
                                                            >
                                                                <div style={{ maxWidth: '100%', maxHeight: `${ (HOUR_HEIGHT / 2) - 10 }px`, overflow: 'hidden' }}>
                                                                    <strong>{ appointment.vehicle.year } { appointment.vehicle.make } { appointment.vehicle.model }</strong> ({ appointment.customer.fullName })
                                                                    <br/>
                                                                    { appointmentTime.format('h:mm a') }
                                                                </div>
                                                                {/* <div itle={ appointment.confirmationStatus === 'CONFIRMED' ? t('appointmentConfirmed', 'Appointment confirmed!') : appointment.confirmationStatus === 'CANCELLED' ? t('appointmentCancelled', 'Appointment has been cancelled.') : t('appointmentNotConfirmed', 'Appointment has not been confirmed.') } className={`appointmentConfirmationStatus ${ `appointmentConfirmationStatus--${ appointment.confirmationStatus.toLowerCase() }` }`}><i className='material-icons'>{ appointment.confirmationStatus === 'CONFIRMED' ? 'check_circle' : appointment.confirmationStatus === 'CANCELLED' ? 'remove_circle' : 'check_circle_outline' }</i></div> */}
                                                            </Link>
                                                        )
                                                    })

                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
                
            </div>
        </div>
    )
}

export function StyleSwitcher({ type }) {

    const { t, i18n } = useTranslation()

    const history = useHistory()

    const [open, setOpen] = useState()

    const lang = i18n.language.substring(0,2)

    useEffect(() => {
        function onKeyUp(e) {
            if ((lang === 'en' && e.key === 's') || (lang === 'fr' && e.key === 'h')) history.push('/schedule')
            if ((lang === 'en' && e.key === 'd') || (lang === 'fr' && e.key === 'j')) history.push('/schedule/day')
            if ((lang === 'en' && e.key === 'w') || (lang === 'fr' && e.key === 's')) history.push('/schedule/week')
        }

        window.addEventListener('keyup', onKeyUp)
        return () => window.removeEventListener('keyup', onKeyUp)
    }, [lang, history])

    return (
        <div className='boardStyleContainer'>
            <div className='boardStyleSwitcher' onClick={() => setOpen(open => !open)}>
                { type }
                <i className='material-icons'>arrow_drop_down</i>
                <ul className='boardStyleSwitcher__styles' style={{ display: open ? 'block' : 'none' }}>
                    <li className={`${ type === 'Schedule' ? 'active' : '' }`}><Link to='/schedule'>{ t('scheduleButton', 'Schedule') } <span>{ t('scheduleButtonKeyboardShortcut', 'S') }</span></Link></li>
                    <li className={`${ type === 'Day' ? 'active' : '' }`}><Link to='/schedule/day'>{ t('dayButton', 'Day') } <span>{ t('dayButtonKeyboardShortcut', 'D') }</span></Link></li>
                    <li className={`${ type === 'Week' ? 'active' : '' }`}><Link to='/schedule/week'>{ t('weekButton', 'Week') } <span>{ t('weekButtonKeyboardShortcut', 'W') }</span></Link></li>
                </ul>
            </div>
        </div>
    )
}


// function CalendarSwitcher({ calendarStyle, onChange }) {

//     const { t } = useTranslation()
    
//     const CALENDAR_STYLES = [
//         {
//             key: 'day',
//             title: t('calendarStyleDay', 'Day')
//         },
//         {
//             key: 'week',
//             title: t('calendarStyleWeek', 'Week')
//         },
//     ]

//     return (
//         <div className='calendarSwitcherContainer'>
//             {
//                 CALENDAR_STYLES.map((style) => <button key={style.key} type='button' className={`calendarStyleSwitcher ${ calendarStyle === style.key ? 'calendarStyleSwitcher--active' : '' }`} onClick={() => onChange(style.key)}>{ style.title }</button>)
//             }
//         </div>
//     )

// }
import React from 'react'

import { useQuery } from '@apollo/react-hooks'
import { GET_STRIPE_ACCOUNT } from 'operations/Installer'
import { GENERATE_CONNECT_STATE } from 'operations/Auth'

import Card, { CardTitle } from 'components/Card'

import { Link } from 'react-router-dom'

import buttonImage from './blue-on-light@3x.png'
import Loader from 'components/Loader'

import { useTranslation } from 'react-i18next'

// import mcc from './mcc.json'

export default function Payments() {

    const { t } = useTranslation()

    const { error, loading, data } = useQuery(GET_STRIPE_ACCOUNT)

    if (error || loading) return <Loader />

    const { stripeAccount } = data

    // console.log(stripeAccount)

    return (
        <div className='bodyContainer'>
            <h1><Link to='/settings'>{ t('settings', 'Settings') }</Link><i className='material-icons'>chevron_right</i><span className='current'>{ t('payments', 'Payments') }</span></h1>

            {
                stripeAccount ? (
                    <>
                    <h2>Connected Stripe Account</h2>
                        <Card
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto auto auto'
                            }}
                        >
                            <CardTitle title={ t('accountId', 'Account ID') }>{ stripeAccount.id }</CardTitle>
                            <CardTitle title={ t('businessName', 'Business Name') }>{ stripeAccount.business_profile.name }</CardTitle>
                            <CardTitle title={ t('currency', 'Currency') }>{ stripeAccount.default_currency.toUpperCase() }</CardTitle>
                            {/* <CardTitle title='Merchant Category'>{ mcc[stripeAccount.business_profile.mcc] }</CardTitle> */}
                            {/* { stripeAccount.settings.dashboard.display_name } */}
                        </Card>
                    </>
                ) : (
                    <ConnectStripe />
                )
            }

            
        </div>
    )

}

function ConnectStripe() {

    const { t } = useTranslation()

    const { error, loading, data } = useQuery(GENERATE_CONNECT_STATE)

    if (error || loading) return <Loader />
    return (
        <>
            { t('connectStripeMessage', 'Connect a Stripe account and start accepting credit card payments.') }
            <p><a href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_H8QDx2COZ9Mgp8GYMaoPm24ktlsJRPnv&scope=read_write&state=${ data.generateConnectState }`}><img style={{ width: '180px' }} src={buttonImage} alt='Connect with Stripe' /></a></p>
        </>
    )
}
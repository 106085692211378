import React, { useState, useRef } from 'react'
import './contentBox.scss'
import ReactMarkdown from 'react-markdown'
import { FormatDate } from 'components/Format'
import { useEffect } from 'react'

export default function ContentBox({ children, buttonText, emptyEditText, onSave, meta, clearOnSave }) {

    const textareaEl = useRef(null)

    const [ editing, setEditing ] = useState(false)
    const [ state, setState ] = useState(children)

    useEffect(() => {
        if (editing && textareaEl && textareaEl.current) {            
            textareaEl.current.focus()
        }
    }, [editing])

    function handleCtrlEnter(e) {
        if (e.ctrlKey && e.keyCode === 13) {
            handleSave()
        }
    }

    function handleSave() {
        setEditing(false)
        onSave && onSave(state)
        if (clearOnSave) setState()
    }

    function handleStartEditing() {
        setEditing(true)
    }    

    function handleCancel() {
        if (clearOnSave) {
            setState('')
        }
        else {
            setState(children) 
        }
        setEditing(false)
    }

    const textboxStyle = editing ? {
        left: 0,
        right: '-1rem',
        paddingRight: '1rem',
        minHeight: '87px'
    } : {
        left: '-99999px',
        height: 0,
        padding: 0
    }

    return (
        <div className='contentBox__container'>
            { meta && (
                <div className='contentBox__status'>
                    { meta.createdAt && <>Created on <FormatDate format='MMM D, h:mm A' date={ meta.createdAt } /> { meta.updatedAt > meta.createdAt && ' • edited' }</> }
                </div>
            )}            
            <div className='contentBox'>
                {
                    !editing && children && (
                        <button type='button' className='contentBox__editButton' onClick={ () => handleStartEditing() }><i className='material-icons'>create</i></button>
                    )
                }
                {
                    editing ? (
                        <>
                            { !state && <div className='contentBox__placeholder'>{ `${ emptyEditText } [CTRL + Enter] to save` }</div> }
                            {/* <textarea ref={ textareaEl } className='contentBox__textarea' cols='2' rows='10' onChange={ (e) => setState(e.target.value) } onKeyDown={ (e) => handleCtrlEnter(e) } value={ state }></textarea> */}
                        </>
                    ) : (
                        <>{ state ? (
                            <ReactMarkdown source={ state } />
                        ) : <button className='contentBox__addButton' type='button' onClick={ () => handleStartEditing() }>{ buttonText }</button> }</>
                    )
                }  
                <textarea ref={ textareaEl } style={textboxStyle} className='contentBox__textarea' cols='2' rows='10' onChange={ (e) => setState(e.target.value) } onKeyDown={ (e) => handleCtrlEnter(e) } value={ state }></textarea>          
            </div>
            {
                editing && (
                    <div className='contentBox__actions'>
                        <button className='contentBox__cancelButton' onClick={() => handleCancel()}>Cancel</button> or <button className='contentBox__saveButton' onClick={() => handleSave()}>Save</button>
                    </div>
                )
            }
        </div>
    )
}
import React from 'react'

import './release.scss'

import moment from 'moment'

export const VERSION = '1.8.0'

export default function ReleaseNotes() {

    localStorage.setItem('version', VERSION)

    return (
        <div className='bodyContainer'>
            <h1>Release notes</h1>

            <Version v='1.0.1' date='2020-06-03'>

                <EmbedVideo id='c0qYNMNNR_A'/>

                <Features>
                    <p>
                        Today we released a new <strong>weekly schedule view</strong> so you can see your appointments at a glance.
                    </p>
                    <p>
                        You can access the new <strong>week view</strong> by clicking on the (Day <i className='material-icons'>arrow_drop_down</i>) button in the top-right corner of your daily schedule.
                    </p>
                    <ul>
                        <li>See your weekly appointments at a glance</li>
                        <li>Navigate forward and back one week at a time using <i className='material-icons'>navigate_before</i><i className='material-icons'>navigate_next</i></li>
                        <li>Get back to the current week instantly by clicking (Today)</li>
                    </ul>
                </Features>

                <Bugs>
                    <p>
                        Fixed mobile phone views.
                    </p>
                </Bugs>

            </Version>

        </div>
    )
}

function Version({ children, v, date }) {
    return (
        <div className='release'>
            <h2>Version { v }</h2>
            <h3>{ moment(date).format('LL') }</h3>
            {
                children
            }
        </div>
    )
}

function Features({ children }) {
    return (
        <>
            <h2>Features</h2>
            { children }
        </>
    )
}

function Bugs({ children }) {
    return (
        <>
            <h2>Bugfixes</h2>
            { children }
        </>
    )
}

function EmbedVideo({ id }) {
    return (
        <div style={{ maxWidth:'500px' }}>
        <div className='videoContainer'>
            <iframe title={id} width='560' height='315' src={`https://www.youtube.com/embed/${ id }?rel=0`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        </div>
    )
}
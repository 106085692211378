import React, { createContext, useContext, useState } from 'react'

const BoardContext = createContext()

export default function BoardProvider({ children }) {

    const [selectedCard, setSelectedCard] = useState()

    return (
        <BoardContext.Provider
            value={{
                selectedCard,
                setSelectedCard: (e) => setSelectedCard(e)
            }}
        >
            { children }
        </BoardContext.Provider>
    )

}

export const useBoard = () => useContext(BoardContext)
import React, { useState } from 'react'

import { useMutation } from '@apollo/react-hooks'
import { CREATE_TEAM_MEMBER, GET_TEAM_MEMBERS } from 'operations/Auth'

import Form, { Fieldset, Field, Submit } from 'components/Form'

import { Link, useHistory } from 'react-router-dom'
import validator from 'validator'

import { useTranslation } from 'react-i18next'


export default function Devices() {

    const { t } = useTranslation()

    const history = useHistory()

    const [ linkDevice ] = useMutation(CREATE_TEAM_MEMBER, { refetchQueries: [{ query: GET_TEAM_MEMBERS }] })
    const [ errors, setErrors ] = useState({})


    return (
        <>
            <div className='modal__header'>
                <Link to='/settings/team-members' className='modal__closeButton'><i className='material-icons'>close</i></Link>
            </div>
            <div className='bodyContainer' >
            
                <h1>{ t('addATeamMember', 'Add a team member') }</h1>

                <Form
                    onSubmit={ async ({ rawInputs, enableSubmit }) => {

                        const formErrors = {}
                        if (validator.isEmpty(rawInputs.userType)) formErrors.userType = true
                        if (validator.isEmpty(rawInputs.firstName)) formErrors.firstName = true
                        if (validator.isEmpty(rawInputs.lastName)) formErrors.lastName = true

                        if (Object.keys(formErrors).length > 0) {
                            enableSubmit()
                            return setErrors(formErrors)
                        }

                        try {
                            await linkDevice({
                                variables: {
                                    payload: {
                                        userType: rawInputs.userType,
                                        firstName: rawInputs.firstName,
                                        lastName: rawInputs.lastName
                                    }
                                }
                            })
                            enableSubmit()
                            history.replace('/settings/team-members')
                        }
                        catch(err) {
                            console.log(err)
                            enableSubmit()
                        }

                    }}
                >
                    {() => {
                        return (
                            <Fieldset legend={ t('teamMember', 'Team member') } style={{ maxWidth: '480px' }}>
                                <Field name='userType' label={ t('userType', 'User type') } type='select' options={[['SERVICE_ADVISOR', t('serviceAdvisor', 'Service advisor')], ['TECHNICIAN', t('technician', 'Technician')]]} required error={ errors.userType } errorMessage='What type of user is this?' />
                                <Field name='firstName' label={ t('firstName', 'First name') } required error={ errors.firstName } errorMessage={ t('teamMemberFirstNameError', 'Please enter the first name of this team member.') }/>
                                <Field name='lastName' label={ t('lastName', 'Last name') } required error={ errors.lastName } errorMessage={ t('teamMemberLastNameError', 'Please enter the last name of this team member.') } />
        
                                <Submit>{ t('addTeamMember', 'Add team member') }</Submit>
                            </Fieldset>
                        )
                    }}
                </Form>

            </div>
        </>
    )

}
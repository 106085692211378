import React, { useState } from 'react'

import { useMutation } from '@apollo/react-hooks'
import { LINK_DEVICE, GET_DEVICES } from 'operations/Device'

import Form, { Fieldset, Field, Submit } from 'components/Form'

import { Link, useParams, useHistory } from 'react-router-dom'
import validator from 'validator'
import AccessCode from 'components/AccessCode'

import DeviceDetector from 'device-detector-js'
import { useDevice } from 'hooks/device'

import { useTranslation } from 'react-i18next'

const location = window.location

const deviceDetector = new DeviceDetector()
const userAgent = navigator.userAgent
const deviceInfo = deviceDetector.parse(userAgent)

export default function Devices() {

    const { t } = useTranslation()

    const params = useParams()
    const history = useHistory()

    const { setDevice } = useDevice()

    const [ deviceId, setDeviceId ] = useState(() => {
        if (params.deviceId === 'link-device') return null
        if (params.deviceId === 'link-this-device') return params.deviceId
        return params.deviceId
    })

    const [ linkDevice ] = useMutation(LINK_DEVICE, { refetchQueries: [{ query: GET_DEVICES }] })
    const [ errors, setErrors ] = useState({})


    return (
        <>
            <div className='modal__header'>
                <Link to='/settings/devices' className='modal__closeButton'><i className='material-icons'>close</i></Link>
            </div>
            <div className='bodyContainer' >
            
                <h1>{ deviceId === 'link-this-device' ? t('linkThisDevice', 'Link this device') : t('linkADevice', 'Link a device') }</h1>                

                <Form
                    onSubmit={ async ({ rawInputs, enableSubmit }) => {

                        const formErrors = {}
                        if (validator.isEmpty(rawInputs.name || '')) formErrors.name = true
                        if (validator.isEmpty(rawInputs.deviceType || '')) formErrors.deviceType = true

                        if (Object.keys(formErrors).length > 0) {
                            enableSubmit()
                            return setErrors(formErrors)
                        }

                        try {
                            const { data } = await linkDevice({
                                variables: {
                                    payload: {
                                        deviceId,
                                        name: rawInputs.name,
                                        deviceType: rawInputs.deviceType,
                                        deviceInfo                                      
                                    }
                                }
                            })

                            if (data.device.deviceToken) {
                                localStorage.setItem('deviceToken', data.device.deviceToken)
                                setDevice(data.device.deviceToken)
                            }
                            history.replace('/settings/devices')
                        }
                        catch(err) {
                            console.log(err)
                            enableSubmit()
                        }

                    }}
                >
                    {() => {
                        return (
                            <Fieldset legend={ !deviceId ? <> { t('deviceLinkInstructions', 'On the device you want to link, open a web browser and go to') } <p><strong>{ location.hostname }/link</strong></p></> : ''}>
                                {
                                    !deviceId ? (
                                        <>
                                            <AccessCode onChange={ async (code) => {
                                                try {
                                                    const { data } = await linkDevice({ variables: { payload: { verificationCode: code } } }) 
                                                    setDeviceId(data.device.id)
                                                }
                                                catch(err) {
                                                    setErrors({ accessCode: true })
                                                }
                                            }}/>
                                            {
                                                errors.accessCode && <div style={{
                                                    color: 'red',
                                                    fontSize: '1.2rem',
                                                    marginTop: '1rem'
                                                }}>{ t('deviceCodeNotFoundError', 'Device code not found. Please try again.') }</div>
                                            }
                                        </>
                                    ) : (
                                        <>
                                            
                                            <Field name='name' label={ t('deviceNickname', 'Device nickname') } helper={ t('deviceNicknameHelper', `Something descriptive to help you remember later.`) } required error={ errors.name } errorMessage={ t('deviceNicknameError', 'Please name this device.') }/>
                                            <Field name='deviceType' label={ t('deviceType', 'Device type') } type='select' options={[['INSTALLER', t('administrator', 'Administrator')], ['SERVICE_ADVISOR', t('serviceAdvisor', 'Service Advisor')], ['TECHNICIAN', t('technician', 'Technician')]]} required error={ errors.deviceType } errorMessage={ t('deviceTypeError', 'What type of user will use this device?') } />
                    
                                            <Submit>{ t('linkThisDevice', 'Link this device') }</Submit>
                                        </>
                                    )
                                }
                            </Fieldset>
                        )
                    }}
                </Form>

            </div>
        </>
    )

}
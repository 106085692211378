import React, { useState } from 'react'

import './appointment.scss'
import CustomerLookup from './CustomerLookup'
import NewCustomer from './NewCustomer'
import VehicleLookup from './VehicleLookup'
import AppointmentDetails from './AppointmentDetails'
import BookAppointment, { EditAppointmentTime } from './BookAppointment'

import Customer from './Customer'
import Vehicle from './Vehicle'
import CustomerPage from 'pages/Board/Customer'
import Actions from 'pages/Board/Actions'
import { useParams, useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { GET_APPOINTMENT_BY_ID, DESTROY_APPOINTMENT, GET_APPOINTMENTS_BOARD } from 'operations/Appointment'
import { GET_CUSTOMER_APPOINTMENTS } from 'operations/Customer'
import Button, { CancelButton, DestroyButton } from 'components/Button'

import { FormatDate } from 'components/Format'
import Loader from 'components/Loader'

import { useTranslation } from 'react-i18next'

import { DateTime } from 'luxon'

// Step definitions
const LOOKUP_CUSTOMER = 'LOOKUP_CUSTOMER'
const NEW_CUSTOMER = 'NEW_CUSTOMER'
const LOOKUP_VEHICLE = 'LOOKUP_VEHICLE'
const APPOINTMENT_DETAILS = 'APPOINTMENT_DETAILS'
const BOOK_APPOINTMENT = 'BOOK_APPOINTMENT'
const COMPLETE = 'COMPLETE'

export default function Appointment() {
    const { appointmentId, customerId } = useParams()

    const { error, loading, data } = useQuery(GET_APPOINTMENT_BY_ID, { variables: { id: appointmentId }, skip: !appointmentId })

    if (error || loading) return <Loader />

    // console.log(error, loading, data)

    if (appointmentId) {
        const { appointment } = data

        if (!appointment) return `Sorry, we couldn't find that appointment.`

        if (appointment.status === 'APPOINTMENT_REQUEST') return <NewAppointment appointment={ appointment } />
        return <ExistingAppointment appointment={ appointment } />

    }

    if (customerId) {
        return <NewAppointment appointment={{ customer: { id: customerId } }} />
    }

    return <NewAppointment />

}

export function NewAppointment({ appointment }) {

    const { t } = useTranslation()

    const history = useHistory()

    const [ step, setStep ] = useState(appointment ? appointment.vehicle ? APPOINTMENT_DETAILS : LOOKUP_VEHICLE : LOOKUP_CUSTOMER)
    const [ customer, setCustomer ] = useState(appointment && appointment.customer )
    const [ vehicle, setVehicle ] = useState(appointment && appointment.vehicle)
    const [ details, setDetails ] = useState()
    // const [ date, setDate ] = useState()

    // const history = useHistory()
    // console.log(appointment)

    return (
        <div className={`appointment__container ${ ![LOOKUP_CUSTOMER, NEW_CUSTOMER].includes(step) ? 'appointment__container--expandedSidebar' : '' }`}>

            <div className='appointment__sidebarContainer'>
                {
                    ![LOOKUP_CUSTOMER, NEW_CUSTOMER, LOOKUP_VEHICLE].includes(step) && <Vehicle vehicle={ vehicle } />
                }
                {
                    ![LOOKUP_CUSTOMER, NEW_CUSTOMER].includes(step) && <Customer customer={ customer } />
                }
            </div>

            <div className='appointment__bodyContainer'>

                <div className='appointment__header'>
                    <Button onClick={() => history.goBack()} className='appointment__closeButton'><i className='material-icons'>close</i></Button>
                </div>

                <div className='appointment__body'>

                    <h1>{ t(`Add an appointment`) }</h1>

                    { step === LOOKUP_CUSTOMER && (
                        <CustomerLookup onChange={ (customer) => {
                            setCustomer(customer)
                            if (customer.id) {
                                return setStep(LOOKUP_VEHICLE)
                            }
                            return setStep(NEW_CUSTOMER) 
                        }} /> 
                    )}

                    {
                        // TODO: need to save customer
                        step === NEW_CUSTOMER && <NewCustomer onChange={(customer) => {
                            setCustomer(customer)
                            return setStep(LOOKUP_VEHICLE)
                        }} mobileNumber={ customer } />
                    }

                    {
                        step === LOOKUP_VEHICLE && <VehicleLookup customerId={ customer.id } onChange={ (vehicle) => {
                            setVehicle(vehicle)
                            return setStep(APPOINTMENT_DETAILS)
                        }} />
                    }

                    {
                        step === APPOINTMENT_DETAILS && <AppointmentDetails onChange={ (details) => {
                            setDetails(details)
                            return setStep(BOOK_APPOINTMENT)
                        }} />
                    }

                    {
                        step === BOOK_APPOINTMENT && <BookAppointment appointmentId={ appointment && appointment.id } customer={ customer } vehicle={ vehicle } details={ details } onSelect={ (apppointment) => {
                            // setDate(date)
                            // return setStep(COMPLETE)
                            // console.log(appointment)
                            // return history.replace(`/appointment/${ appointment.id }`)
                        }} />
                    }

                    {
                        step === COMPLETE && <>Appointment has been booked!</>
                    }

                </div>

            </div>

        </div>
    )

}

export function ExistingAppointment({ appointment }){

    const { t } = useTranslation()

    const history = useHistory()

    const [ isEditTime, setIsEditTime ] = useState(false)

    const [ isAppointmentDestroy, setAppointmentDestroy ] = useState(false)

    const [ destroyAppointment ] = useMutation(DESTROY_APPOINTMENT, { refetchQueries: [{ query: GET_APPOINTMENTS_BOARD, variables: { startDate: DateTime.local().startOf('day'), endDate: DateTime.local().endOf('day') } }, { query: GET_CUSTOMER_APPOINTMENTS, variables: { id: appointment.customer.id } }] })

    if (isAppointmentDestroy) return (
        <div className='appointment__container'>
            <div className='appointment__bodyContainer'>
                <div className='appointment__header'>
                    <Button onClick={() => setAppointmentDestroy(false)} className='appointment__closeButton'><i className='material-icons'>close</i></Button>
                </div>
                <div className='appointment__body'>

                    <h1>{ t(`Delete appointment`) }</h1>

                    <h2>Are you sure you want to destroy this appointment?</h2>
                    
                    <div><DestroyButton onClick={async () => {
                        
                        const { data } = await destroyAppointment(
                            {
                                variables: {
                                    payload: {
                                        id: appointment.id
                                    }
                                }
                            }
                        )

                        if (data.destroyAppointment === 'SUCCESS') history.replace('/schedule')

                    }}>Destroy appointment</DestroyButton><CancelButton or onClick={() => setAppointmentDestroy(false)}>Cancel</CancelButton></div>

                </div>
            </div>
        </div>
    )

    if (isEditTime) return (
        <div className={`appointment__container`}>
            <div className='appointment__bodyContainer'>

                <div className='appointment__header'>
                    {
                        [
                            'APPOINTMENT_REQUEST',
                            'ARRIVAL_PICKUP',
                            'ARRIVAL_DROP'
                        ].includes(appointment.status) && <DestroyButton style={{ marginTop: '1rem' }} onClick={() => setAppointmentDestroy(true)}>Delete appointment</DestroyButton>
                    }
                    <Button onClick={() => setIsEditTime(false)} className='appointment__closeButton'><i className='material-icons'>close</i></Button>
                </div>

                <div className='appointment__body'>

                    <h1>{ t(`Change appointment time`) }</h1>


                    <EditAppointmentTime appointmentId={ appointment && appointment.id } onSelect={ (apppointment) => {
                            // setDate(date)
                            // return setStep(COMPLETE)
                            // console.log(appointment)
                            // return history.replace(`/appointment/${ appointment.id }`)
                        }} />

                </div>

            </div>
        </div>
    )

    return (
        <div className={`appointment__container appointment__container--expandedSidebar`}>

            <div className='appointment__sidebarContainer'>
                <Vehicle vehicle={ appointment.vehicle } />
                <Customer customer={ appointment.customer } />
            </div>

            <div className='appointment__bodyContainer appointment__bodyContainer--top'>

                <div className='appointment__header'>
                    <h1><FormatDate date={ appointment.appointmentStartAt } />
                        { ['ARRIVAL_PICKUP', 'ARRIVAL_DROP'].includes(appointment.status) && <button type='button' onClick={() => setIsEditTime(true)} className='appointmentTimeEditButton'><i className='material-icons'>edit</i></button> }
                    </h1>
                    <Button onClick={() => history.goBack()} className='appointment__closeButton'><i className='material-icons'>close</i></Button>
                    <Actions appointment={ appointment } />
                </div>

                <div className='appointment__bodyScrollContainer'>

                    <CustomerPage appointment={ appointment } />

                </div>

            </div>

        </div>
    )
}
import gql from 'graphql-tag'

export const ADD_SURVEY_RESULT = gql`
    mutation AddSurveyResult($payload: AddSurveyResultPayload!) {
        addSurveyResult(payload: $payload) {
            id
            surveyType
            action
            completedBy
            medium
            score
            comment
        }
    }
`

export const GET_NPS_SURVEY_RESULTS = gql`
    query GetNPSSurveyResults($payload: StatsPayload!) {
        npsSurveyResults(payload: $payload) {
            globalScore
            score
            promoters
            passives
            detractors
            isEnoughResults
        }
    }
`
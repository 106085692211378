import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_CUSTOMER_BY_ID, GET_CUSTOMER_SURVEYS } from 'operations/Customer'

import { Link, NavLink, useHistory, Switch, Route } from 'react-router-dom'
import { useParams } from 'react-router-dom'

// import { CtaButton } from 'components/Button'
import Loader from 'components/Loader'
import { GET_CUSTOMER_APPOINTMENTS } from 'operations/Customer'

import moment from 'moment'
import Card, { CardTitle, CardDate } from 'components/Card'
import Label from 'components/Label'

import CustomerLookup from '../Appointment/CustomerLookup'
import NewCustomer from '../Appointment/NewCustomer'
import NoData from 'components/NoData'
import { SubNav } from 'components/SubNav'

import { PrimaryButton, SecondaryButton } from 'components/Button'

import { ModalButton } from 'components/Modal'
import Aside, { AsideDetail } from 'components/Aside'
import NPSRating from 'components/NPSRating'
import Info from 'components/Info'

import './customer.scss'
import { FormatCurrency, FormatDate, FormatPhone } from 'components/Format'

import { useTranslation } from 'react-i18next'

import Vehicles from './Vehicles'

// import { DateTime } from 'luxon'

export default function Customer({ newCustomer }) {

    const { t } = useTranslation()

    const { customerId } = useParams()

    const { error, loading, data } = useQuery(GET_CUSTOMER_BY_ID, { variables: { id: customerId }, skip: newCustomer })

    if (error || loading) return <Loader />

    if (newCustomer) return <AddACustomer />

    const { customerById: customer } = data

    return (
        <div className='bodyContainer bodyContainer--fullWidth'>
            <h1><Link to='/customers'>{ t('customers', 'Customers') }</Link><i className='material-icons'>chevron_right</i><span className='current'>{ customer.fullName }</span></h1>

            {/* <CtaButton>New appointment</CtaButton> */}

            <SubNav>
                <NavLink exact to={`/customers/${ customerId }`}>{ t('appointments', 'Appointments') }</NavLink>
                <NavLink to={`/customers/${ customerId }/vehicles`}>{ t(`Vehicles`) }</NavLink>
                <NavLink to={`/customers/${ customerId }/feedback`}>{ t('feedback', 'Feedback') }</NavLink>
            </SubNav>

            <div className='customerGrid'>

                <div>
                    <Switch>
                        <Route exact path={`/customers/${ customerId }`} render={ () => <CustomerAppointments customerId={ customerId } /> } />
                        <Route path={`/customers/${ customerId }/vehicles`} render={ () => <Vehicles customerId={ customerId } /> } />
                        <Route path={`/customers/${ customerId }/feedback`} render={ () => <CustomerSurveys customerId={ customerId } /> } />
                    </Switch>
                </div>
                
                <Aside>

                    <SecondaryButton to={`/customers/${ customerId }/profile`}>Edit customer profile</SecondaryButton>

                    <AsideDetail title={ t('mobileNumber', 'Mobile number') }>
                        <FormatPhone>{ customer.user.mobileNumber }</FormatPhone>
                    </AsideDetail>

                    <AsideDetail title={ t('accountRevenue', 'Account revenue') }>
                        $<FormatCurrency>{ customer.revenue }</FormatCurrency>
                    </AsideDetail>

                    <AsideDetail title={
                        <>{ t('accountNPSRating', 'Account NPS Rating') } <Info href='https://support.napaconnect.ca/net-promoter-score' /></>
                    }>
                        <NPSRating>{ customer.npsRating }</NPSRating>
                    </AsideDetail>

                </Aside>

            </div>


        </div>
    )

}

function CustomerAppointments({ customerId }) {

    const { t } = useTranslation()

    const { error, loading, data } = useQuery(GET_CUSTOMER_APPOINTMENTS, { variables: { id: customerId } })

    if (error || loading) return <Loader />

    const { appointments } = data.customer

    const now = moment().unix()
    const nowYear = moment().year()

    if (appointments.length < 1) return (
        <NoData>
            <p>Looks like this customer hasn't booked any appointments yet.</p>
            <PrimaryButton to={`/appointment/customer/${ customerId }`}>Book an appointment</PrimaryButton>
        </NoData>
    )

    return (
        <>

            {
                appointments.map((appointment, key) => {

                    let tense = 'future'
                    if (appointment.appointmentStartAt && moment(Number(appointment.appointmentStartAt)).unix() < now) tense = 'past'
                    if (appointment.appointmentStartAt && moment(Number(appointment.appointmentStartAt)).unix() <= now && !['APPOINTMENT_REQUEST', 'DEPARTURE_PICKUP_COMPLETE', 'DEPARTURE_DROP_COMPLETE'].includes(appointment.status)) tense = 'present'
            
                    let yearTense = tense
                    if (yearTense === 'future' && appointment.appointmentStartAt && moment(Number(appointment.appointmentStartAt)).year() === nowYear) yearTense = 'present'
            
                    const { vehicle } = appointment
            
                    return (
                        <div key={ appointment.id }>
                            <Card
                                to={`/appointment/${ appointment.id }`}
                                style={{
                                    display: 'grid',
                                    gridGap: '1rem',
                                    gridTemplateColumns: '60px 1fr 1fr 1fr 1fr 80px'
                                }}
                            >
                                <CardDate date={ appointment.appointmentStartAt } tense={ tense } />
                                <CardTitle title={ t('Vehicle') }>
                                    { vehicle ? `${ vehicle.year } ${ vehicle.make } ${ vehicle.model }` : 'Not specified' }
                                </CardTitle>
                                <CardTitle title={ t('Appointment') }>
                                    { appointment.appointmentStartAt ? moment(Number(appointment.appointmentStartAt)).format('MMM D @ h:mma') : 'Not specified' }
                                </CardTitle>
                                <CardTitle title={ t('Revenue') }>
                                    { appointment.invoice && appointment.invoice.totalPaid ? <>$<FormatCurrency>{ appointment.invoice.totalPaid }</FormatCurrency></> : t('N/A') }
                                </CardTitle>
                                <CardTitle title={ t('Status') }>
                                    <Label>{ appointment.status }</Label>
                                </CardTitle>
                                <CardTitle title={ t('NPS Rating') } style={{ textAlign: 'center' }}>
                                    <NPSRating>{ appointment.survey ? appointment.survey.score : null }</NPSRating>
                                </CardTitle>
                            </Card>
            
                            {
                                (!appointments[key + 1] || moment(Number(appointment.appointmentStartAt)).format('YYYY') !== moment(Number(appointments[key + 1].appointmentStartAt)).format('YYYY')) && (
                                    <>
                                        <div className={`timeline timeline--${tense}`}/>
                                        <div className={`timelineYear timelineYear--${ yearTense }`}><div className='timelineYearLine' /><div className={`year`}>{ appointment.appointmentStartAt ? moment(Number(appointment.appointmentStartAt)).format('YYYY') : t('N/A') }</div></div>
                                        { appointments[key + 1] && <div className={`timeline timeline--${ yearTense }`}/> }
                                    </>
                                )
                            }
            
                            <div className={`timeline timeline--${tense}`}/>
            
                        </div>
                    )
            
                })
            }

            <ModalButton to={`/appointment/customer/${ customerId }`} hoverText='Add an appointment' />
        </>
    )

}

// Step definitions
const LOOKUP_CUSTOMER = 'LOOKUP_CUSTOMER'
const NEW_CUSTOMER = 'NEW_CUSTOMER'

function AddACustomer() {

    const [ step, setStep ] = useState(LOOKUP_CUSTOMER)
    const [ customer, setCustomer ] = useState()

    const history = useHistory()

    return (
        <div className='appointment__container'>
            <div className='appointment__bodyContainer'>

                <div className='appointment__header'>
                    <Link to='/customers' className='appointment__closeButton'><i className='material-icons'>close</i></Link>
                </div>

                <div className='appointment__body'>

                    <h1>Add a customer</h1>

                    { step === LOOKUP_CUSTOMER && (
                        <CustomerLookup onChange={ (customer) => {
                            setCustomer(customer)
                            if (customer.id) {
                                return history.replace(`/customers/${ customer.id }`)
                            }
                            return setStep(NEW_CUSTOMER) 
                        }} /> 
                    )}

                    {
                        // TODO: need to save customer
                        step === NEW_CUSTOMER && <NewCustomer onChange={(customer) => {
                            return history.replace(`/customers/${ customer.id }`)
                        }} mobileNumber={ customer } />
                    }

                </div>

            </div>
        </div>
    )
}

function CustomerSurveys({ customerId }) {

    const { error, loading, data } = useQuery(GET_CUSTOMER_SURVEYS, { variables: { id: customerId } })

    if (error) return 'Error loading customer feedback'

    if (loading) return <Loader />

    const { customerSurveys } = data

    if (customerSurveys.length < 1) return <NoData>This customer has not provided any feedback yet.</NoData>

    return customerSurveys.map(survey => {
        return (
            <React.Fragment key={ survey.id }>
                <Card style={{ display: 'grid', gridTemplateColumns: '50px auto' }} to={`/appointment/${ survey.appointmentId }`}>
                    <NPSRating>{ survey.score }</NPSRating>
                    <CardTitle title='Comment'>
                        { survey.comment }
                    </CardTitle>
                </Card>
                <div style={{ fontSize: '1.1rem', color: 'rgba(0,0,0,0.5)' }}>
                    <span><strong>Responed:</strong> <FormatDate date={ survey.createdAt } /> </span>
                    <span><strong>Type:</strong> { survey.surveyType } </span>
                    <span><strong>Trigger:</strong> { survey.action } </span>
                    <span><strong>Method:</strong> { survey.medium }</span>
                </div>
            </React.Fragment>
        )
    })

}
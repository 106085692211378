import React, { useState, useEffect, useRef } from 'react'
import './accessCode.sass'

export default function AccessCode({ onChange, userId }) {

    const el = useRef(null)

    const [ code, setCode ] = useState('')
    // const history = useHistory()

    // const { setAuthenticated } = useAuthentication()
    // const [ accessCodeAuthentication ] = useMutation(ACCESS_CODE_AUTHENTICATION)
    // const [ verificationAuth ] = useMutation(VERIFICATION_AUTH)

    const [ isFocused, setIsFocused ] = useState(false)

    useEffect(() => {
        if (el.current) {
            el.current.focus()
        }
    }, [])

    useEffect(() => {

        if (code.length === 6) {

            (async () => {

                // history.push('/')
                onChange && onChange(code)

            })()

        }
        // eslint-disable-next-line
    }, [ code, userId ])

    const codeParts = code.split('')

    const parts = [0, 1, 2, 3, 4, 5]

    return (
        <div className='accessCodeContainer'>                
            <label htmlFor='f_accessCodeInput' className='accessCodeLabel'>
                <div className='accessCodeHelperText' >Enter the code displayed on the device</div>
                {
                    parts.map(part => (
                        <AccessCodePart key={ part } isFocused={ isFocused } active={ part === codeParts.length }>{ codeParts[part] }</AccessCodePart>
                    ))
                }
            </label>
            <input type='text' id='f_accessCodeInput' className='accessCodeInput' ref={ el } name='accessCode' autoComplete='off' onChange={ (e) => setCode(e.target.value.toUpperCase()) } maxLength='6' onFocus={ () => setIsFocused(true) } onBlur={ () => setIsFocused(false) } />
        </div>
    )

}

function AccessCodePart({ children, isFocused, active }) {

    return <div className={`accessCodePart ${ active && isFocused ? 'accessCodePart--focus' : '' }`}>{ active && isFocused ? <div className={`accessCodePartBlink`}/> : children }</div>
}
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react'
import jwtDecode from 'jwt-decode' 
import moment from 'moment'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { REFRESH_TOKEN } from '../operations/Auth'
// import { useGlobalState } from '../hooks/useGlobalState'
import * as Sentry from '@sentry/browser'

const AuthenticationContext = createContext()

function AuthenticationProvider({ init = false, children }) {

    const client = useApolloClient()
    const [isLoadedAccess, setIsLoadedAccess] = useState(false)
    const [isLoadedRefresh, setIsLoadedRefresh] = useState(false)
    const [isAuthenticated, setAuthenticated] = useState(init)
    const [user, setUser] = useState({})
    const [tokenExpires, setTokenExpiry] = useState()

    if (user) {
        Sentry.configureScope(scope => {
            scope.setUser({
                id: user.sub,
                userType: user.userType
            })
        })
    }

    const logout = useCallback(
        () => {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            setTokenExpiry()
            setAuthenticated(false)
            setUser({})
            client.clearStore()
        },
        [client]
    )

    const [ refresh ] = useMutation(REFRESH_TOKEN)

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken') || false

        if (accessToken) {
            const decoded = jwtDecode(accessToken)

            const { knd, exp } = decoded
            if (knd === 'ACCESS' && exp > moment().unix()) {
                setTokenExpiry(exp)
                setUser(decoded)
                setAuthenticated(true)
            }
            setIsLoadedAccess(true)
        }
        if (!accessToken) setIsLoadedAccess(true)

    }, [isAuthenticated, isLoadedAccess])

    useEffect(() => {

        if (isLoadedAccess && isAuthenticated) {
            setIsLoadedRefresh(true)
        }

        if (isLoadedAccess && !isAuthenticated) {
            const refreshToken = localStorage.getItem('refreshToken') || false
            if (refreshToken) {

                refresh({
                    variables: {
                        payload: {
                            refreshToken
                        }
                    }
                })
                .then(({data}) => {
                    const { accessToken } = data.refreshToken
                    const decoded = jwtDecode(accessToken)
                    const { exp } = decoded
                    localStorage.setItem('accessToken', accessToken)
                    setTokenExpiry(exp)
                    setUser(decoded)
                    setAuthenticated(true)
                    setIsLoadedRefresh(true)
                })
                .catch(err => {
                    logout()
                    setIsLoadedRefresh(true)
                })

            }

            if (!refreshToken) {
                setIsLoadedRefresh(true)
            }
        }

    }, [ isAuthenticated, isLoadedAccess, isLoadedRefresh, refresh, logout ])

    useEffect(() => {
        const refreshTimer = setInterval(() => {
            if (tokenExpires < moment().add(5, 'minutes').unix()) {

                const refreshToken = localStorage.getItem('refreshToken') || null

                if (refreshToken) {

                    refresh({
                        variables: {
                            payload: {
                                refreshToken
                            }
                        }
                    })
                    .then(({data}) => {
                        const { accessToken } = data.refreshToken
                        const { exp } = jwtDecode(accessToken)
                        localStorage.setItem('accessToken', accessToken)
                        setTokenExpiry(exp)
                    })
                    .catch(err => {
                        logout()
                    })

                }

                else {
                    logout()
                }
                
            }
        }, 60000)

        return () => {
            clearInterval(refreshTimer)
        }

    }, [tokenExpires, refresh, logout])

    if (!isLoadedAccess || !isLoadedRefresh) return null


    return(
        <AuthenticationContext.Provider
            value={{
                isAuthenticated,
                user,
                setAuthenticated: (...args) => setAuthenticated(...args),
                logout
            }}
        >
            { children }
        </AuthenticationContext.Provider>
    )

}

function useAuthentication() {
    return useContext(AuthenticationContext)
}

export {
    useAuthentication
}

export default AuthenticationProvider
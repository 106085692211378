import React from 'react'

import './npsRating.scss'

export default function({ children }) {
    if (!children && children !== 0) return <span className='npsRating npsRating--na'>N/A</span>
    let customer = 'promoter'
    if (children <= 8) customer = 'passive'
    if (children <=6) customer = 'detractor'
    return <span className={`npsRating npsRating--${ customer }`}>{ children }</span>
}
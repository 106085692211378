import React from 'react'

import { useQuery } from '@apollo/react-hooks'
import { GET_CUSTOMER_BY_ID } from 'operations/Customer'
import { FormatPhone } from 'components/Format'
import Loader from 'components/Loader'

export default function Customer({ customer: { id } }) {

    const { error, loading, data } = useQuery(GET_CUSTOMER_BY_ID, { variables: { id } })

    if (error || loading) return <Loader />

    const customer = data.customerById

    const address = customer.addresses && customer.addresses.length > 0 ? <>{ customer.addresses[0].address } { customer.addresses[0].addressLine2 }<br/>{ customer.addresses[0].city }, { customer.addresses[0].province } { customer.addresses[0].postal }</> : null

    return (
        <div className='appointment__customerContainer'>
            <div className='appointment__customerName'>{ customer.fullName }</div>
            <div className='appointment__customerPhone'><FormatPhone>{ customer.user.mobileNumber }</FormatPhone></div>
            { address &&  <div className='appointment__customerAddress'>{ address }</div> }
        </div>
    )
}
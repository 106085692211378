import React, { useState, useEffect } from 'react'

import { useQuery } from '@apollo/react-hooks'
import { LINK_DEVICE_REQUEST } from 'operations/Device'
import DeviceDetector from 'device-detector-js'

import './linkDevice.scss'
import { Link, useHistory } from 'react-router-dom'
import { useDevice } from 'hooks/device'
// import jwtDecode from 'jwt-decode'

import { useTranslation } from 'react-i18next'

const deviceDetector = new DeviceDetector()
const userAgent = navigator.userAgent
const deviceInfo = deviceDetector.parse(userAgent)

export default function LinkDevice() {

    const { t } = useTranslation()

    return (
        <div className='login__container'>

            {/* <div className='login__sidebar'>

                <i className='material-icons'>directions_car</i>

            </div> */}

            <div className='login__body'>

                <div>

                    <div className='logo'><i className='material-icons'>directions_car</i></div>

                    { t('enterCodeToLink', 'Enter the code below to link this device to your NAPA Connect account.') }

                    <div className='linkDevice__code'><Generator /></div>

                    <Waiting />

                    <div className='loginSignupSwitch'><Link to='/'>{ t('cancel', 'Cancel') }</Link></div>

                </div>
                
            </div>

        </div>
    )

}

const CHARS = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
]

function Generator() {

    const code = []
    const length = 6
    for (let i = 0; i < length; i++) {
        if (length / 2 === i && length > 4) code.push(' ')
        code.push(CHARS[Math.floor(Math.random() * CHARS.length)])
    }

    const codeString = code.reduce((accumulator, char) => accumulator += char.toString().trim())

    return (
        <div className='code'>
            { code.map((char, i) => isNaN(char) ? <React.Fragment key={i}>{ char }</React.Fragment> : <span key={i}>{ char }</span>) }
            <LinkDeviceRequest code={ codeString } />
        </div>
    )
}

function Waiting() {

    const { t } = useTranslation()

    return (
        <div className='loadingContainer'>
            <div className='spinner'><i className='material-icons'>autorenew</i></div>
            <div>{ t('waitingForLink', 'Waiting for link') }</div>
        </div>
    )

}

function LinkDeviceRequest({ code, onActivate }) {

    // const { setAuthenticated } = useAuthentication()
    const history = useHistory()
    const [ deviceId, setDeviceId ] = useState(null)

    const [ skip, setSkip ] = useState(true)

    const { setDevice } = useDevice()

    // Make sure to load uuid from cache
    useEffect(() => {
        const deviceCache = localStorage.getItem('deviceId') || false
        if (deviceCache) {
            setDeviceId(deviceCache)
            setSkip(false)
        }
        else {
            setSkip(false)
        }
    }, [])

    const { loading, error, data } = useQuery(LINK_DEVICE_REQUEST, { variables: { payload: { verificationCode: code, deviceId, deviceInfo } }, pollInterval: 5000, skip })

    useEffect(() => {

        if (!deviceId && data && data.linkDeviceRequest) {
            const deviceId = data.linkDeviceRequest.id
            localStorage.setItem('deviceId', deviceId)
            setDeviceId(deviceId)            
        }
        if (data && data.linkDeviceRequest && data.linkDeviceRequest.isActive) {
            localStorage.setItem('deviceToken', data.linkDeviceRequest.deviceToken)
            setDevice(data.linkDeviceRequest.deviceToken)
            history.replace('/')
        }
        // eslint-disable-next-line
    }, [data])

    if (loading) return null

    if (error) return `Error: ${error.message}`


    // if (data && data.linkDeviceRequest && data.linkDeviceRequest.accessToken) {
    //     localStorage.setItem('accessToken', data.linkDeviceRequest.accessToken)
    //     localStorage.setItem('refreshToken', data.linkDeviceRequest.refreshToken)
    //     // setAuthenticated(true)
    // }

    return null
}
import React, { useState } from 'react'
import Calendar from 'react-calendar'
import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { CREATE_APPOINTMENT, GET_APPOINTMENTS_BOARD, UPDATE_APPOINTMENT_TIME } from 'operations/Appointment'
import { GET_CUSTOMER_APPOINTMENTS } from 'operations/Customer'

import Card from 'components/Card'
import { useHistory } from 'react-router-dom'

import moment from 'moment'

import Button from 'components/Button'

import { DateTime } from 'luxon'

import { useTranslation } from 'react-i18next'

import Loader from 'components/Loader'

const MINUTES = {
    '00': '00',
    '25': '15',
    '50': '30',
    '75': '45',
}

function padDate(value) {
    return '00'.substring(0, 2 - value.toString().length) + value
}

function convertHour(hour) {
    if (hour < 1) return '12'
    if (hour > 12) return hour - 12
    return hour
}

function convertAmPm(hour) {
    return hour > 11 ? 'pm' : 'am'
}

function convertTime(time) {
    const parseTime = parseFloat(time).toFixed(2)
    const splitTime = parseTime.split('.')

    return convertHour(splitTime[0]) + ':' + MINUTES[splitTime[1].toString()] + convertAmPm(splitTime[0])

}

function getHour(time) {
    const parseTime = parseFloat(time).toFixed(2)
    const splitTime = parseTime.split('.')

    return padDate(splitTime[0])
}

function getMinute(time) {
    const parseTime = parseFloat(time).toFixed(2)
    const splitTime = parseTime.split('.')

    return padDate(MINUTES[splitTime[1].toString()])
}

export default function BookAppointment({ appointmentId, customer, vehicle, details }) {

    const { t } = useTranslation()

    const [ date, setDate ] = useState()
    const [ confirmKey, setConfirmKey ] = useState()

    const { error, loading, data } = useQuery(gql`
        query GetInstallerTimezone {
            installer {
                id
                timezone
            }
        }
    `)

    const [ createAppointment ] = useMutation(CREATE_APPOINTMENT, { refetchQueries: [{ query: GET_APPOINTMENTS_BOARD, variables: { startDate: DateTime.local().startOf('day'), endDate: DateTime.local().endOf('day') } }, { query: GET_CUSTOMER_APPOINTMENTS, variables: { id: customer.id } }] })
    const history = useHistory()

    
    if (loading) return <Loader />
    if (error || (data && !data.installer.timezone)) return 'Error fetching timezone'

    const timeButtons = []
    // full day is 0, 96
    for (let i = 24; i < 96; i++) {
        timeButtons.push(i / 4)
    }
    
    return (
        <Card className={`appointmentBooking__container ${ date ? 'appointmentBooking__container--dateIsSet' : '' }`}>
            {/* TODO: TRANSLATE - calendar region */}
            <Calendar
                className='calendar'
                maxDetail='month'
                minDate={ new Date() }
                onChange={ (date) => setDate(date) }
                calendarType='US'
            />

            <div className='timeSelector__container' >
                {
                    date && <div className='timeSelector__selectedDate'>{ moment(date).format('LL') } <Button onClick={() => setDate()} className='timeSelector__clearDateButton'><i className='material-icons'>close</i></Button></div>
                }
            {
                date && timeButtons.map((time, i) => {

                    return (
                        <div key={ i } className={`timeButton__container ${ confirmKey === i ? 'timeButton--confirm' : '' }`}>
                            <div className='timeButton__buttonContainer'>
                                <div className='timeButton__padding'>
                                    <button type='button' className={`timeButton`} onClick={ () => setConfirmKey(i) }>{ convertTime(time) }</button>
                                </div>
                            </div>
                            <div className='timeButton__confirmButtonContainer'>
                                <div className='timeButton__padding'>
                                    <button type='button' className='timeButton__confirm' onClick={ async () => {

                                        const appointmentStartAt = DateTime.fromFormat(`${ date.getFullYear() }-${ padDate(date.getMonth() + 1) }-${ padDate(date.getDate()) }T${ padDate(getHour(time)) }:${ padDate(getMinute(time)) }:00 ${ data.installer.timezone }`, "yyyy-MM-dd'T'HH:mm:ss z", { setZone: true })

                                        try {
                                            const { data } = await createAppointment({
                                                variables: {
                                                    payload: {
                                                        customerId: customer.id,
                                                        appointmentId: appointmentId,
                                                        vehicleId: vehicle.id,
                                                        appointmentStartAt,
                                                        description: details.description,
                                                        isPickup: details.isPickup,
                                                        messageTime: appointmentStartAt.toLocaleString({ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit', timeZoneName: 'short', weekday: 'short' })
                                                    }
                                                }
                                            })
                                            // onSelect && onSelect(data.createAppointment)
                                            return history.replace(`/appointment/${ data.createAppointment.id }`)
                                        }
                                        catch (err) {
                                            console.log(err)
                                        }

                                        
                                    }}>{t('Confirm')}</button>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            </div>

        </Card>
    )

}

export function EditAppointmentTime({ appointmentId }) {

    const { t } = useTranslation()

    const [ date, setDate ] = useState()
    const [ confirmKey, setConfirmKey ] = useState()

    const { error, loading, data } = useQuery(gql`
        query GetInstallerTimezone {
            installer {
                id
                timezone
            }
        }
    `)

    // const [ createAppointment ] = useMutation(CREATE_APPOINTMENT, { refetchQueries: [{ query: GET_APPOINTMENTS_BOARD, variables: { startDate: DateTime.local().startOf('day'), endDate: DateTime.local().endOf('day') } }, { query: GET_CUSTOMER_APPOINTMENTS, variables: { id: customer.id } }] })

    const [ updateAppointmentTime ] = useMutation(UPDATE_APPOINTMENT_TIME, { refetchQueries: [{ query: GET_APPOINTMENTS_BOARD, variables: { startDate: DateTime.local().startOf('day'), endDate: DateTime.local().endOf('day') } }] })
    const history = useHistory()

    
    if (loading) return <Loader />
    if (error || (data && !data.installer.timezone)) return 'Error fetching timezone'

    const timeButtons = []
    // full day is 0, 96
    for (let i = 24; i < 96; i++) {
        timeButtons.push(i / 4)
    }

    return (
        <Card className={`appointmentBooking__container ${ date ? 'appointmentBooking__container--dateIsSet' : '' }`}>
            {/* TODO: TRANSLATE - calendar region */}
            <Calendar
                className='calendar'
                maxDetail='month'
                minDate={ new Date() }
                onChange={ (date) => setDate(date) }
                calendarType='US'
            />

            <div className='timeSelector__container' >
                {
                    date && <div className='timeSelector__selectedDate'>{ moment(date).format('LL') } <Button onClick={() => setDate()} className='timeSelector__clearDateButton'><i className='material-icons'>close</i></Button></div>
                }
            {
                date && timeButtons.map((time, i) => {

                    return (
                        <div key={ i } className={`timeButton__container ${ confirmKey === i ? 'timeButton--confirm' : '' }`}>
                            <div className='timeButton__buttonContainer'>
                                <div className='timeButton__padding'>
                                    <button type='button' className={`timeButton`} onClick={ () => setConfirmKey(i) }>{ convertTime(time) }</button>
                                </div>
                            </div>
                            <div className='timeButton__confirmButtonContainer'>
                                <div className='timeButton__padding'>
                                    <button type='button' className='timeButton__confirm' onClick={ async () => {

                                        const appointmentStartAt = DateTime.fromFormat(`${ date.getFullYear() }-${ padDate(date.getMonth() + 1) }-${ padDate(date.getDate()) }T${ padDate(getHour(time)) }:${ padDate(getMinute(time)) }:00 ${ data.installer.timezone }`, "yyyy-MM-dd'T'HH:mm:ss z", { setZone: true })

                                        try {
                                            await updateAppointmentTime({
                                                variables: {
                                                    payload: {
                                                        appointmentId: appointmentId,
                                                        appointmentStartAt,
                                                        messageTime: appointmentStartAt.toLocaleString({ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit', timeZoneName: 'short', weekday: 'short' })
                                                    }
                                                }
                                            })
                                            // onSelect && onSelect(data.createAppointment)
                                            return history.replace(`/appointment/${ appointmentId }`)
                                        }
                                        catch (err) {
                                            console.log(err)
                                        }

                                        
                                    }}>{t('Confirm')}</button>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            </div>

        </Card>
    )

}
import React, { useState } from 'react'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_INSTALLER, UPDATE_INSTALLER } from 'operations/Installer'

import Form, { Fieldset, Field, Submit } from 'components/Form'

import { Link } from 'react-router-dom'
import validator from 'validator'
import Loader from 'components/Loader'

import { DateTime } from 'luxon'

import { useTranslation } from 'react-i18next'

export default function HourlyRate({ onboarding, onSave }) {

    const { t, i18n } = useTranslation()

    const { error, loading, data } = useQuery(GET_INSTALLER)
    const [ updateInstaller ] = useMutation(UPDATE_INSTALLER, { refetchQueries: [ { query: GET_INSTALLER } ] })
    const [ errors, setErrors ] = useState({})

    if (error || loading) return <Loader />

    const Element = onboarding ? React.Fragment : 'div'

    return (
        <Element { ...(Element === 'div' && { className: 'bodyContainer' }) }>
            { !onboarding && <h1><Link to='/settings'>{ t('settings', 'Settings') }</Link><i className='material-icons'>chevron_right</i><span className='current'>{ t('locale', 'Locale') }</span></h1> }
            <Form
                onSubmit={ async ({ rawInputs, enableSubmit }) => {

                    const formErrors = {}
                    if (validator.isEmpty(rawInputs.language)) formErrors.language = true
                    if (validator.isEmpty(rawInputs.timezone)) formErrors.timezone = true

                    if (Object.keys(formErrors).length > 0) {
                        enableSubmit()
                        return setErrors(formErrors)
                    }

                    try {
                        await updateInstaller({
                            variables: {
                                payload: {
                                    language: rawInputs.language,
                                    timezone: rawInputs.timezone
                                }
                            }
                        })
                        enableSubmit()
                        onSave && onSave()
                    }
                    catch(err) {
                        console.log(err)
                        enableSubmit()
                    }
                }}
            >
                {() => (
                    <>
                        <Fieldset legend={ t('languageTitle', 'Language') } style={{ maxWidth: '480px' }}>
                            <Field 
                                name='language' 
                                label={ t('primaryBusinessLanguage', 'Primary Business Language') } 
                                type='select' 
                                options={[['en-CA', t('languageCountryEnglish', 'English (Canada)')], ['fr-CA', t('languageCountryFrench', 'French (Canada)')]]} 
                                defaultValue={ data.installer.language || (i18n.language ? i18n.language + '-CA' : '') } 
                                required 
                                error={ errors.language } 
                                errorMessage={ t('languageSelectionError', 'What language is primarily used at your business?') } 
                            />
                        </Fieldset>
                        <Fieldset legend={ t('timezoneTitle', 'Timezone') } style={{ maxWidth: '480px' }}>
                            <Field 
                                name='timezone' 
                                label={ t('timezoneTitle', 'Timezone') } 
                                type='select' 
                                options={[
                                    'America/Atikokan',
                                    'America/Blanc-Sablon',
                                    'America/Cambridge_Bay',
                                    'America/Creston',
                                    'America/Dawson_Creek',
                                    'America/Dawson',
                                    'America/Edmonton',
                                    'America/Fort_Nelson',
                                    'America/Glace_Bay',
                                    'America/Goose_Bay',
                                    'America/Halifax',
                                    'America/Inuvik',
                                    'America/Iqaluit',
                                    'America/Moncton',
                                    'America/Montreal',
                                    'America/Nipigon',
                                    'America/Pangnirtung',
                                    'America/Rainy_River',
                                    'America/Rankin_Inlet',
                                    'America/Regina',
                                    'America/Resolute',
                                    'America/St_Johns',
                                    'America/Swift_Current',
                                    'America/Thunder_Bay',
                                    'America/Toronto',
                                    'America/Vancouver',
                                    'America/Whitehorse',
                                    'America/Winnipeg',
                                    'America/Yellowknife'
                                ]} 
                                defaultValue={ data.installer.timezone || DateTime.local().zoneName } 
                                required 
                                error={ errors.timezone } 
                                errorMessage={ t('timezoneSelectionError', 'What timezone is your business located in?') } 
                            />
                        </Fieldset>
                        <Submit>{ onboarding ? t('next', 'Next') : t('save', 'Save') }</Submit>
                    </>
                )}
            </Form>
        </Element>
    )

}
import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import Form, { Field, Submit } from 'components/Form'
import { useAuthentication } from 'hooks/authentication';
import { AUTHENTICATE } from 'operations/Auth'
import { useTranslation } from "react-i18next"
import ErrorMessage from 'components/ErrorMessage'
import { Link, useHistory } from 'react-router-dom'

import './login.scss'
import { useDevice } from 'hooks/device';



export default function Login() {

    const { t, i18n } = useTranslation()
	// const { t, i18n } = useTranslation()
	// const ERROR_MESSAGES = {
	// 	'USER_NOT_FOUND': t('loginErrorUserNotFound', 'Sorry, but we were unable to find an account with that username.'),
	// 	'BAD_PASSWORD_MATCH': t('loginErrorBadPasswordMatch', 'Sorry, but your password is incorrect.'),
	// 	'USER_INACTIVE': t('loginErrorUserInactive', 'Sorry, but your account has been deactivated.'),
	// 	'USER_INVALID_EMAIL': t('loginErrorUserInvalidEmail', 'Sorry, but your email has not been verified. Please check your inbox and spam/junk folders for an account activation email from accounts@mail.napalabourwarranty.com')
    // }
    
    const history = useHistory()

    const { device } = useDevice()

	const { setAuthenticated } = useAuthentication()
    const [error, setError] = useState()
    
    const [ authenticate ] = useMutation(AUTHENTICATE)

    const ERROR_MESSAGES = {
        'USER_NOT_FOUND': t('userNotFound', `We were unable to find a user with that username.`),
        'USER_INACTIVE': t('userDeactivated', 'Sorry, but this user has been deactivated.'),
        'BAD_PASSWORD_MATCH': t('badPasswordMatch', 'Sorry, but your password is incorrect.')
    }
    
    return (
        <div className='login__container'>

            {/* <div className='login__sidebar'>

                <i className='material-icons'>directions_car</i>

            </div> */}

            <div className='login__body'>

                <div>

                <div className='logo'><i className='material-icons'>directions_car</i></div>
				
                    <Form className='login' onSubmit={({rawInputs: { username, password }, enableSubmit}) => {

                        authenticate({
                            variables: {
                                payload: {
                                    username,
                                    password
                                }
                            }
                        })
                        .then(({ data: { authenticate } }) => {
                            localStorage.setItem('accessToken', authenticate.accessToken)
                            localStorage.setItem('refreshToken', authenticate.refreshToken)
                            setAuthenticated(true)
                            history.replace('/')
                        })
                        .catch((err) => {
                            const { graphQLErrors } = err
                            graphQLErrors.forEach(({ message }) => {
                                setError(ERROR_MESSAGES[message])
                            })
                            enableSubmit()
                        })

                    }}>
                        {() => (
                            <>
                                {/* <h1>{ t('login', 'Login') }<button type='button' className='languageButton' onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en')}>{ i18n.language === 'fr' ? 'English' : 'Français' }</button></h1> */}
                                <h1>{ t('signInToYourAccount', 'Sign in to your account') }</h1>
                                <div style={{ textAlign: 'right' }}><button type='button' className='languageSwitcher' onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en')}>{ t('languageSwitcher', 'Français') }</button></div>

                                {
                                    error && (
                                        <ErrorMessage>{ error }</ErrorMessage>
                                    )
                                }

                                <Field label={ t('username', 'Username') } name="username" required errorMessage={ t('usernameError', 'Please enter your username.') }/>
                                <Field label={ t('password', 'Password') } type="password" name="password" required errorMessage={ t('passwordError', 'Sorry, your password is incorrect.') } />
                                <Link className='forgotPasswordLink' to='/forgot'>{ t('forgotPasswordLink', 'Forgot your password?') }</Link>
                                <Submit>{ t('signIn', 'Sign in') }</Submit>
                            
                            </>
                        )}
                    
                </Form>

                <div className='loginSignupSwitch'>{ t('noAccountYetQuestion', "Don't have an account yet?") } <Link to='/sign-up'>{ t('signUp', 'Sign up') }</Link></div>
                            { device && <div className='loginSignupSwitch'><Link to='/'>{ t('userSignIn', '{{ userType }} sign in', { userType: device.typ === 'SERVICE_ADVISOR' ? t('serviceAdvisorProperCase', 'Service advisor') : t('technicianProperCase', 'Technician') }) }</Link></div> }

                {
                    // !device && <div className='loginSignupSwitch'><Link to='/link'>Link this device to your account</Link></div>
                }

            </div>

        </div>

    </div>
    )
    
    
}
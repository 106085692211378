import React, { useState } from 'react'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_STRIPE_CUSTOMER_ACCOUNT, CREATE_STRIPE_SUBSCRIPTION, STRIPE_CREATE_PORTAL_SESSION } from 'operations/Installer'
// import { GENERATE_CONNECT_STATE } from 'operations/Auth'

import Card, { CardTitle } from 'components/Card'

import { Link } from 'react-router-dom'

// import buttonImage from './blue-on-light@3x.png'
import Loader from 'components/Loader'
import { loadStripe } from '@stripe/stripe-js'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'

import Form, { Field, Fieldset, Submit } from 'components/Form'

import './cardSection.scss'
import validator from 'validator'
import { FormatCurrency } from 'components/Format'

import { useSubscription } from 'hooks/subscription'

import ErrorMessage from 'components/ErrorMessage'

import { PrimaryButton } from 'components/Button'

import { useTranslation } from 'react-i18next'

// import mcc from './mcc.json'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

export default function () {

    const { t } = useTranslation()

    const { subscriptionStatus } = useSubscription()

    const { error, loading, data } = useQuery(GET_STRIPE_CUSTOMER_ACCOUNT)
    const [ stripeCreatePortalSession ] = useMutation(STRIPE_CREATE_PORTAL_SESSION)

    if (error || loading) return <Loader />

    const { stripeProducts } = data

    const product = stripeProducts[0]
    
    const RECURRING = {
        month: t('recurringMonthly', 'Monthly'),
        annual: t('recurringAnnually', 'Anually')
    }
    
    const SUBSCRIPTION_STATUS = {
        PAID: t('subscriptionActive', 'Active'),
        PAST_DUE: t('subscriptionPastDue', 'Past due'),
        CANCELLED: t('subscriptionCancelled', 'Cancelled'),
        DEMO: t('subscriptionDemoAccount', 'DEMO ACCOUNT')
    }

    return (
        <div className='bodyContainer'>
            <h1><Link to='/settings'>{ t('settings', 'Settings') }</Link><i className='material-icons'>chevron_right</i><span className='current'>{ t('billing', 'Billing') }</span></h1>

            {
                !['TRIAL', 'TRIAL_EXPIRED', 'CANCELLED'].includes(subscriptionStatus) ? (
                    <>
                    <h2>{ t('yourCurrentSubscriptionPlan', 'Your current plan') }</h2>
                        <Card
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto auto auto auto'
                            }}
                        >
                            <CardTitle title={ t('subscriptionPlan', 'Plan') }>
                                { product.name }
                            </CardTitle>
                            <CardTitle title={ t('amount', 'Amount') }>
                                $<FormatCurrency>{ product.prices[0].unit_amount * 0.01 }</FormatCurrency>
                            </CardTitle>
                            <CardTitle title={ t('recurringInterval', 'Recurring') }>
                                { RECURRING[product.prices[0].recurring.interval] }
                            </CardTitle>
                            <CardTitle title={ t('subscriptionStatus', 'Status') }>
                                { SUBSCRIPTION_STATUS[subscriptionStatus] }
                            </CardTitle>
                        </Card>
                        {/* <form method="POST" action={`${ process.env.REACT_APP_API_URL }/stripe/create-customer-portal-session`}>
                            <button className='submitButton' type="submit">Update billing information</button>
                        </form> */}

                        {
                            subscriptionStatus !== 'DEMO' && (
                                <PrimaryButton onClick={ async () => {
                                    const { data: { stripeCreatePortalSession: res } } = await stripeCreatePortalSession() 
                                    window.location.replace(res)
                                    
                                }}>{ t('updateBillingInfo', 'Update billing info') }</PrimaryButton>
                            )
                        }
                    </>
                ) : (
                    <Billing stripeProducts={ stripeProducts }/>
                )
            }
            
        </div>
    )

}

function Billing({ stripeProducts }) {

    return (
        <Elements stripe={ stripePromise }>
            <CheckoutForm stripeProducts={ stripeProducts } />
        </Elements>
    )
}

function CheckoutForm({ stripeProducts }) {

    const { t } = useTranslation()

    const stripe = useStripe()
    const elements = useElements()

    const [ createSubscription ] = useMutation(CREATE_STRIPE_SUBSCRIPTION)

    const { refetchSubscriptionStatus } = useSubscription()

    const [errors, setErrors] = useState({})

    const PROVINCES = [
        ['AB', t('alberta', 'Alberta')],
        ['BC', t('britishColumbia', 'British Columbia')],
        ['MB', t('manitoba', 'Manitoba')],
        ['NB', t('newBrunswick', 'New Brunswick')],
        ['NL', t('newfoundlandAndLabrador', 'Newfoundland and Labrador')],
        ['NT', t('northwestTerritories', 'Northwest Territories')],
        ['NS', t('novaScotia', 'Nova Scotia')],
        ['NU', t('nunavut', 'Nunavut')],
        ['ON', t('ontario', 'Ontario')],
        ['PE', t('princeEdwardIsland', 'Prince Edward Island')],
        ['QC', t('quebec', 'Quebec')],
        ['SK', t('saskatchewan', 'Saskatchewan')],
        ['YT', t('yukon', 'Yukon')]
    ]
    
    const RECURRING = {
        month: t('recurringMonthly', 'Monthly'),
        annual: t('recurringAnnually', 'Anually')
    }

    return (
        <Form 
            onSubmit={ async ({ rawInputs, enableSubmit }) => {

                if (!stripe || !elements) {
                    return
                }

                const formErrors = {}

                if (validator.isEmpty(rawInputs.email) || !validator.isEmail(rawInputs.email)) formErrors.email = true
                if (validator.isEmpty(rawInputs.address)) formErrors.address = true
                if (validator.isEmpty(rawInputs.city)) formErrors.city = true
                if (validator.isEmpty(rawInputs.province)) formErrors.province = true
                if (validator.isEmpty(rawInputs.postal) || (!validator.isPostalCode(rawInputs.postal, 'CA') && !validator.isPostalCode(rawInputs.postal, 'US'))) formErrors.postal = true
                if (!validator.isEmpty(rawInputs.phone) && !validator.isMobilePhone(rawInputs.phone, 'en-US')) formErrors.phone = true

                if (Object.keys(formErrors).length > 0) {
                    enableSubmit()
                    return setErrors(formErrors)
                }
        
                // Get a reference to a mounted CardElement. Elements knows how
                // to find your CardElement because there can only ever be one of
                // each type of element.
                const cardElement = elements.getElement(CardElement)
            
                // If a previous payment was attempted, get the latest invoice
                const latestInvoicePaymentIntentStatus = localStorage.getItem(
                  'latestInvoicePaymentIntentStatus'
                );
        
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                });
                
                    if (error) {
                        console.log('[createPaymentMethod error]', error);
                        enableSubmit()
                        return setErrors({
                            cardDeclined: true
                        })
                    } else {
                        const paymentMethodId = paymentMethod.id;

                        const invoiceId = localStorage.getItem('latestInvoiceId')

                        // Create the subscription
                        const { data } = await createSubscription({
                            variables: {
                                payload: {
                                    ...rawInputs,
                                    country: 'CA',
                                    paymentMethodId,
                                    invoiceId, // invoiceId is not null when retrying a payment due to a declined card
                                    retry: latestInvoicePaymentIntentStatus === 'requires_payment_method' ? true : false,
                                    priceId: stripeProducts[0].prices[0].id,
                                }
                            }
                        })

                        const subscription = data.createStripeSubscription

                        if (subscription.object === 'invoice' && subscription.status === 'paid') {

                            localStorage.removeItem('latestInvoiceId')
                            localStorage.removeItem('latestInvoicePaymentIntentStatus')

                            return refetchSubscriptionStatus()

                        }

                        if (subscription.status === 'active') {

                            return refetchSubscriptionStatus()

                        }

                        if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method' || subscription.status === 'incomplete') {
                            enableSubmit()
                            localStorage.setItem(
                                'latestInvoiceId', 
                                subscription.latest_invoice.id
                            )
                            localStorage.setItem(
                                'latestInvoicePaymentIntentStatus',
                                subscription.latest_invoice.payment_intent.status
                            )
                            return setErrors({
                                cardDeclined: true
                            })
                        }
                }

            }}
        >
            {() => (
                <div style={{ maxWidth: '480px' }}>

                    <h2>{ t('subscriptionPlans', 'Plans') }</h2>

                    {
                        stripeProducts.map(product => {
                            return (
                                <Card
                                    key={ product.id }
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'auto auto auto'
                                    }}
                                >
                                    <CardTitle title={ t('subscriptionPlan', 'Plan') }>
                                        { product.name }
                                    </CardTitle>
                                    <CardTitle title={ t('amount', 'Amount') }>
                                        $<FormatCurrency>{ product.prices[0].unit_amount * 0.01 }</FormatCurrency>
                                    </CardTitle>
                                    <CardTitle title={ t('subscriptionInterval', 'Recurring') }>
                                        { RECURRING[product.prices[0].recurring.interval] }
                                    </CardTitle>
                                </Card>
                            )
                        })
                    }

                    <Fieldset legend={ t('billingContact', 'Billing contact') }>
                        <Field name='email' inputMode='email' label={ t('email', 'Email') } required error={ errors.email } errorMessage={ t('billingEmailError', 'Please enter a billing contact email.') } />
                        <Field name='phone' inputMode='tel' label={ t('phone', 'Phone') } error={ errors.phone } errorMessage={ t('billingPhoneError', 'Please enter a valid billing contact phone number.') } />
                    </Fieldset>
                    <Fieldset legend='Billing address'>
                        <Field name='address' label={ t('addressLine1', 'Address line 1') } required error={ errors.address } errorMessage={ t('billingAddressError', 'Please enter your billing address.') } />
                        <Field name='addressLine2' label={ t('addressLine2', 'Address line 2') }/>
                        <Field name='city' label={ t('city', 'City') } required error={ errors.city } errorMessage={ t('billingCityError', 'Please enter your billing city.') } />
                        <Field name='province' label={ t('province', 'Province') } type='select' options={PROVINCES} required error={ errors.province } errorMessage={ t('billingProvinceError', 'Please select your billing province.') } />
                        <Field name='postal' label={ t('postalCode', 'Postal code') } required error={ errors.postal } errorMessage={ t('billingPostalError', 'Please enter your billing postal code.') } />
                    </Fieldset>
                    <Fieldset legend='Payment information'>
                        {
                            errors.cardDeclined && (
                                <ErrorMessage>{ t('cardDeclinedError', 'Sorry, your card was declined. Please try again.') }</ErrorMessage>
                            )
                        }
                        <CardSection />
                        <Field name='cardholderName' label={ t('cardholderName', 'Cardholder name') } required />
                    </Fieldset>
                    <Submit disabled={ !stripe }>{ t('upgradePlan', 'Upgrade plan') }</Submit>
                </div>
            )}
        </Form>
    )

}

const CARD_ELEMENT_OPTIONS = {
    // style: {
    //   base: {
    //     color: "#32325d",
    //     fontFamily: 'sans-serif',
    //     fontSmoothing: "antialiased",
    //     fontSize: "16px",
    //     "::placeholder": {
    //       color: "#aab7c4",
    //       fontWeight: 'normal'
    //     },
    //   },
    //   invalid: {
    //     color: "#fa755a",
    //     iconColor: "#fa755a",
    //   },
    // },
  };

function CardSection() {
    return (
        <label>
            <CardElement options={ CARD_ELEMENT_OPTIONS } />
        </label>
    )
}